import styled from 'styled-components'

interface IOptionsPredefinedMessages{
    isActive: boolean;
}

export const PredefinedMessagesContainer = styled.div<IOptionsPredefinedMessages>`
position:absolute;
background-color:var(--bar-background);
width:500px;
max-height:200px;
bottom: 62px;
border-radius: 3px;
display: ${ props => props.isActive ? 'block' : 'none'}

`;

export const PredefinedMessagesWrapper = styled.div`
display: flex;
flex-direction: column;
overflow: auto;
max-height: 200px;
border: 1px solid #ddd;
padding: 8px;
box-sizing: border-box;
`;

export const PredefinedMessageItemWrapper = styled.div` 
max-height: 25px;
padding: 5px 2px;
font-size:1rem;
display:flex;
&:hover{
    background-color:#eaedf0;

}
`;
export const PredefinedMessageTitle= styled.div` 
font-weight:500;
`;
export const PredefinedMessageContent= styled.div` 
font-weight:300;
padding-left: 10px;
word-break: break-all;
overflow: hidden;
text-overflow: ellipsis;
line-height: 25px;
flex: 1;
`;

export const PredefinedMessageIconsWrapper= styled.div` 
display:flex;
padding: 4px 8px;
justify-content: space-between;
width: 40px;
`;

export const PredefinedMessageEmpty = styled.span`
font-size:14px;
`
