import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  height: 0.8rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: flex-start;
  align-items: center;
  background-color:var(  --background-sidebar-item);
    &:hover {
        background-color: var( --background-item-hover );
    }
  cursor: pointer;
  color: var( --text-primary);
  gap: 1rem;

  
`;

export const ButtonText = styled.span`
  font-family: 'Open Sans', sans-serif !important;
`;
