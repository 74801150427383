import styled from "styled-components";

export const AgentName = styled.div`
  font-weight:100;
  font-size: 12px;
  color: var( --text-secondary);
  margin-left: 2px;
`;

export const Icon = styled.div`
  color: var( --text-secondary);
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var( --text-secondary);
  margin-left: 10px;
`;
