import styled from 'styled-components'

interface IOptionsMessageReply{
    isActive: boolean;
}

export const MessageReplyContainer = styled.div<IOptionsMessageReply>`
    position:absolute;
    background-color:var(--bar-background);
    width: auto;
    padding: 5px 10px;
    max-width:600px;
    bottom: 62px;
    left: 570px;
    border-radius: 3px;
    box-shadow:0 1px 0.5px rgb(0 0 0 / 13%);
    flex-direction: row;
    align-items: center;
    display: ${ props => props.isActive ? 'flex' : 'none'}
`;

export const MessageReplyLabel = styled.div`
    display: flex;
    max-width:500px;
    flex-direction: column; 
    margin-inline: 10px;
    color:var(--text-primary);
    font-size: 14px;
    font-weight: 500;
`;