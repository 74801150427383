import styled from 'styled-components'

export const Container = styled.div`  
  min-width: 150px;
  width: 100%;

  border-radius: 1rem;

  /* background-color: hsl(220, 17.647%, 20%); */
  background-color: var(--bar-background);

  box-shadow: 0 0.25rem 0.5rem hsla(0, 0%, 0%, 0.25);
`;

export const ContentContainer = styled.div`
  padding: 0.625rem 1rem; // 10px 16px

  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr;
  align-items: center;
  row-gap: 0.625rem; // 10px

  color: var(--text-primary);
  word-break: break-all;
  text-overflow: ellipsis;
`;

export const Item = styled.p`
  margin: 0;
  padding: 0;

  font-size: 0.75rem; // 12px
  line-height: 0.875rem; // 16px
  
  white-space: nowrap;  
`;

export const Value = styled.p`
  margin: 0;
  padding: 0;

  font-size: 0.875rem; // 14px
  line-height: 1rem; // 16px
  font-weight: 600;
  
  white-space: nowrap;
`;