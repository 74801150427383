import { InputWrapper, Label } from "./styles";
import Select from 'react-select';

interface FormTextInputProps {
  label: string;
  placeholder: string;
  handleOnChange: Function
  options: { value: string; label: string; }[]
}

const FormSelectInput: React.FC<FormTextInputProps> = ({handleOnChange, label, placeholder, options}) => {
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <Select
        options={options}
        defaultValue={{ value: "", label: "" }}
        placeholder={placeholder}
        noOptionsMessage={ () => 'Nenhuma opção disponível'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 8,
            colors: {
              ...theme.colors,
            },
          })}
      onChange={ (newValue,actionMeta) => handleOnChange(newValue,actionMeta)}
    />
    </InputWrapper>
  );
}

export default FormSelectInput;
