import { Button, TableContainer } from "@mui/material";
import styled from "styled-components";

export const ComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

`;

export const SubWrapper = styled.div`
  padding: 0.5rem;
`;

export const Wrapper = styled.div`
    display:flex;
    flex: 1;
    flex-wrap: wrap;
    -webkit-font-smoothing: antialiased;
    margin: 0.5rem;
    margin-right: 1.5rem;
    height:98vh;
    min-height: 98vh;
    max-height: 98vh;
    block-size: fit-content;
    flex-direction:column;
    background-color: var(--background-secondary);
    -webkit-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    color: var(--text-primary);
    
    input{
        color: var(--text-primary);
      
        &:before{
          border-bottom-color: 'transparent',
        }
        
      }

      label {
        color: var(--text-primary);
      }

      svg{
        color: var(--text-primary);
      }

      header{
        svg{color: white;}
      }
      

        overflow-y: visible;

    @media (max-width: 768px) {
      position: fixed;
      top: 72px;
      height: calc(100vh - 72px);
      width: 96vw;
    }
`;

export const Title = styled.span`
  font-weight: 500; 
  font-size: xx-large;
  margin-bottom: 1rem;
`;

export const FilterOptionsRow = styled.div`
  display: flex;
  /* width: inherit; */
  gap: 1rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const FilterLabel = styled.span`
  font-weight: 400;
  font-size: small;
`;

export const FilterInput = styled.div`
  display: flex;
  gap: 1.5rem;
  margin: 1rem 0 1rem 0;
`;

export const ExportButton = styled.div`
  display: flex;
  background-color: red;
  width: 4rem;
  height: 2rem;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundModal);
  padding: 10px;
  box-sizing: border-box;
  /* gap: 4rem; */
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
`;

export const InfoColumnTitle = styled.span`
  font-size: medium;
  overflow: hidden;
  overflow-wrap: break-word;
  justify-content: flex-start;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #EAF1E8;
  height: 100%;
  width: 9rem;
  align-items: center;
`;



export const ValueWrapper = styled.div`
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  text-overflow: clip;
`;

export const InfoColumnValue = styled.span``;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ContentRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;


export const TableGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(10px, 1fr));
  grid-auto-rows: 40px;
  overflow-x: hidden;
  max-height: 300px;
  border: 0.3mm solid rgba(17, 27, 33, .1);
`;
export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: scroll;
`;

export const GridItem = styled.div`
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  height: 100%;
  width: auto;
  justify-content: center;
  border-bottom: 0.3mm solid rgba(17, 27, 33, .1);
`
export const GridItemHeader = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background-color: rgba(17, 27, 33, 0.9);
`

export const DatePickWrapper = styled.div`
  width: 70px;
`