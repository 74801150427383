import { Dialog } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  width: 35rem;
  background-color: var(--backgroundModal);
  border: 1px solid var(--background-admin-edit-border);
  color: var(--background-admin-edit-color);
  justify-content: space-between;

  input{
    background: var(--input-bg-color);
    color: var(--text-modal);
    border-radius: 4px;
  }

  ::placeholder{
    color: var(--text-modal);
  }
`;

export const InputDiv = styled.div`
  width: 100%;
  box-sizing: border-box;
  object-fit: fill;
  border-radius: 3px;

`;


export const TitleContainer = styled.span`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: xx-large;
`;

export const NewDepForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  align-items: center;
  justify-content: space-between;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 1rem;
`;
export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: #0f0f0f2b;
  }
`;
