import { createContext, useContext } from 'react';
import { io, Socket } from "socket.io-client";
import { FileUpload, MessageReply } from '../../interface/IModels';

export type ChatContextType = {
    inputField: string;
    setInputField: (inputField: string) => void;
    inputFiles: FileUpload[],
    setInputFiles: (inputFiles: FileUpload[]) => void;
    messageReply: MessageReply;
    setMessageReply: (messageReply: MessageReply ) => void;
}
export type SidebarOptions = {
    modalNotification: boolean,
    setModalNotification: (option: boolean) => void;
}

export const ChatContext = createContext<ChatContextType>({ 
    inputField: "", setInputField: (inputField) => { }, 
    inputFiles: [], setInputFiles: (inputFiles) => { },
    messageReply: {id:"",content:null}, setMessageReply: (messageReply) => { }
});

export const useChat = () => useContext(ChatContext);

export const ModalNotificationContext = createContext<SidebarOptions>({ modalNotification: false, setModalNotification: (modalNotification) => { } });

export const useModalNotification = () => useContext(ModalNotificationContext);

const url = process.env.REACT_APP_URL_SOCKET_URL || "";

export const socket = io(url, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionAttempts: 999999,
    transports: ["polling"]
}) as Socket;

export const SocketContext = createContext(socket);