import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { ChatModel, ChatItemModel, CompanyItem, DepartmentItem } from "../models/redux-models";
import { IMessage, THistory } from "../interface/IModels";

type ParamsHistory = {
    messages: IMessage[],
    chat_id: number,
}

export const initialChat: ChatModel = {
    chat: {
        id: 0,
        agent: null,
        agent_id: null,
        name: "",
        phone: "",
        picture: "",
        messages: 0,
        messages_read: 0,
        updatedAt: "",
        isGroup: false,
        companyID: 0,
        departmentID: 0,
        messages_chat: [],
        history: {
            history: false,
            historys: [],
        },
        extra_payload: "",
        isMessagesLoading: false,
    },
    departments: [],
    companysSelect: [],
    companys: [],
    chatsInAttendance: [],
};

const chatSlice = createSlice({
    name: 'chat',
    initialState: initialChat,
    reducers: {
        setChatItem(state, action: PayloadAction<ChatItemModel>) {
            state.chat = { ...action.payload, isMessagesLoading: false };
        },
        setChats(state, action: PayloadAction<ChatItemModel[]>) {
            state.chatsInAttendance = [...action.payload];
        },
        getChatsInAttendance(state, action: PayloadAction<ChatItemModel[]>) {
            state.chatsInAttendance = [...action.payload];
        },
        setChat: (state, action: PayloadAction<ChatItemModel>) => {
            state.chat = { ...action.payload };
        },
        resetChat: (state) => {
            state.chat = { ...initialChat.chat };
        },
        setNewMessageChat(state, action: PayloadAction<IMessage>) {
            if (action.payload) {
                if (!state.chat.messages_chat) {
                    state.chat.messages_chat = [];
                }
                state.chat.messages_chat.push(action.payload);
            }
        },
        setNewMessageChatAgent: (state, action: PayloadAction<IMessage>) => {
            state.chat.messages_chat.push(action.payload);
        },
        setUpdateSendMessageAgent: (state, action: PayloadAction<IMessage>) => {
            let messagePosition: number = state.chat.messages_chat.findIndex((message) => message.id === action.payload.id);
            if (messagePosition != -1) {
                // action.payload
                state.chat.messages_chat[messagePosition] = { ...action.payload };
            }
        },

        setCompanys(state, action: PayloadAction<CompanyItem[]>) {
            state.companys = [...action.payload];
        },

        addCompanyItem(state, action: PayloadAction<CompanyItem>) {
            state.companys.push(action.payload);
            state.companysSelect.push(action.payload);
        },

        setCompanySelect(state, action: PayloadAction<CompanyItem>) {
            state.companysSelect.push(action.payload);
        },

        setSelectedCompanys(state, action: PayloadAction<CompanyItem[]>) {
            state.companysSelect = [...action.payload];
        },

        setDepartments(state, action: PayloadAction<DepartmentItem[]>) {
            state.departments = [...action.payload];
        },

        resetState: (state) => {
            state.companys = [...initialChat.companys];
            state.companysSelect = [...initialChat.companysSelect];
            state.chatsInAttendance = [...initialChat.chatsInAttendance]
        },

        setMessagesHistory(state, action: PayloadAction<ParamsHistory>) {
            const historyFind = state.chat.history.historys.find(history => history.id == action.payload.chat_id)
            const historysCopy = [...state.chat.history.historys];

            if (historyFind == undefined) return;

            const newHistorys = historysCopy.map(his => {
                if (his.id == action.payload.chat_id) {
                    his.messages = [...action.payload.messages];
                }
                return his;
            });

            state.chat.history.historys = [...newHistorys];
        },
        updateChatInAttendance(state, action: PayloadAction<ChatItemModel>) {
            const newChats = state.chatsInAttendance.map(chat => {
                return (chat.id === action.payload.id) ? action.payload : chat;
            });
            state.chatsInAttendance = [...newChats];
        },
        pushChatInAttendance(state, action: PayloadAction<ChatItemModel>) {
            const newChats = state.chatsInAttendance.filter(chat => chat.id != action.payload.id);
            state.chatsInAttendance = [...newChats];

            state.chatsInAttendance.push(action.payload)

        },
        removeChat(state, action: PayloadAction<number>) {
            const newChats = state.chatsInAttendance.filter(chat => chat.id != action.payload);
            state.chatsInAttendance = [...newChats];

            if (state.chat.id == action.payload) {
                state.chat = { ...initialChat.chat };
            }
        }
    }
})

export const { setNewMessageChatAgent } = chatSlice.actions;
export default chatSlice;