import styled from 'styled-components'

export const SidebarContainer = styled.div`
    display: flex;
    width: 30%;
    background-color: var(--background-sidebar-item);
    max-height:100vh;
    flex-direction: column;
    bakcground-color: red;
    border-right: 1px solid var(--border-color);
    @media (max-width: 768px) {
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;