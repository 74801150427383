import { Box, Checkbox, Chip, ListItemText, MenuItem, OutlinedInput, FilledInput, Select, SelectChangeEvent, InputLabel, FormControl } from "@mui/material";
import { useEffect, useState } from "react";


interface IProps{
    style?: Object,
    label?: string;
    variant?: "outlined" | "standard" | "filled" | undefined;
    options:{id:number, value: string}[]; 
    optionsSelected?:number[]; 
    error?:boolean;
    onSelect?:(ids:number[]) => void;
}
export default function MultiSelect(props: IProps) {
    
    const [selectedDataIDs, setSelectedDataIDs] = useState<number[]>(props.optionsSelected || []);

    useEffect( () => {
        if( props.onSelect ){
            props.onSelect(selectedDataIDs);
        }
    },[selectedDataIDs])
    

    const handleSelectChange = (event: SelectChangeEvent<number[]>) => {
        const {value} = event.target;
        if(  Array.isArray(value) ){
            setSelectedDataIDs(value)
        }
    };
    const renderTypeInput = (variant: string | undefined) => {
        switch( variant ){
            case "filled":
                return (
                    <FilledInput /> 
                );
            default:
                return (<OutlinedInput />);
        }
    }

    return (
        <FormControl variant={props.variant}  sx={props.style ? props.style : {}} >
            <InputLabel id={`multi-select-label-${props.label}`}>{props.label}</InputLabel>
            <Select
                multiple
                labelId={`multi-select-label-${props.label}`}
                value={selectedDataIDs}
                variant={props.variant}
                onChange={handleSelectChange}
                input={renderTypeInput(props.variant) }
                error={props.error}
                renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                    {selected.map((id) => (
                        <Chip key={id} label={ props.options?.find(e => e.id === id) ? props.options?.find(e => e.id === id)?.value : "" } />
                    ))}
                </Box>
                )}
            >
                {props.options.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={selectedDataIDs.includes(item.id)} />
                    <ListItemText primary={item.value} />
                </MenuItem>
                ))}                      
            </Select>
        </FormControl>
  
    
    );
  }
  