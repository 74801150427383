import { Button, styled } from "@mui/material";
import styledComponents, { keyframes } from "styled-components";

export const Wrapper = styledComponents.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    -webkit-font-smoothing: antialiased;
    height: 98vh;
    min-height: 98vh;
    max-height: 98vh;
    block-size: fit-content;
    flex-direction:column;
    background-color: var(--background-secondary);
    -webkit-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    color: var(--text-primary);
    overflow: visible;
    
    input {
      color: var(--text-primary);
    }

    @media (max-width: 768px) {
      position: fixed;
      top: 72px;
      height: calc(100vh - 72px);
      width: 96vw;
    }
`;

export const TitleContainer = styledComponents.span`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: xx-large;
`;

export const ConfigContainer = styledComponents.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  margin-top: 1rem;
  align-items: start;
  overflow-y: scroll;
`;

export const CreateButton = styled(Button)({
  lineHeight: '0',
  padding: '7px 8px',
  width: '100%',
  backgroundColor: 'var(--btn-registerNew)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'var(--btn-registerNew-hover)',
  }
});

export const spinner = keyframes`
  from{
    -webkit-transform: rotate(360deg);
  }
  to{
    -webkit-transform: rotate(0deg);
  }
`;

export const Circle = styledComponents.div`
  display:flex;
  align-items:center;
  -webkit-animation: ${spinner} 1s linear infinite;

`;