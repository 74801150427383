import axios from 'axios'
import { IMessage, IMessages, IHistory, THistory, FileUpload, IChat } from '../interface/IModels';
import { ChatItemModel, CompanyItem, DepartmentItem, MessageModel } from '../models/redux-models';
import toast from 'react-hot-toast';

interface createMessageAgentOptions {
  isDirect?: boolean;
  department_id?: number;
  webhookUrl?: string;
}

const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'chat/' || "";

export default {
  async getMessagesByNumber(phone: string, id: number) {
    const tokenJWT = localStorage.getItem("auth");

    let messages: IMessages = [];
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "getChats.php";

    await axios.post<IMessage[]>(url,
      { "phone": phone },
      {
        headers
      }).then(request => {
        messages = request.data;
      }).then(async response => {
        url = baseUrl + "readMessages.php"
        return axios.post(url, { chat_id: id }, { headers }).then(
          response => {
            return messages;
          })
      }).catch(err => {
        console.log(err)
      });

    return messages;
  },

  async createMessageAgent(message: IMessage, phone: string, fileToUpload?: FileUpload, options?: createMessageAgentOptions) {

    const tokenJWT = localStorage.getItem("auth");

    let valuePromisse = 0;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "sendMessageAgentV2.php";
    return await axios.post<number>(url,
      {
        ...message,
        department: options?.department_id ?? 0,
        phone: phone,
        file: fileToUpload,
        isDirect: options?.isDirect ?? false,
        webhookUrl: options?.webhookUrl ?? ""
      }, { headers }
    ).then(response => {
      valuePromisse = response.data;
    }).catch(() => {
      toast.error("Falha ao mandar mensagem, envie-a novamente!", {
        duration: 2000
      })
    }).then(async response => {
      url = baseUrl + "readMessages.php"

      return axios.post(url, { chat_id: message.chat_id }, { headers }).then(response => {
        return valuePromisse;
      });

    }).catch(err => {
      console.log(err)
      return 0;
    });

  },

  async createChatByApp(chatDetails: IChat, photoUrl: string) {
    const tokenJWT = localStorage.getItem("auth");

    let url = baseUrl + "createChatV2.php";

    let valuePromisse = 0;
    console.log(photoUrl)

    let clientToken = '';
    if (chatDetails.companyId != 22) clientToken = 'F86e6d40befa14914aa4077c8986569b6S';
    else clientToken = 'Fe113fc1e2fac430f93ff05d20680d8caS';

    const headers = {
      'Content-Type': 'application/json',
      'Client-Token': clientToken,
    }

    try {
      const response = await axios.get(photoUrl, { headers });

      const headersApp = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenJWT}`,
      }

      let photo;
      if (!response.data) {
        photo = "https://gravatar.com/avatar/29967191/e9c43271a321f0af66f5da2e4462cb8b.jpeg?size=2500";
      } else {
        photo = response.data;
      }

      const createTranshipment = await axios.post<number>(url,
        {
          phone: chatDetails.phone,
          name: chatDetails.name,
          company_id: chatDetails.companyId,
          department: chatDetails.departmentId,
          picture: photo
        },
        { headers: headersApp }
      );
      valuePromisse = createTranshipment.data;

      url = baseUrl + "readMessages.php";

      return createTranshipment;
    } catch (err) {
      return err as any;
    }
  },

  async getChatActive(chat_id: number) {
    const tokenJWT = localStorage.getItem("auth");
    let valuePromisse: ChatItemModel;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "getChatV2.php";

    return await axios.post<ChatItemModel>(url, { chat_id: chat_id }, { headers }).then(response => {
      valuePromisse = response.data
      return response.data;

    }).then(async response => {

      let url = baseUrl + "getChatsArchivedV2.php";

      return await axios.post<IHistory[]>(url, { phone: response.phone, company: response.companyID }, { headers }).then(responseArchived => {
        if (responseArchived.data) {
          const history: THistory = {
            history: true,
            historys: responseArchived.data ? responseArchived.data : []
          };

          valuePromisse.history = { ...history };
        }
        return valuePromisse;
      })
    }
    ).then(async response => {
      url = baseUrl + "readMessages.php"
      return axios.post(url, { chat_id: response.id }, { headers }).then(
        response => {
          return valuePromisse;
        }
      )
    })

  },

  async getChatsInAttendance(companys: CompanyItem[], departments: DepartmentItem[]) {
    const tokenJWT = localStorage.getItem("auth");

    let valuePromisse: ChatItemModel[] = [];

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "getChatsInAttendance.php";

    let companyIDs = companys.map(e => e.id);
    let departmentIDs = departments.map(e => e.id);

    if (!companys.length) return valuePromisse;

    return await axios.post<ChatItemModel[]>(url,
      { companys: companyIDs, departments: departmentIDs }, { headers }
    ).then(response => {
      valuePromisse = response.data;
      return valuePromisse;
    }).catch(err => {
      console.log(err)
      return valuePromisse;
    });

  },

  async closeChat(chat_id: number, webhook_id?: string, ticket_number?: string, data?: any | null) {
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "closeChatV2.php";

    return await axios.post<boolean>(url, { id: chat_id, webhook_id: webhook_id, ticket_number, data }, { headers }
    ).then(response => {
      return response.data;
    }).catch(err => {
      console.log(err)
      return false;
    });
  },

  async getCustomerExtraInformationByExtraPayload(phone: string, id_company: number) {
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "getCustomerExtraInformation.php";

    return await axios.post<any>(url, { phone, id_company }, { headers }
    ).then(response => {
      return response.data;
    }).catch(err => {
      console.log(err)
      return false;
    });
  },

  async getChatDetails(chat_id: number, phone: string) {
    const tokenJWT = localStorage.getItem("auth");

    type GetChatDetailsResponse = {
      archived: boolean,
      pinnd: boolean,
      messagesUnread: number,
      phone: string,
      unread: number,
      name: string,
      lastMessageTime: string,
      muteEndTime: string,
      isMuted: string,
      isMarkedSpam: string,
      time: string,
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "getChatDetails.php";

    return await axios.post<GetChatDetailsResponse>(url, { id: chat_id, phone: phone }, { headers }
    ).then(response => {
      return response.data;
    }).catch(err => {
      console.log(err)
      return false;
    });
  },

  async getMessageByID(message_id: number) {

    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "getMessage.php";

    return await axios.post<IMessage>(url, { id: message_id }, { headers }
    ).then(response => {
      return response.data;
    }).catch(err => {
      console.log(err)
      return false;
    });
  },

  async readMessages(chat_id: number) {

    let url = baseUrl + "readMessages.php"
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    return await axios.post(url, { chat_id: chat_id }, { headers }).then(
      response => {
        return true;
      }
    ).catch(error => {
      return false;
    })
  },

  async getChatMetadata(company_id: number, phone: string) {

    let url = baseUrl + "getChatMetadata.php"

    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    return await axios.post(
      url,
      {
        company_id: company_id,
        phone: phone
      },
      { headers })
      .then(
        response => {
          return response.data;
        }
      ).catch(error => {
        console.log(error);
        return false;
      })
  },

  async updateChatDepartament(chat_id: number, departament_id: number) {

    let url = baseUrl + "updateDepartment.php"

    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    return await axios.post(
      url,
      {
        id_chat: chat_id,
        id_departament: departament_id
      },
      { headers })
      .then(
        response => {
          return response.data;
        }
      ).catch(error => {
        console.log(error);
        return false;
      })
  },

  async setChatAttendant(
    chatId: number,
    userId: number
  ) {
    const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'chat/' || "";

    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    const url = baseUrl + 'setAgentWithSocket.php';

    return await axios.post(url,
      {
        chat_id: chatId,
        agent_id: userId
      },
      {
        headers
      }).then(request => {
        return request.data;
      }).catch(err => {
        throw err;
      });
  },

  async getChatByNumber(phone: string, companyId: string) {

    let url = baseUrl + "findChatByPhoneAndCompany.php"

    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    return await axios.post(
      url,
      {
        phone: phone,
        companyID: companyId
      },
      { headers })
      .then(
        response => {
          return response.data;
        }
      ).catch(error => {
        console.log(error);
        return false;
      })
  },

  async listContacts(nameOrPhone: string, companyId: number[]): Promise<{ name: string; phone: string }[]> {
    const url = process.env.REACT_APP_URL_APP_BOT_API + `client/search.php?search=${nameOrPhone}${companyId.map((c) => `&company[]=${c}`).join("")}`;

    return (await axios.get(url)).data as { name: string; phone: string }[];
  },

  async verifyIfChatAlreadyOpen(originalPhone: string, companyId: number, possiblePhones: string[]): Promise<boolean> {

    const JWTToken = localStorage.getItem("auth");
    const response = await axios.post(
      `${process.env.REACT_APP_URL_APP_BOT_API}/chat/statusChatV2.php`,
      {
        companyID: companyId,
        originalPhone: originalPhone,
        phones: possiblePhones
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${JWTToken}` } }
    );

    console.log(response.data.id);

    return !!(response.data.id ?? false);
  },
  async updateNameUserChat(name: string, phone: string, idCompany: number, idAgent: string): Promise<boolean> {

    const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'client/' || "";

    const tokenJWT = localStorage.getItem("auth");
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    const urlRequest = baseUrl + "updateNameUserChat.php";

    return await axios.post<any>(urlRequest, {
      name: name,
      phone: phone,
      idCompany: idCompany,
      idAgent: idAgent
    }, { headers })
      .then(response => {
        return response.data > 0;
      })
      .catch(error => {
        return false;
      });
  },

  async transferAttendanceByAgentOrDepartment(idChat: number, idDepartment: string, idUser: string): Promise<boolean> {


    const tokenJWT = localStorage.getItem("auth");
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "transferAttendance.php";

    return await axios.post<any>(url, {
      idChat,
      idDepartment,
      idUser
    }, { headers })
      .then(response => {
        console.log(response.status == 200);
        return response.status == 200;
      })
      .catch(error => {
        return false;
      });
  }
}