
import { FormControl, FormControlLabel, styled, Switch, SwitchProps, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ListWebhook from './ListWebhook';
import * as S from './styles';

const Webhook: React.FC = () => {

    const { register, clearErrors, setError, reset, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' });

    const [switchToDiscardChat, setSwitchToDiscardChat] = useState<boolean>(false);

    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        '& .MuiSwitch-switchBase': {
            transitionDuration: '300ms',

            '&.Mui-checked': {
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                },
                '& .MuiSwitch-thumb': {

                    transition: theme.transitions.create(['width'], {
                        duration: 200,
                    }),
                }
            },
        },
    }));


    useEffect(() => {

    }, [switchToDiscardChat])

    return (
        <S.CardWrapper>
            {/* <S.SwitchWrapper>  

                <FormControl component="fieldset" variant="standard">
                    <FormControlLabel
                        control={
                            <IOSSwitch checked={switchToDiscardChat} onChange={() => setSwitchToDiscardChat(!switchToDiscardChat)} name="gilad" />
                        }
                        label="Descartar conversas"
                    />
                </FormControl>
            </S.SwitchWrapper> */}

            <ListWebhook />
        </S.CardWrapper>

    )
}

export default Webhook;