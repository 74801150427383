import { useMemo } from "react";
import { ArrowContainer, NavWrapper, PagNumber, PagNumsWrapper, Wrapper } from "./style";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

interface IPaginationBarProps {
  offset: number,
  total: number,
  limit: number,
  onChange: (num: number) => any,
}

const Paginationbar: React.FC<IPaginationBarProps> = ({ limit, offset, total, onChange }) => {

  const numTotalPags = useMemo(() => Math.ceil(total / limit), [limit, total])
  const acutalPag = useMemo(() => (offset / limit), [offset, limit])

  const handleArrowBack = () => {
    if (acutalPag - 1 >= 1) {
      let offset = (acutalPag - 1) * limit;
      onChange(offset);
    }
  }

  const handleArrowFoward = () => {
    if (acutalPag + 1 <= numTotalPags - 1) {
      let offset = (acutalPag + 1) * limit;
      onChange(offset);
    }
  }

  const checkActualPage = (page: number) => {
    if (page === acutalPag) {
      return true;
    }
    return false;
  }

  const renderPagNumbers = () => {
    let array = [acutalPag - 2, acutalPag - 1, acutalPag, acutalPag + 1, acutalPag + 2]
      .filter(el => el > 0 && el < numTotalPags)

    return (
      <NavWrapper>
        <ArrowContainer>
          <PagNumber actualPage={false} role="button" onClick={handleArrowBack}>
            <IoIosArrowBack scale={10} />
          </PagNumber>
          {
            array[0] > 1 && (<span >...</span>)
          }
        </ArrowContainer>

        <PagNumsWrapper>
          {
            array.map(el => (
              <PagNumber actualPage={checkActualPage(el)} role="button" onClick={() => {
                let offset = el * limit;
                onChange(offset);
              }}>{el}</PagNumber>
            ))
          }
        </PagNumsWrapper>

        <ArrowContainer>
          {
            array[array.length - 1] < numTotalPags - 1 && (<span >...</span>)
          }
          <PagNumber actualPage={false} role="button" onClick={handleArrowFoward}>
            <IoIosArrowForward />
          </PagNumber>
        </ArrowContainer>
      </NavWrapper>
    )
  }

  return (
    <Wrapper>
      {renderPagNumbers()}
    </Wrapper>
  );
}

export default Paginationbar;
