import styled from "styled-components";

export const FormWrapper = styled.form`
  display: flex;
  width: 100%;
  height: 90%;
  gap: 1rem;
  position: relative;
`;

export const UserDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 30rem;
  background-color: var(--background-admin-edit);
  border: 1px solid var(--background-admin-edit-border);
  border-radius: 5px;
  color: var(--background-admin-edit-color);
  // justify-content: space-between;
`;

export const WrapperRelations = styled.div`
  display:flex;
  align-items:center;
  width:80%;
  flex-direction:column;
`

export const WrapperRelationField = styled.div`
  display:flex;
  width:100%;
  flex-direction:column;
  margin-bottom:1.5rem;
`;


export const TitleContainer = styled.span`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: xx-large;
`;

export const SectionContainer = styled.span`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: x-large;
`;

export const NewUserForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  // overflow-y: scroll;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  gap: 1rem;
  // overflow-y: scroll;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  gap: 1rem;
  // overflow-y: scroll;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  gap: 1rem;
`;

export const CompanyCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 1.5rem;
  height: 12rem;
`;

export const LabelSelect = styled.label`
  font-size:large;
  font-weight:500;
  display:block;
  margin-bottom:10px;
`

export const LabelErrorSelect = styled.label`
  font-size:small;
  font-weight:500;
  display:block;
  margin-bottom:10px;
  color: red;
`
