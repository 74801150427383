import React from 'react';
import { SidebarContainer } from './styles'

import SidebarChat from '../SidebarChat';
import SidebarHeader from '../SidebarHeader';


const Sidebar: React.FC = (props) => {

    const pull_data = (data: string) => {

    }

    return (
        <SidebarContainer>
            <SidebarHeader
                func={pull_data}
            />
            <SidebarChat />
        </SidebarContainer>
    );
};

export default Sidebar;
