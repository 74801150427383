import axios from 'axios'

async function getApiStatus(compabnyId: number) {
  const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'provider/' || "";

  const tokenJWT = localStorage.getItem("auth");

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${tokenJWT}`,
  }

  const url = baseUrl + '/status.php';

  return await axios.post(url,
    {
      company_id: compabnyId
    },
    {
      headers
    }).then(request => {
      return request.data;
    }).catch(err => {
      throw err;
    });
}

const ProviderService = {
  getApiStatus,
}

export default ProviderService;
