import { MdOutlineSupportAgent } from "react-icons/md";
import { useAppSelector } from "../../hooks/redux-hooks";
import { AgentName, Icon, RowWrapper } from "./style";

interface IChatHeaderAttendantProps {
  departtamentId: number,
  chatId: number,
}

export const ChatHeaderAttendant: React.FC<IChatHeaderAttendantProps> = ({ chatId, departtamentId }) => {

  const chat = useAppSelector(state => state.chat.chat)

  return (
      <RowWrapper>
        <Icon>
          <MdOutlineSupportAgent />
        </Icon>
        <AgentName>{chat.agent_id ? chat.agent?.name : "Nenhum"}</AgentName>
      </RowWrapper>
  );
}

export default ChatHeaderAttendant;
