import { ErrorMessage, Input, InputWrapper, Label } from "./styles";

interface FormTextInputProps {
  label: string;
  placeholder: string;
  register: Function
  errors: any
  name: string
  showpass: boolean;
  required: boolean,
  handleChangeShow: Function;
}

const FormPasswerdInput: React.FC<FormTextInputProps> = ({register, label, placeholder, errors, showpass, handleChangeShow, name, required}) => {
  const requiredCondition = required ? ( {required: 'Campo obrigatório'} ) : "";
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <Input
        type={showpass ? "text" : "password"}
        placeholder={placeholder}
        {...register(name, { 
          requiredCondition,
          minLength: {
          value: 8,
          message: "Mínimo de 8 caracteres"
        }})}
      />
      {errors && <ErrorMessage>{errors.message}</ErrorMessage>}
    </InputWrapper>
  );
}

export default FormPasswerdInput;
