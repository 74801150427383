import { Button, colors } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled.div`
    display:flex;
    flex: 1;
    flex-wrap: wrap;
    
    -webkit-font-smoothing: antialiased;
    margin:1.2rem auto;
    max-width: 700px;
    height:95vh;
    min-height: 90vh;
    block-size: fit-content;
    flex-direction:column;
    background-color: var(--background-secondary);
    -webkit-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    z-index: 10;
    color: var(--text-primary);
      input{
        color: var(--text-primary);
      }

`;

export const FullPageWrapper = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    overflow-y: scroll;

    background-color: var(--background-secondary);
`;

export const AvatarFlow4bot = styled.img`
    
        max-height: fit-content;
        width: 100%;
        height: auto;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        text-align: center;
        align-self: center;
        display: grid;
        margin: 0 auto; 
`;

export const HelperText = styled.p`
color: red;
`;

export const AvatarHover = styled.img`
    
        max-height: fit-content;
        width: 100%;
        height: auto;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        text-align: center;
        align-self: center;
        display: grid;
        margin: 0 auto;

        border: 5px solid red;

        ::before{
            content: 'teste';
            font: 2000px;
        }
    
`;


export const Header = styled.header`
  padding: 0.5rem auto;
  img {
   border-radius: 50%;
  }
`;

export const Main = styled.main`
  width: 100%;
  display: row !important;
  flex-direction: column;
    
  .FormTrocarSenha:focus-within .footerBtn {
    visibility: hidden;
    max-height: 0;
    background-color: green;
  }
`;

export const ProfileDoubleinput = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 10% 90%;
    width: 100%;
    align-items: right;
    
`;

export const RightDoubleInput = styled.div`
    text-align: right;
`;

export const FormWrapper = styled.div`

    padding: 2rem 3rem;
    margin: 0 auto;

`;

export const ChangePasswordWrapper = styled.div`

`;


export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;


export const styles = {
  inputMarginTop: {
    marginTop: "20px",
    backgroundColor: 'var(--input-background)',
    color: 'var(--text-primary)'
  },
  Width: {
    width: '90%',
  }
};

export const CreateButton = styled(Button)({
  backgroundColor: 'var(--icon-active)',
  color: 'white',
  '&:hover': {
    backgroundColor: '#ccc',
  }
});

export const ImageContainer = styled.div`
  position: relative;
  display: grid;
  margin: 0 auto;
  max-height: max-content;
  width: max-content;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #323232;
  border: 1px solid var(--btn-submit);
`;

export const WrapperButtonBack = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;


export const ImageAvatar = styled.img`
   
     width: 100%;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        text-align: center;
        align-self: center;
        margin: 0 auto;
        cursor: pointer;
        transition: opacity 0.3s ease;
        &:hover{
            transition: opacity 0.3s ease, filter 0.2s linear;
            opacity: 0.6;
            filter: blur(2px);
        }
`;

export const ImageAvatarClicked = styled.img`
        width: 100%;
        max-height: max-content;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        text-align: center;
        align-self: center;
        margin: 0 auto;
        cursor: pointer;
        transition: opacity 0.3s ease;
        z-index: 2;
        cursor: default;
`;

export const Icon = styled.div`
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;

  ${ImageContainer}:hover & {
    opacity: 1;
  }

  &:hover .${ImageAvatar}{
    opacity: 0.7;
  }


`;
