import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  width: 30rem;
  background-color: whitesmoke;
  border-radius: 16px;
  justify-content: space-between;
`;

export const TitleContainer = styled.span`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: xx-large;
`;
