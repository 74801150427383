import { useCallback, useEffect } from 'react';
import webhookService, { IWebhook } from '../../../../service/webhookService';
import * as S from './styles';
import { Card } from '@mui/material';
import { IoMdRemoveCircle, IoMdCreate } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

import Modal from 'react-modal';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import EditWebhook from '../EditWebhook';
import { useMainContext } from '../../../../Contexts/Contexts';
interface IWebhookProps {
    webhook: IWebhook
};

const CardWebhhok: React.FC<IWebhookProps> = (webhookProps) => {
    const Contexts = useMainContext();


    const nav = useNavigate();
    const { id: idParams } = useParams();

    const openModal = (id: string) => {
        Contexts.setId(id);
        Contexts.setEditWebhookOpen(true);
        nav(`/admin/webhook/${id}`)
    }

    useEffect(() => {
        if (typeof idParams == "string") {
            openModal(idParams);
        }
    }, [idParams])

    const [modalState, setModalState] = useState<boolean>(false);

    const customStyles = {
        content: {
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '400px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'var(--background)',
            border: '1px solid var(--background)',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
        }
    };

    const removeWebhook = useCallback((id: string) => {

        webhookService.deleteWebhook(id).then(res => {
            window.location.reload();
        })
    }, []);

    const handleModaExclusionWebhook = (stateModal: boolean) => {
        setModalState(stateModal);
    }

    return (
        <>
            <Card sx={{ width: "100%", padding: "1.5rem", borderRadius: "4px", backgroundColor: "var(--input-background)", color: "var(--text-company)", marginBottom: "1rem", border: "1px solid #ccc", display: "flex", justifyContent: "space-between" }}>
                <S.CardName>
                    {webhookProps.webhook.name}
                </S.CardName>
                <S.CardIcons>      {/*onClick={() => { nav(`/admin/edit-webhook/${webhookProps.webhook.id}`) }} */}
                    <EditOutlined sx={{ cursor: 'pointer' }} onClick={() => { openModal(webhookProps.webhook.id) }} />
                    <DeleteOutline sx={{ cursor: 'pointer' }} onClick={() => handleModaExclusionWebhook(!modalState)} />
                </S.CardIcons>
            </Card>

            <Modal
                isOpen={modalState}
                contentLabel="Modal"
                style={customStyles} >
                <S.ModalTitle>Excluir Webhook</S.ModalTitle>
                <p>Deseja excluir esse webhook?</p>
                <S.ModalButtonRow>
                    <S.ModalButton className='activeButtonModal' onClick={() => {
                        removeWebhook(webhookProps.webhook.id)
                    }}>Sim</S.ModalButton>
                    <S.ModalButton onClick={() => { setModalState(false) }}>Não</S.ModalButton>
                </S.ModalButtonRow>
            </Modal>

            {/* {false && <EditWebhook />} */}
        </>
    );
}

export default CardWebhhok;