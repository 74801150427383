import { useEffect, useRef, useState } from 'react';
import * as S from './styles';
import { MessageReply } from '../../interface/IModels';
import { FaReply, FaWindowClose } from 'react-icons/fa';
import { useChat } from '../Chat/chatContext';
import { EMessage } from '../../util/enums';
import { normalize } from 'path';
import { BsCameraReelsFill, BsCardImage, BsMic, BsPaperclip } from 'react-icons/bs';

interface IProps{
    messageReply: MessageReply;
}

export const MessageReplyComponent = ({
    messageReply
 }:IProps) => {

    const {
        setMessageReply
    } = useChat();
    
    const [ visibilityProp, setVisibityProp] = useState<boolean>(false);
    
    const ref = useRef(null)

    useEffect( () => {
        setVisibityProp(!!messageReply.content);

    },[messageReply]);

    function getMessage(message:string){ 
        // let name = message.split(/[\r\n]+/)[0].replace("*", "");
        const messageText = message.split(/[\r\n]+/)[1];
        
        // name = (name == localStorage.getItem("user") ? "Você" : name);
        return messageText;
    }

    function getNameSender(message:string){ 
        let name = message.split(/[\r\n]+/)[0].replaceAll('*', '');
        name = (name == localStorage.getItem("user") ? "Você" : name);
        return name;
    }

    function getContent() {
        if(!messageReply.content) return<></>;

        const message = messageReply.content;

        switch( message.type_message ){
            case EMessage.TEXT:
                if(message.sender==='agent') {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{getNameSender(message.message)}</strong>
                            {getMessage(message.message)}
                        </S.MessageReplyLabel>
                    </>;
                } else {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{message.participant_name}</strong>
                            {message.message}
                        </S.MessageReplyLabel>
                    </>;
                }
    
            case EMessage.IMAGE:
                if(message.sender==='agent') {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{getNameSender(message.message)}</strong>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsCardImage /> Imagem</div>
                        </S.MessageReplyLabel>
                    </>;
                } else {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{message.participant_name}</strong>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsCardImage /> Imagem</div>
                        </S.MessageReplyLabel>
                    </>;
                }

            case EMessage.AUDIO:
                if(message.sender==='agent') {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{getNameSender(message.message)}</strong>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsMic /> Áudio</div>
                        </S.MessageReplyLabel>
                    </>;
                } else {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{message.participant_name}</strong>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsMic /> Áudio</div>
                        </S.MessageReplyLabel>
                    </>;
                }
                
                
            case EMessage.VIDEO:
                if(message.sender==='agent') {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{getNameSender(message.message)}</strong>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsCameraReelsFill /> Vídeo</div>
                        </S.MessageReplyLabel>
                    </>;
                } else {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{message.participant_name}</strong>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsCameraReelsFill /> Vídeo</div>
                        </S.MessageReplyLabel>
                    </>;
                }
                

            case EMessage.DOCUMENT:
                if(message.sender==='agent') {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{getNameSender(message.message)}</strong>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsPaperclip /> Anexo</div>
                        </S.MessageReplyLabel>
                    </>;
                } else {
                    return <>
                        <S.MessageReplyLabel>
                            <strong>{message.participant_name}</strong>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsPaperclip /> Anexo</div>
                        </S.MessageReplyLabel>
                    </>;
                }
                
        
            default:
        }
        return <></>;
    }

   
    return(
        <S.MessageReplyContainer isActive={visibilityProp} ref={ref} >
            {getContent()}
            <FaWindowClose style={{cursor: 'pointer'}} onClick={() => setMessageReply({id:'',content:null})}/>
        </S.MessageReplyContainer >
    )
}