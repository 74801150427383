import styled from "styled-components";

export const ContainerApp = styled.div`
  height: 100vh;
  margin: 0px;
  padding:0px;

`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #F7F7F7;
  margin: 0 auto;
  max-width: 1600px;
  box-shadow: 0 6px 18px rgb(11 20 26 / 5%);
  @media (min-width: 1600px) {
    height: calc(100vh - 20px);
  }
`;

export const ContainerRoot = styled.div`
  background-color: var(--background);
  height: 100vh;
  @media (min-width: 1600px) {
    height: calc(100vh - 20px);
    padding-top: 20px;
  }
`;