import { createContext, useContext } from 'react';

export enum SidebarTabEnum {
    IN_ATTENDANCE         = 0,
    NO_ATTENDANT          = 1,
    IN_GENERAL_ATTENDANCE = 2
}

export type SidebarChatType = {
    inputField: string;
    setInputField: (inputField: string) => void;
    sidebarTabSelected: SidebarTabEnum;
    setSidebarTabSelected: (selected: SidebarTabEnum) => void;
}

export type SidebarHistoryType = {
    unreadMode: boolean;
    setUnreadMode: (unread: boolean) => void;
    historyMode: boolean;
    setHistoryMode: (history: boolean) => void;
}

export const SidebarChatContext = createContext<SidebarChatType>({ 
    inputField: "", 
    setInputField: (inputField)  => {} ,
    sidebarTabSelected: SidebarTabEnum.IN_ATTENDANCE,
    setSidebarTabSelected: (selected)  => {},
});

export const SidebarHistoryContext = createContext<SidebarHistoryType>({
    historyMode:false,
    setHistoryMode: (history)  => {},
    unreadMode: false,
    setUnreadMode: (unread)  => {}
});

export const useFilter = () => useContext(SidebarChatContext);
export const useHistory = () => useContext(SidebarHistoryContext);
