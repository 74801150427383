import styled from 'styled-components'

export const SidebarItem = styled.div`
    display: flex;
    padding: 15px 20px;
    border-bottom: 1px solid  var(--border-color);
    margin: 0 !important;
    cursor: pointer;
    align-items:center;
    background-color:var(  --background-sidebar-item);
    &:hover {
        background-color: var( --background-item-hover );
    }
`;

export const SidebarItemImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 100%;
`;

export const SidebarItemInfoRight = styled.div`
  display:flex;
  flex-direction:column;
  align-items:flex-end;
  margin-left: auto;
`;


export const SidebarItemName = styled.span`
    font-size: 100%;
    padding: 1% !important;
    text-align: left;
    color: var( --text-primary );
    margin-left:14px;
`;

export const SidebarItemTime = styled.span`
    text-align: right;
    font-size: 12px;
    padding: 1% !important;
    margin-left: auto;
    color: var( --text-primary);
    vertical-align: baseline;
`;

export const SidebarItemUnread = styled.span`
    display: flex;
    background-color: var(--background-unread);
    height: 20px;
    width: max-content;
    padding: 0px 0.4rem;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 2em;
    color: #fff;
    margin-top: 5px;
`;


export const SidebarItemNew = styled.span`
    display: flex;
    background-color: var(--background-new);
    height: 20px;
    width: 30px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 2em;
    color: #fff;
    margin-top: 5px;
`;

export const SidebarDepartment = styled.div`
    border-radius:25px;
    padding: 4px 12px;
    margin-left:14px;
    font-size: 10px;
    display: inline-block;
    margin-top:5px;
    background-color: var(--background-department);
    color: var(--text-department);
    max-width: max-content;

`;

export const SidebarNames = styled.div`
    display: flex;
    flex: 1;
    flex-direction:column;
`;


export const SidebarHistory = styled.div`
    border-radius:25px;
    padding: 4px 12px;
    margin-left:14px;
    font-size: 10px;
    display: inline-block;
    margin-top:5px;
    background-color: var(--background-department);
    color: var(--text-department);
    max-width: max-content;
`;

export const ImageWrapper = styled.div`
    display: flex;
    position: relative;
    height: 100%;
`;

export const SidebarAttendantimage = styled.img`
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 100%;
    object-fit: contain;
    bottom: 0;
    right: 0;
`;

export const SidebarItemTagsContainer = styled.div`
    margin-left: 14px;
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

`;

/**
  @description Se for usar esse componente com uma imagem válida, por favor, retire a própriedade background-color do mesmo. Ela exite somente pra fins de visualização.
*/
export const SidebarEmployeeImage = styled.img`
    max-width:30px;
`;

export const SidebarWrapperEmployeeImage = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--background-chat);
`;
