import { useEffect, useState } from "react";
import { Bar, Line, Pie } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

import { 
  ChartWrapper, 
  ChartsWrapper, 
  ComponentWrapper, 
  FilterInput, 
  FilterLabel, 
  InputsWrapper, 
  Title, Wrapper, 
  StatsWrapper, 
} from "./style";
import CustomSelect from "../../../Select";
import Stat from "../../../Stat";

import DatePicker from "react-datepicker";

import { ptBR as locale } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "./customDatePickerWidth.css";

Chart.register(CategoryScale);

/**
 * @description Se você precisar colocar uma cor especifica em um dataset, basta passar o atributo backgroundColor a cada dataset
 */
interface DatasetChartInterface {
  label: string;
  data: number[];
  backgroundColor?: string;
};

interface RenderChartInterface {
  labels?: string[];
  dataset?: DatasetChartInterface[];
  title?: string;
  type?: 'bar' | 'line' | 'pie';

  height?: string;
  width?: string;
};

const example = [
  { date: '2023-02-15', value: 10 },
  { date: '2023-02-14', value: 20 },
  { date: '2023-02-13', value: 50 },
  { date: '2023-02-12', value: 50 },
  { date: '2023-02-11', value: 40 },
  { date: '2023-02-10', value: 15 },
  { date: '2023-02-09', value: 60 },
  { date: '2023-02-08', value: 38 },
];

const ShowGraphics: React.FC = () => {
  // data states
  const [ labels, setLabels ] = useState<string[]>([]);
  const [ datasets, setDatasets ] = useState<DatasetChartInterface[]>([]);

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [users, setUsers] = useState<{ label: string; value: string;}[]>([
    { label: 'user 1', value: 'user 1' },
  ]);
  const [departaments, setDepartaments] = useState<{ label: string; value: string;}[]>([
    { label: 'departament 1', value: 'departament 1' },
  ]);
  const [employees, setEmployee] = useState<{ label: string; value: string;}[]>([
    { label: 'employee 1', value: 'employee 1' },
  ]);

  useEffect(() => {
    let labels: string[] = [];
    let dataset: DatasetChartInterface[] = [];

    example.forEach(item => {
      const user = 'user 1';
      
      if (!labels.includes(item.date)) labels.push(item.date);
      if (!dataset.find(item => item.label === user)) dataset.push({ label: user, data: [] });
      
      const index = dataset.findIndex(item => item.label === user);
      dataset[index].data.push(item.value);
    });

    example.forEach(item => {
      const user = 'user 2';

      if (!labels.includes(item.date)) labels.push(item.date);
      if (!dataset.find(item => item.label === user)) dataset.push({ label: user, data: [] });
      
      const index = dataset.findIndex(item => item.label === user);
      dataset[index].data.push(item.value);
    });

    setLabels(labels);
    setDatasets(dataset);
  }, []);

  function renderChart({
    labels = [],
    dataset = [],
    title = 'Title',
    type = 'bar',

    width = '50%',
    height = '100%',
    
  }: RenderChartInterface = {}) {
    if (type === 'bar') {
      return (
        <Bar 
          style={{ width, height }}
          data={{
            labels: labels,
            datasets: dataset
          }}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: title,
              },
              legend: { display: false }
            }
          }}
        />
      );
    } else if (type === 'line') {
      return (
        <Line 
          style={{ width, height }}
          data={{
            labels: labels,
            datasets: dataset
          }}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: title,
              },
              legend: { display: false }
            }
          }}
        />
      );
    } else if (type === 'pie') {
      return (
        <Pie 
          style={{ width, height }}
          data={{
            labels: labels,
            datasets: dataset
          }}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: title,
              },
              legend: { display: false }
            }
          }}
        />
      );
    };
  };

  return(
    <ComponentWrapper>
      <Wrapper>
        <Title>Gráficos</Title>

        <InputsWrapper>
          <CustomSelect 
            options={users}
            placeholder='Selecione a usuário'
            onSelected={(content) => {
              console.log('seleted users: ', content);
            }}
          />
          
          <CustomSelect 
            options={departaments}
            placeholder='Selecione a departamento'
            onSelected={(content) => {
              console.log('seleted departaments: ', content);
            }}
          />

          <CustomSelect 
            options={employees}
            placeholder='Selecione a empresa'
            onSelected={(content) => {
              console.log('seleted employees: ', content);
            }}
          />
        </InputsWrapper>

        <InputsWrapper >
          <FilterInput>
            <FilterLabel>Início</FilterLabel>
            <DatePicker
              locale={locale}
              selected={startDate}
              onChange={(date:Date) => setStartDate(date)}
              dateFormat='dd/MM/yyyy'
            />
          </FilterInput>

          <FilterInput>
            <FilterLabel>Fim</FilterLabel>
            <DatePicker
              selected={endDate}
              onChange={(date:Date) => setEndDate(date)}
              dateFormat='dd/MM/yyyy'
              maxDate={new Date()}
            />
          </FilterInput>
        </InputsWrapper>

        <StatsWrapper>
          <Stat 
            title='Interações iniciadas'
            value={`50`}
            description='hoje'
          />

          <Stat 
            title='Quantidade de chamados fechados/atualizados'
            value={`10`}
            description='hoje'
          />

          <Stat 
            title='Tempo de inicío de atendimento'
            value={`5`}
            description='em média'
          />

          <Stat 
            title='Tempo médio de resolução'
            value={`20`}
            description='em média'
          />
        </StatsWrapper>

        <ChartsWrapper>
          <ChartWrapper>
            {renderChart({ 
              dataset: datasets, 
              labels: labels, 
              title: 'Interações iniciadas', 
              type: 'bar'
            })}     
          </ChartWrapper>

          <ChartWrapper>
            {renderChart({ 
              dataset: datasets, 
              labels: labels, 
              title: 'Interações iniciadas', 
              type: 'pie'
            })}     
          </ChartWrapper>
          <ChartWrapper>
            {renderChart({ 
              dataset: datasets, 
              labels: labels, 
              title: 'Interações iniciadas', 
              type: 'line'
            })}     
          </ChartWrapper>
        </ChartsWrapper>
      </Wrapper>
    </ComponentWrapper>
  )
}

export default ShowGraphics;
