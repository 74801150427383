import styled from 'styled-components'


export const EmployeeImage = styled.img`
    max-width:100%;
    border-radius: 50%;

`;

export const WrapperEmployeeImage = styled.div`
    height: 38px;
    width: 38px;
    border-radius: 50%;
    border: 1px solid var(--border-image-company);
    background-color: var(--background-chat);
    display: flex;
    justify-content: center;
    align-items: center;
`;
