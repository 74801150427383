import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 4rem;
  justify-content: space-between;
`;
export const Label = styled.label`
  font-size: large;
  font-weight:500;
  display: block;
`;
export const Input = styled.input`
  height: 50%;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: #EAF1E8;
  &:focus {
        outline: 1px;
        box-shadow: 0px 0px 2px #0B131B;
    }
`;
