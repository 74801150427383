import styled from 'styled-components'

interface IPropsChatBodyItem {
    isAgent:boolean,
    isText: boolean,
    isReply: boolean,
    shouldHaveBackground: boolean,
}

interface IPropsChatBodyItemContainer{
    isAgent: boolean,
}

export const ChatBodyItemContainer = styled.div<IPropsChatBodyItemContainer>`
    display: flex;
    margin-left:9%;
    margin-right:9%;
    margin-bottom:20px;
    align-items:${
        props => props.isAgent ? 'flex-end' : 'flex-start'
    };
    flex-direction:column;
`;

export const ChatBodyItemAllMessage = styled.div<IPropsChatBodyItem>`
    border-radius:10px;
    display: flex;
    background-color:${
        props => ( props.isText ) ?  ( (props.isAgent ) ? 'var(--chat-send);' : 'var(--chat-incoming);' ) : ((props.shouldHaveBackground || props.isReply) ? 'var(--chat-incoming);' : '')
    };
    flex-direction: ${
        props => props.isReply ? 'column' : 'row'
    };
    color:var(--text-primary);
    font-size: 14px;
    font-weight: 500;
`;


export const ChatBodyItemResponseMessage = styled.div<IPropsChatBodyItem>`
    
    border-radius:10px;
    box-shadow:0 1px 0.5px rgb(0 0 0 / 13%);
    display:flex;
    margin:2px;
    flex-direction: row;
    justify-content: space-between;
    color:var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    background-color:${
        props => ( (props.isAgent ) ? 'var(--chat-reply-send);' : 'var(--chat-reply-incoming);' )
    };
    cursor: pointer;
    
`;

export const ChatBodyItemMessage = styled.div<IPropsChatBodyItem>`

    border-radius:10px;
    box-shadow:${
        props => ( props.isText ) ? '0 1px 0.5px rgb(0 0 0 / 13%);' : '' 
    }
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    color:var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
`;
export const TimeMessage = styled.span`
    color: #999;
    font-size: 9px;
    position: relative;
    padding-top: 12px;
    padding-left: 15px;
    padding-right:10px;
  
`;

export const TimeMessageDocument = styled.span`
    color: #999;
    font-size: 9px;
    position: relative;
    padding-top: 12px;
    padding-right:10px;
`;
export const TimeMessageImage = styled.span`
    margin-right:10px;
`;

export const MessageContainer = styled.div`
    display:flex;
    flex-direction:column;
    padding: 6px 7px 8px 9px;
    white-space: pre-line;
`;

export const MessageImageContainer = styled.div`
    border-radius:3px;
    position:relative;
    margin: 5px 5px 0px 5px;
`;


export const MessageTimeImage = styled.div`
    position: absolute;
    width: 100%;
    color: #fff;
    font-size:12px;
    text-align: right;
    bottom: 4px;
    padding-bottom:5px;
    padding-top:5px;
    background-image: linear-gradient(to top,rgba(var(--overlay-rgb),.5),rgba(var(--overlay-rgb),0));
`;

export const MessageImage = styled.img`
    border-radius:3px;
    max-width:300px;
    max-height:300px;
`;

export const MessageLinkDocument = styled.a`
    padding: 5px 10px;
    border-radius: 4px;
    color: var(--text-primary);
    text-decoration: none;
`;

export const MenuOptions = styled.div`
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1)
`;

export const MenuOptionsButton = styled.button`
    display: block;
    width: 100%;
    border: none;
    text-align: left;
    &:hover {
        background-color: var(--chat-reply-send);
        color:var(--text-primary);
    }
    cursor: pointer;
`;