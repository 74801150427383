import { Alert, Snackbar } from "@mui/material"
import { useMainContext } from "../../Contexts/Contexts";


interface SnacbackInterface {
  severity: 'error' | 'success',
  snackbarText: String;
}
export function ErrorSnackbar({ severity, snackbarText }: SnacbackInterface) {
  const idContext = useMainContext();

  const handleCloseErrorPopup = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    idContext.setPopupModalErrorOpen(false);
  };

  return (
    <Snackbar open={idContext.popupModalErrorOpen} autoHideDuration={2000} onClose={handleCloseErrorPopup}>
      <Alert onClose={handleCloseErrorPopup} severity={severity} sx={{ width: '100%' }}>
        {snackbarText}
      </Alert>
    </Snackbar>
  )
}