import styled from "styled-components";

export const WrapperTable = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    overflow: auto;
`;

export const WrapperButtons = styled.div`
    display:flex;
    justify-content:flex-end;
`;

export const ItemButton = styled.div`
    display:flex;
    padding:5px;
    cursor:pointer;
`
export const IsEmpty = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:large;
    font-weight:300;
`