import {
  IoIosBrowsers,
  IoMdBriefcase,
  IoMdCog,
  IoMdLink,
  IoMdList,
  IoMdPerson
} from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SidebarHeader from "../../SidebarHeader";

import { AppBar, Box } from "@mui/material";
import { IconType } from "react-icons";
import SidebarButton from "../SidebarButton";
import { ButtonsContainer, SidebarWrapper } from "./styles";

export interface SideMenuEntry {
  icon: IconType;
  text: string;
  navigateTo: string;
}

export const adminSideMenuItems: SideMenuEntry[] = [
  {
    icon: IoMdPerson,
    text: "Usuários",
    navigateTo: "/admin/users"
  },
  {
    icon: IoMdBriefcase,
    text: "Departamentos",
    navigateTo: "/admin/departaments",
  },
  {
    icon: IoMdList,
    text: "Relatórios",
    navigateTo: "/admin/reports"
  },
  // {
  //   icon: IoMdTrendingUp,
  //   text: "Gráficos",
  //   navigateTo: "/admin/graphics"
  // },
  {
    icon: IoMdLink,
    text: "Webhooks",
    navigateTo: "/admin/webhook"
  },
  {
    icon: IoIosBrowsers,
    text: "Mensagens pré definidas",
    navigateTo: "/admin/predefined-messages"
  },
  {
    icon: IoMdCog,
    text: "Configurações",
    navigateTo: "/admin/config"
  }
];

const AdminSidebar: React.FC = () => {

  const nav = useNavigate();
  const hideText = false;



  return (
    <>
      <Box sx={{ display: { md: "none", } }}>
        <AppBar>
          <SidebarHeader func={() => { }} />
        </AppBar>
      </Box>
      <Box sx={{ display: { sm: "none", xs: "none", md: "contents" } }}>
        <SidebarWrapper>
          <SidebarHeader func={() => { }} />
          <ButtonsContainer>
            {adminSideMenuItems?.map((item) => <SidebarButton
              Icon={item.icon}
              title={item.text}
              handleFunction={() => { nav(item.navigateTo) }}
            />)}
          </ButtonsContainer>

        </SidebarWrapper>
      </Box>
    </>
  );
}

export default AdminSidebar;
