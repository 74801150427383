import styled from 'styled-components'

export const ChatBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    padding-top:10px;
    background-color:var(--background-chat);
`;


export const ChatHistorySelect = styled.div`
    display:flex;
    justify-content:center;
    margin-left:30px;
    margin-right:30px;
    color: var(--text-light);
    position:relative;
    margin-bottom:20px;
`;

export const ChatHistorySeparatorData = styled.div`
    z-index:1;
    background-color: var(--background-chat);
    padding:0px 10px 0px 10px;
    display:flex;
`;

export const ChatHistorySeparatorWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
`;

export const ChatHistorySeparator = styled.hr`
    border: 0;
    border-bottom: 1px solid var(--border-color-hr);
    width: 100%;
    margin-right: 10px; /* Ajuste opcional para dar espaço antes do texto */
`;

export const ChatHistoryTime = styled.span`
    color: var(--text-light);
    white-space: nowrap;
    align-self: center;
    justify-self: end;
`;

export const ChatHistorySeparatorEnd = styled.hr`
    border:0;
    border-bottom: 1px solid var(--border-color-hr);
    width: 100%;
    margin-left:30px;
    margin-right:30px;
    margin-bottom:20px
`;

export const ChatHistoryButtonLoad = styled.div`
    font-size: 25px;
    margin: 0px 10px;
    cursor:pointer;
`;

export const ChatLoadHistory = styled.div`
    
    text-align: center;
    font-size: 10px;
    padding: 4px;
    border-bottom: 1px solid var(--text-primary);
    color: var(--text-primary);
    font-weight: 700;
    cursor: pointer;
    max-width: max-content;
`;
export const ChatContainerLoadHistory = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    position:relative;
`;

export const ChatHistoryLoadSeparator = styled.hr`
    border:0;
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    margin-left:30px;
    margin-right:30px;
    margin-bottom:20px
`;

export const LoadMessageContainer = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0px;
`;

export const LoadMessageItem = styled.li`
    color: #939393;
    height: 40px;
    padding: 2px 18px;
    width: auto;

    display: flex;

    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;

    background-color: var(--chat-incoming);

    white-space: pre-line;

    border-radius: 10px;

    display: flex;
    align-items: center;
`