import { Alert, alpha, AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputBase, InputLabel, MenuItem, Modal, Snackbar, SnackbarOrigin, Stack, styled, TextField, Toolbar, Typography, useMediaQuery } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';

import * as S from "./styles";

import React, { useEffect, useState } from "react";
import TablePM, { RowType } from "./Table";
import MultiSelect from "./MultiSelect";

import { FaPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { useTheme } from '@mui/material/styles';


import predefinedMessagesService from '../../../service/predefinedMessageService';
import { DTOPredefinedMessageCreate, DTOPredefinedMessageUpdate, IPredefinedMessage } from "../../../interface/IModels";
import userService from "../../../service/userService";
import departmentService from "../../../service/departmentService";

export interface Snackbar {
    open: boolean;
    message: string;
    severity: AlertColor;
}

interface DialogRemove {
    open: boolean;
    key: number;
}

interface ModalOptions {
    open: boolean;
    mode: 'CREATE' | 'UPDATE';
}
export type AlertColor = 'success' | 'info' | 'warning' | 'error';

const PredefinedMessagesManager: React.FC = () => {

    const { register, clearErrors, setError, reset, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' });


    const [predefinedMessages, setPredefinedMessages] = useState<IPredefinedMessage[]>([]);
    const [modalControl, setModalControl] = useState<ModalOptions>({ open: false, mode: 'CREATE' });
    const [dialogOpen, setDialogOpen] = useState<DialogRemove>({ open: false, key: 0 });
    const [rowsFormatted, setRowFormatted] = useState<RowType<any>[]>([]);
    const [companys, setCompanys] = useState<{ id: number, value: string }[]>([]);
    const [departments, setDepartments] = useState<{ id: number, value: string }[]>([]);
    const [departmentsSelected, setDepartmentsSelected] = useState<number[]>([]);
    const [snackBar, setSnackBar] = useState<Snackbar>({ open: false, message: '', severity: 'info' })

    const [companySelected, setCompanySelected] = useState<number>(0);

    const headers = {
        id: "Identificador",
        title: "Termo de busca",
        body: "Mensagem",
    };

    const handleCloseSnack = () => {
        setSnackBar({
            open: false,
            message: '',
            severity: 'info',
        })
    }

    useEffect(() => {

        userService.getRelationUserCompany().then(response => {

            setCompanys(response.map(c => { return { id: parseInt(c.id_company), value: c.name_company } }));

            if (response.length > 0) {

                const companySelect = parseInt(response[0].id_company);

                setCompanySelected(companySelect);

                searchDepartmentsByCompany(companySelect);
            }
        });

    }, []);

    useEffect(() => {
        searchDepartmentsByCompany(companySelected);
        searchPredefinedMessages(companySelected);
    }, [companySelected]);

    useEffect(() => {
        if (!modalControl.open) {
            reset();
            setDepartmentsSelected([]);
        }

    }, [modalControl]);

    const searchDepartmentsByCompany = async (id: number) => {        
        departmentService.getDepartmentByCompanyID(id).then(
            result => {

                if (!result) return;

                const departmentsOptionsFormatted = result.map((dep: any) => {
                    return { value: dep.name, id: parseInt(dep.id) }
                });

                setDepartments(departmentsOptionsFormatted);

            }
        );
    }

    const searchPredefinedMessages = (id_company: number) => {

        predefinedMessagesService.listPredefinedMessagesByCompany(id_company)
            .then(res => {

                if (typeof res == "boolean") {
                    setRowFormatted([]);
                    return;
                }

                setPredefinedMessages(res);

                const rows = res.map(pf => {
                    return {
                        value: {
                            id: pf.id,
                            title: pf.title,
                            body: pf.body,
                        },
                        key: String(pf.id)
                    }
                });
                setRowFormatted(rows);

            })
            .catch(error => {
                setRowFormatted([]);
            });
    }

    const [searchValue, setSearchValue] = useState<string>("");

    const handleEditButton = (key: any) => {

        const predefinedMessageExists = predefinedMessages.find(item => item.id == key);

        if (predefinedMessageExists) {
            setValue("id", predefinedMessageExists.id);
            setValue("title", predefinedMessageExists.title);
            setDepartmentsSelected(predefinedMessageExists.departments.map(dep => dep.id));
            setValue("message", predefinedMessageExists.body);
        }
    }

    const handleCreatePredefinedMessage = () => {
        reset();

        setModalControl({ open: true, mode: 'CREATE' });

    }

    const handleSubmitForm = () => {

        const values = ["title", "message"];

        const errors = values.filter(item => {
            let value = getValues(item);
            if (!value) {
                setError(item, { type: "required", message: "Campo não pode ser vazio!" });
                return item;
            }

            if (item === 'title' && String(getValues('title')).length > 25) {
                setError(item, { type: 'maxLenght', message: 'Tamanho máximo 25 caracteres' });
                return item;
            }
        });

        if (departmentsSelected.length === 0) {
            setError('department', { type: 'required' });
            return;
        }

        if (errors && errors.length > 0) return;

        const dataTitle = String(getValues("title"));
        const dataBody = String(getValues("message"));

        let optionsDep = departments.filter(dep => departmentsSelected.find(departmentSelect => departmentSelect === dep.id));
        const depsFromatted = optionsDep.map(dep => { return { name: dep.value, id: dep.id } });

        if (modalControl.mode === 'CREATE') {

            const dtoPredefinedMessage: DTOPredefinedMessageCreate = {
                title: dataTitle,
                body: dataBody,
                id_company: companySelected,
                ids_departments: departmentsSelected
            };

            predefinedMessagesService.createPredefinedMessage(dtoPredefinedMessage)
                .then(response => {

                    if (typeof response == 'boolean') {
                        setSnackBar({ open: true, message: `Mensagem com título: (${dataTitle}), já existe!`, severity: 'error' });
                        return;
                    }
                    setModalControl({ open: false, mode: 'CREATE' });

                    setSnackBar({ open: true, message: `Mensagem com título: (${dataTitle}), foi criada!`, severity: 'success' });

                    const predefinedMessage: IPredefinedMessage = {
                        id: response.id,
                        title: dtoPredefinedMessage.title,
                        body: dtoPredefinedMessage.body,
                        id_company: dtoPredefinedMessage.id_company,
                        departments: depsFromatted
                    }


                    handlePushNewPredefinedMessageInRow(predefinedMessage)

                }).catch(error => {

                    setSnackBar({ open: true, message: "Erro ao criar, contate o administrador", severity: 'error' });

                });

        } else if (modalControl.mode === 'UPDATE') {

            const dataID = parseInt(getValues("id"));

            if (!dataID) {
                setSnackBar({ open: true, message: `Error ao identificar mensagem`, severity: 'error' });
                return;
            }

            const dtoPredefinedMessage: DTOPredefinedMessageUpdate = {
                id: dataID,
                title: dataTitle,
                body: dataBody,
                ids_departments: departmentsSelected
            };

            predefinedMessagesService.updatePredefinedMessage(dtoPredefinedMessage).then(
                res => {

                    if (typeof res == "boolean") {
                        setSnackBar({ open: true, message: `Error ao atualizar`, severity: 'error' });
                        return;
                    }

                    const predefinedMessage: IPredefinedMessage = {
                        id: dtoPredefinedMessage.id,
                        title: dtoPredefinedMessage.title,
                        body: dtoPredefinedMessage.body,
                        departments: depsFromatted,
                    }

                    handleEditOldPredefinedMessageInRow(predefinedMessage);
                    setSnackBar({ open: true, message: `Mensagem foi atualizada com sucesso!`, severity: 'success' });
                    setModalControl({ open: false, mode: 'CREATE' });

                }
            ).catch(error => {
                setSnackBar({ open: true, message: `Error ao atualizar`, severity: 'error' });
                return;
            });
        }
    }

    const handleSelectCompanys = (event: SelectChangeEvent) => {
        setCompanySelected(parseInt(event.target.value));
    }

    const handleRemovePredefinedMessage = () => {

        setDialogOpen({ open: false, key: 0 })

        if (dialogOpen.key == 0) return;

        predefinedMessagesService.deletePredefinedMessage(dialogOpen.key).then(
            res => {
                if (res) {
                    handleFilterSearch(dialogOpen.key);
                    setSnackBar({ open: true, message: 'Removido com sucesso', severity: 'success' });
                } else {
                    setSnackBar({ open: true, message: 'Erro ao remover', severity: 'error' });
                }
            }
        )
    }

    const handleFilterSearch = (idRemove: number) => {
        const filters = predefinedMessages.filter(pm => pm.id != idRemove);
        setPredefinedMessages(filters);

        const rows = filters.map(pm => {
            return {
                value: {
                    id: pm.id,
                    title: pm.title,
                    body: pm.body,
                },
                key: String(pm.id)
            }
        });
        setRowFormatted(rows);
    }

    const handlePushNewPredefinedMessageInRow = (predefinedMessage: IPredefinedMessage) => {
        const filters = predefinedMessages;
        filters.push(predefinedMessage);
        setPredefinedMessages(filters);

        const row = {
            value: {
                id: predefinedMessage.id,
                title: predefinedMessage.title,
                body: predefinedMessage.body,
            },
            key: String(predefinedMessage.id)
        }

        setRowFormatted([...rowsFormatted, row]);
    }

    const handleEditOldPredefinedMessageInRow = (predefinedMessage: IPredefinedMessage) => {

        const filters = predefinedMessages.map(pf => {
            if (pf.id === predefinedMessage.id) return predefinedMessage;
            return pf;
        })

        setPredefinedMessages(filters);

        const updatedRow = {
            value: {
                id: predefinedMessage.id,
                title: predefinedMessage.title,
                body: predefinedMessage.body,
            },
            key: String(predefinedMessage.id)
        }

        const filterRows = rowsFormatted.map(row => {
            if (row.key === String(predefinedMessage.id)) return updatedRow;
            return row;
        })

        setRowFormatted(filterRows);
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const renderMessageHeaderModal = () => {
        if (modalControl.mode === 'CREATE') {
            return (
                <>
                    <h2 id="parent-modal-title">Criar mensagem</h2>
                    <span id="parent-modal-description">Criar mensagem pré definidas</span>
                </>
            )
        } else {
            return (
                <>
                    <h2 id="parent-modal-title">Editar mensagem</h2>
                    <span id="parent-modal-description">Edição de mensagem pré definidas</span>
                </>
            )
        }
    }

    return (
        <S.ContainerPredefinedMessagesManager>

            <Box sx={{}}>
                <AppBar position="static" sx={{ backgroundColor: 'var(--background-company)', padding: "0.3rem" }} >
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                            Mensagens pré definidas
                        </Typography>

                        <S.CreateButton onClick={() => handleCreatePredefinedMessage()} endIcon={<FaPlus />} >Cadastrar novo</S.CreateButton>
                        <S.Search>
                            <S.SearchIconWrapper>
                                <SearchIcon />
                            </S.SearchIconWrapper>
                            <S.StyledInputBase
                                placeholder="Buscar"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(event) => setSearchValue(event.target.value)}
                            />
                        </S.Search>

                    </Toolbar>
                </AppBar>
            </Box>

            <S.WrapperForm>
                <FormControl variant='filled' sx={{ marginTop: 2, marginLeft: 2.2 }} >
                    <InputLabel id={'select-company-label'}>Empresa</InputLabel>
                    {companys && companys.length > 0 ?

                        <Select sx={{ width: 140 }}
                            id="select-company"
                            variant="filled"
                            {...register("company")}
                            defaultValue={String(companys[0].id)}
                            onChange={handleSelectCompanys} >
                            {companys.map((comp, index) => <MenuItem key={index} value={comp.id}>{comp.value}</MenuItem>)}

                        </Select>
                        : ''}
                </FormControl>
            </S.WrapperForm>

            <TablePM
                searchKey="title"
                items={rowsFormatted}
                headers={headers}
                compress={true}
                searchValue={searchValue}
                styleTable={{ maxWidth: 1320, margin: '1.4rem', boxShadow: 'none' }}
                onClickEditButton={(key) => {
                    handleEditButton(key);
                    setModalControl({ open: true, mode: 'UPDATE' });
                }}
                onClickDeleteButton={(key) => {
                    setDialogOpen({ open: true, key: parseInt(key) });
                }}
            />

            <Modal
                open={modalControl.open}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <Box sx={S.StyleModal}>

                    <S.WrapperContainer>
                        <S.WrapperHeader>
                            {renderMessageHeaderModal()}
                        </S.WrapperHeader>
                        <S.WrapperForm>

                            <TextField
                                id="filled-basic"
                                sx={{ display: 'none' }}
                                variant="filled"
                                {...register("id")}

                            />
                            <TextField
                                sx={{ width: '100%' }}
                                id="filled-basic"
                                label="Título"
                                variant="filled"
                                {...register("title", { required: true, maxLength: 25 })}
                                error={Boolean(errors.title)}
                                helperText={errors.title?.type == "required" ? 'Campo necessário' : '' || errors.title?.type == "maxLength" ? 'Tamanho máximo 25 caracteres' : ''}

                            />

                            <MultiSelect
                                style={{ marginTop: '20px' }}
                                label="Departamentos"
                                variant="filled"
                                {...register("department", { required: true })}
                                options={departments}
                                optionsSelected={departmentsSelected}
                                error={Boolean(errors.department)}
                                onSelect={(items) => {
                                    if (items.length > 0) {
                                        clearErrors("department");
                                    }
                                    setDepartmentsSelected(items);
                                }}
                            />

                            <TextField sx={{ width: '100%', marginTop: "1.2rem" }}
                                id="filled-basic"
                                multiline
                                rows={8}
                                label="Mensagem"
                                variant="filled"
                                {...register("message")}
                                error={Boolean(errors.message)}
                                helperText={errors.message?.type == "required" ? 'Campo necessário' : ''}

                            />
                        </S.WrapperForm>

                        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }} spacing={2} >
                            <Button
                                sx={{ color: 'var(--primary)' }}
                                color="secondary"
                                onClick={() => setModalControl({ open: false, mode: 'CREATE' })}>Voltar</Button>
                            <Button variant="contained" color="success" onClick={() => handleSubmitForm()}>Salvar</Button>
                        </Stack>
                    </S.WrapperContainer>
                </Box>
            </Modal>

            <Dialog
                fullScreen={fullScreen}
                open={dialogOpen.open}
                onClose={() => setDialogOpen({ open: true, key: 0 })}
                aria-labelledby="responsive-dialog-title"
            >

                <DialogTitle sx={{ background: 'var(--background)' }} id="responsive-dialog-title">
                    {"Remover mensagem pré definida"}
                </DialogTitle>
                <DialogContent sx={{ background: 'var(--background)' }}>
                    <DialogContentText>
                        Tem certeza que deseja remover essa mensagem pré definida?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ background: 'var(--background)' }}>
                    <Button autoFocus onClick={() => setDialogOpen({ open: false, key: 0 })}>Cancelar</Button>
                    <Button sx={{ color: 'red' }} onClick={() => handleRemovePredefinedMessage()} autoFocus>Remover</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackBar.open}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseSnack} severity={snackBar.severity} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </S.ContainerPredefinedMessagesManager>
    )
}

export { PredefinedMessagesManager }