export enum UserRole {
  master = 1,
  admin,
  agent,
}

export enum EMessage {
    TEXT     = "TEXT",
    IMAGE    = "IMAGE",
    AUDIO    = "AUDIO",
    VIDEO    = "VIDEO",
    DOCUMENT = "DOCUMENT",
    LOCATION = "LOCATION",
}