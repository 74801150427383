import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import * as C from './styles';

import { AdminPanelSettings, Logout, Notifications, NotificationsOff, Person, Sms, SmsFailed } from '@mui/icons-material';
import { Button, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useAppDispatch } from "../../hooks/redux-hooks";
import logoutService from '../../service/logoutService';
import { resetState } from '../../store/chat-actions';
import { UserRole } from "../../util/enums";
import { StyledDialog } from '../Admin/Departament/EditDepartament/styles';
import { ModalButtonRow, ModalTitle } from '../Admin/Departament/ListDepartamentCard/styles';

type handleCloserType = (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;

interface props {
    handleOpener: boolean,
    handleCLoser: handleCloserType,
    anchorEl: HTMLElement | null,
}
const role = localStorage.getItem("role");

export function MenuItens({ handleOpener, handleCLoser, anchorEl }: props) {
    const dispatch = useAppDispatch();

    async function handleLogout() {
        const userId = localStorage.getItem("id");
        if (userId) {
            const success: Boolean = await logoutService.logout(userId);
            if (success) {
                localStorage.clear();
                dispatch(resetState());
                nav("/");
            }
        }
    }
    const theme = localStorage.getItem('theme');
    const [visualNotification, setVisualNotification] = useState<boolean>();
    const [soundNotification, setSoundNotification] = useState<boolean>();

    const nav = useNavigate();

    const [modalNotification, setModalNotification] = useState<boolean>(false);

    const handleClose = () => {
        setModalNotification(false)
    }

    const handleVisualNotification = (value: boolean) => {
        localStorage.setItem("visualNotification", value ? "true" : "false");
        setVisualNotification(value);
    }

    const handleSoundNotification = (value: boolean) => {
        localStorage.setItem("soundNotification", value ? "true" : "false");
        setSoundNotification(value);
    }

    useEffect(() => {

    }, [soundNotification])

    useEffect(() => {
        const notificationValue = localStorage.getItem("visualNotification");
        const soundNotificationValue = localStorage.getItem("soundNotification");

        { soundNotificationValue == "true" ? handleSoundNotification(true) : handleSoundNotification(false) }
        { notificationValue == "true" ? handleVisualNotification(true) : handleVisualNotification(false) }

    }, [visualNotification, soundNotification])

    return (
        <>
            {theme == "dark" ?
                <Menu
                    id="longMenu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={handleOpener}
                    onClose={handleCLoser}
                    PaperProps={{
                        style: {
                            width: '25ch',
                            background: "var(--menu-background)",
                            color: "var(--input-bg-color)"
                        },
                    }}
                >

                    {role == UserRole.master.toString() ?
                        <Tooltip title="Admin" placement='right'>
                            <MenuItem key={"option"} onClick={() => { nav("/admin/users"); }} >

                                <ListItemIcon>
                                    <C.SidebarHeaderDefault>
                                        <AdminPanelSettings sx={{ fontSize: 28 }} />
                                    </C.SidebarHeaderDefault>
                                </ListItemIcon>
                                <Typography variant="inherit">Admin</Typography>

                            </MenuItem>
                        </Tooltip>
                        :
                        ""
                    }

                    <Tooltip title="Perfil" placement='right'>
                        <MenuItem key={"option"} onClick={() => { nav("/profile/"); }} >

                            <ListItemIcon>
                                <C.SidebarHeaderDefault>
                                    <Person sx={{ fontSize: 27 }} />
                                </C.SidebarHeaderDefault>
                            </ListItemIcon>
                            <Typography variant="inherit">Perfil</Typography>

                        </MenuItem>
                    </Tooltip>

                    <Tooltip title={visualNotification ? "Desativar notificações visuais" : "Ativar notificações visuais"} placement='right'>
                        <MenuItem key={"option"} onClick={() => handleVisualNotification(!visualNotification)}>

                                <ListItemIcon>
                                    {visualNotification ?
                                        <C.SidebarHeaderNotification>
                                            <Sms />
                                        </C.SidebarHeaderNotification>
                                        :
                                        <C.SidebarHeaderDefault>
                                            <SmsFailed />
                                        </C.SidebarHeaderDefault>
                                    }
                                </ListItemIcon>
                            <Typography variant="inherit">Notificação visual</Typography>
                        </MenuItem>
                    </Tooltip>

                    <Tooltip title={soundNotification ? "Desativar notificações sonoras" : "Ativar notificações sonoras"} placement='right'>
                        <MenuItem key={"option"} onClick={() => handleSoundNotification(!soundNotification)}>

                                <ListItemIcon>
                                    {soundNotification ?
                                        <C.SidebarHeaderNotification>
                                            <Notifications />
                                        </C.SidebarHeaderNotification>
                                        :
                                        <C.SidebarHeaderDefault>
                                            <NotificationsOff />
                                        </C.SidebarHeaderDefault>
                                    }
                                </ListItemIcon>
                            <Typography variant="inherit">Notificação sonora</Typography>

                        </MenuItem>
                    </Tooltip>

                    <Tooltip title="Desconectar usuário" placement='right'>
                        <MenuItem key={"option"} sx={{ ":hover": { color: "red", svg: { color: "red" } } }}
                            onClick={() => { setModalNotification(true); }} >

                            <ListItemIcon>
                                <C.SidebarHeaderDefault>
                                    <Logout />
                                </C.SidebarHeaderDefault>
                            </ListItemIcon>
                            <Typography variant="inherit">Desconectar</Typography>

                        </MenuItem>
                    </Tooltip>

                </Menu >
                :
                <Menu
                    id="longMenu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={handleOpener}
                    onClose={handleCLoser}
                    PaperProps={{
                        style: {
                            width: '25ch',
                            background: "var(--background-secondary)",
                            color: "var(--text-modal)"
                        },
                    }}
                >
                    <Tooltip title="Admin" placement='right'>
                        <MenuItem key={"option"} onClick={() => { nav("/admin/users"); }} >

                            <ListItemIcon>
                                <C.SidebarHeaderDefault>
                                    <AdminPanelSettings sx={{ fontSize: 28 }} />
                                </C.SidebarHeaderDefault>
                            </ListItemIcon>
                            <Typography variant="inherit">Admin</Typography>

                        </MenuItem>
                    </Tooltip>

                    <Tooltip title="Perfil" placement='right'>
                        <MenuItem key={"option"} onClick={() => { nav("/profile/"); }} >

                            <ListItemIcon>
                                <C.SidebarHeaderDefault>
                                    <Person sx={{ fontSize: 27 }} />
                                </C.SidebarHeaderDefault>
                            </ListItemIcon>
                            <Typography variant="inherit">Perfil</Typography>

                        </MenuItem>
                    </Tooltip>

                    <Tooltip title={visualNotification?"Desativar notificações visuais":"Ativar notificações visuais"} placement='right'>
                            <MenuItem key={"option"} onClick={() => handleVisualNotification(!visualNotification)}>

                                <ListItemIcon>
                                    {visualNotification ?
                                        <C.SidebarHeaderNotification>
                                            <Sms />
                                        </C.SidebarHeaderNotification>
                                        :
                                        <C.SidebarHeaderDefault>
                                            <SmsFailed />
                                        </C.SidebarHeaderDefault>
                                    }
                                </ListItemIcon>
                                <Typography variant="inherit">Notificação visual</Typography>

                            </MenuItem>
                    </Tooltip>

                    <Tooltip title={soundNotification?"Desativar notificações sonoras":"Ativar notificações sonoras"} placement='right'>
                            <MenuItem key={"option"} onClick={() => handleSoundNotification(!soundNotification)}>

                                <ListItemIcon>
                                    {soundNotification ?
                                        <C.SidebarHeaderNotification>
                                            <Notifications />
                                        </C.SidebarHeaderNotification>
                                        :
                                        <C.SidebarHeaderDefault>
                                            <NotificationsOff />
                                        </C.SidebarHeaderDefault>
                                    }
                                </ListItemIcon>
                                <Typography variant="inherit">Notificação sonora</Typography>

                            </MenuItem>
                    </Tooltip>

                    <Tooltip title="Desconectar usuário" placement='right'>
                        <MenuItem key={"option"} sx={{ ":hover": { color: "red", svg: { color: "red" } } }}
                            onClick={() => { setModalNotification(true); }} >

                            <ListItemIcon>
                                <C.SidebarHeaderDefault>
                                    <Logout />
                                </C.SidebarHeaderDefault>
                            </ListItemIcon>
                            <Typography variant="inherit">Desconectar</Typography>

                        </MenuItem>
                    </Tooltip>
                </Menu>
            }

            <StyledDialog
                open={modalNotification}
                onClose={handleClose}>
                <div style={{ padding: '1rem', background: "var(--backgroundModal)" }}>

                    <ModalTitle>Desconectar conta</ModalTitle>
                    <p>Deseja se desconectar?</p>

                    <ModalButtonRow style={{ gap: '1rem' }}>
                        <Button sx={{ color: 'var(--primary)' }} color="secondary" onClick={handleClose}>Não</Button>
                        <Button variant="contained" sx={{ width: '100%' }} color="success" onClick={() => { handleLogout() }}>Sim</Button>
                    </ModalButtonRow>

                </div>
            </StyledDialog>
        </>
    )
}