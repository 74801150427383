import styled from 'styled-components';

export const WebhookFormWrapper = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  width: 35rem;
  background-color: var(--backgroundModal);
  border: 1px solid var(--background-admin-edit-border);
  color: var(--background-admin-edit-color);
  justify-content: space-between;
  padding: 1rem;
  input{
    color: var(--text-modal);
    background: var(--input-bg-color);
    border-radius: 4px;
  }

  ::placeholder{
    color: var(--text-modal);
  }
`;

export const WebhookInputWrapper = styled.div`
  margin-top:20px;
  width:100%;
`;


export const WebhookForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export const WrapperWebhookSearch = styled.div`
  border: 1px solid #ccc;
  margin-top: 1.5rem;
  width: 80%;
  padding: 1rem;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.9px);
  -webkit-backdrop-filter: blur(9.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
`;