import { SelectedItemRow } from "./styles";
import {IoMdTrash} from 'react-icons/io'

interface IFormSelectedItem {
  label: string
  removeFunc: Function
  id: string
}

const FormSelectedItem: React.FC<IFormSelectedItem> = ({label, id, removeFunc}) => {
  return (
  <SelectedItemRow>
    {label}
    <IoMdTrash onClick={() => {removeFunc(id)}}/>
  </SelectedItemRow>
  );
}

export default FormSelectedItem;
