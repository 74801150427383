import React, { ReactNode, useState, useEffect } from "react";

import { EMessage } from "../../util/enums";

import * as C from './styles';

import axios from 'axios';
interface Props {
    url: string | undefined;
    type:string;
    participantName?: string;
    time: string;

    content: ReactNode;
};

const ChatBodyFileItem: React.FC <Props> = ({
    url,
    type,
    participantName,
    time,
    content
}) => {
    const [ item, setItem ] = useState<ReactNode>(<></>);

    function getImage(url: string){
        return new Promise(function(resolve, reject){
            var img = new Image()
            img.onload = function(){
                resolve(url)
            }
            img.onerror = function(){
                reject(url)
            }
            img.src = url
        })
    }



    function checkUrl(string: string) {
        return new Promise(function(resolve, reject){
            try {
                let url = new URL(string)
               resolve(url)
            } catch(err) {
                reject(url)
            }
        });
    }

    async function getValidate( url: string ){

        let isUrlValid = false;
        switch( type ){
            case EMessage.IMAGE: 
                isUrlValid = await getImage(url).then(function(successUrl){
                    return true;
                }).catch(function(errorUrl){
                    return false;
                });
                break;
            case EMessage.AUDIO:
                isUrlValid = await checkUrl(url).then(function(successUrl){
                    return true;
                }).catch(function(errorUrl){
                    return false;
                });
             
                break;
            case EMessage.VIDEO:
                isUrlValid = await checkUrl(url).then( function(successUrl){
                    return true
                }).catch( function(errorUrl){
                    return false;
                });
                break;
            case EMessage.DOCUMENT:
                isUrlValid = await checkUrl(url).then( function(successUrl){
                    return true
                 }).catch( function(errorUrl){
                     return false;
                 });
                break;
        }

        return isUrlValid;
    }


    const validateItem = async () => {
        if (url) {
  
            const isUrlValid = await getValidate(url);
            
            if (isUrlValid) {
                setItem(content);
            } else {
                setItem(
                    <>
                        <C.MessageContainer> 
                            {participantName ? <div><strong>{participantName}</strong></div> : <></>}
                            Tempo de visibilidade expirou
                        </C.MessageContainer>
                        <C.TimeMessage>{time}</C.TimeMessage>
                    </>
                )
            }
        } else {
            setItem(
                <>
                    <C.MessageContainer> 
                        {participantName ? <div><strong>{participantName}</strong></div> : <></>}
                        Tempo de visibilidade expirou
                    </C.MessageContainer>
                    <C.TimeMessage>{time}</C.TimeMessage>
                </>
            )
        }
    };

    useEffect(() => {
        validateItem();
    }, []);

    return (
        <>
            {item}
        </>
    )
}

export default ChatBodyFileItem;