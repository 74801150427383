import styled from "styled-components";

export const ComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  height: 90%;
  width: 95%;
  padding: 1rem;
  
  display: flex;
  flex-direction: column;
  gap: 2rem;

  background-color: whitesmoke;
  border-radius: 16px;
  box-sizing: border-box;

  overflow-y: scroll;
`;

export const Title = styled.span`
  font-weight: 500; 
  font-size: xx-large;
  margin-bottom: 1rem;
`;

export const FilterOptionsRow = styled.div`
  display: flex;
  /* width: inherit; */
  gap: 1rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const FilterLabel = styled.span`
  font-weight: 400;
  font-size: small;
`;

export const FilterInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.313rem; // 5px
`;

export const ExportButton = styled.div`
  display: flex;
  background-color: red;
  width: 4rem;
  height: 2rem;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ExportButtonText = styled.span`
  color: white;
  font-size: small;
`;

export const Input = styled.input`
  max-width: 140px;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: #EAF1E8;
  &:focus {
        outline: 1px;
        box-shadow: 0px 0px 2px #0B131B;
    }
`;

export const InputsWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem; // 16px
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
`;

export const InfoColumnTitle = styled.span`
  font-size: medium;
  overflow: hidden;
  overflow-wrap: break-word;
  justify-content: flex-start;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #EAF1E8;
  height: 100%;
  width: 9rem;
  align-items: center;
`;



export const ValueWrapper = styled.div`
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  text-overflow: clip;
`;

export const InfoColumnValue = styled.span``;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ContentRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;


export const TableGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(10px, 1fr));
  grid-auto-rows: 40px;
  overflow-x: hidden;
  max-height: 300px;
  border: 0.3mm solid rgba(17, 27, 33, .1);
`;
export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: scroll;
`;

export const GridItem = styled.div`
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  height: 100%;
  width: auto;
  justify-content: center;
  border-bottom: 0.3mm solid rgba(17, 27, 33, .1);
`
export const GridItemHeader = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background-color: rgba(17, 27, 33, 0.9);
`

export const DatePickWrapper = styled.div`
  width: 70px;
`;

export const StatsWrapper = styled.div`
  width: 100%;
  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 0.625rem; // 10px
`;

export const ChartsWrapper = styled.div`
  width: 100%;
  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 0.625rem; // 10px
`;

/**
 * @description caso precise mudar a altura do gráfico, altere a altura desde componenet e ele irá se adequar
 */
export const ChartWrapper = styled.div`
  width: 100%;
`;