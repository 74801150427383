import { call, take, takeEvery, CallEffect, actionChannel } from 'redux-saga/effects'
import { FileUpload, IMessage } from '../interface/IModels';
import { ChatItemModel } from '../models/redux-models';
import chatService from '../service/chatService';

import * as actionTypes from './actionTypes/messageType';

// Midleware to queue messages send
function* sendMessage() {

    const requestChannel: string = yield actionChannel(actionTypes.SET_MESSAGE);

    while (true) {
        const action: ({ payload: { message: IMessage, phone: string, fileUpload?: FileUpload } }) = yield take(requestChannel);
        let idMessage: number = yield call(() => chatService.createMessageAgent(action.payload.message, action.payload.phone, action.payload.fileUpload));

    }
};

export default sendMessage;