import React, { useState, useEffect, ReactNode } from 'react';
import {
    SidebarItem,
    SidebarHistory,
    SidebarNames,
    SidebarItemImage,
    SidebarItemName,
    SidebarItemTime,
    SidebarItemUnread,
    SidebarItemNew,
    SidebarItemInfoRight,
    ImageWrapper,
    SidebarAttendantimage,
    SidebarItemTagsContainer,
    SidebarEmployeeImage,
    SidebarWrapperEmployeeImage
} from './styles';
import './index.css';

import { ChatItemModel } from '../../models/redux-models';
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { fetchSelectedChat } from '../../store/chat-actions';

import chatService from '../../service/chatService';
import departmentService from '../../service/departmentService';
import SidebarDepartment from '../SidebarDepartament';
import ImageCompany from '../ImageCompany';
import { useMainContext } from '../../Contexts/Contexts';

// type GetChatDetailsResponse = {
//     archived:boolean,
//     pinnd: boolean,
//     messagesUnread: number,
//     phone: string,
//     unread: number,
//     name:string,
//     lastMessageTime: string,
//     muteEndTime: string,
//     isMuted: string,
//     isMarkedSpam:string,
//     time: string,
//   }

const SidebarChatItem: React.FC<{ chat: ChatItemModel, isHistory: boolean }> = (props) => {


    const [updatedDepartment, setUpdatedDepartment] = useState<string>('');

    var inputSelect = false;
    const Contexts = useMainContext();
    const dispatch = useAppDispatch();

    const chat = useAppSelector(state => state.chat.chat);

    const [departmentName, setDepartmentName] = useState<string>("");

    const activeChat = (props.chat.id === chat.id && chat.id);

    function handleChat(chat_id: number) {

        if (inputSelect) return;
        inputSelect = true;
        dispatch(fetchSelectedChat(chat_id, props.isHistory));
    }


    useEffect(() => {
        if (props.chat.departmentID === 0) return;

        const departmentId = !!Contexts.updatedDepartment ? Contexts.updatedDepartment.departmentId : props.chat.departmentID;

        if (!!Contexts.updatedDepartment && props.chat.id != Contexts.updatedDepartment.chatId) {
            return
        }

        if(!props.chat.departmentName) {
            departmentService.getDepartmentByID(departmentId).then(response => {
                if (typeof response === "boolean") return;
                setDepartmentName(response.initials !== "" ? response.initials : response.name);
            });
        } else {
            setDepartmentName(props.chat.departmentName);
        }

        setUpdatedDepartment(departmentName)
    }, [props.chat.departmentID, Contexts.updatedDepartment]);

    function getTime(timestamp: string) {

        let timeNow = "";

        let dateTime = new Date(timestamp);
        let dateTimeNow = new Date();

        let diffDate = (dateTimeNow.getTime() - dateTime.getTime()) / 60000;

        if ((dateTimeNow.getDate() !== dateTime.getDate()) && diffDate < 1440) {
            timeNow = 'Ontem';

        } else if ((dateTimeNow.getDate() !== dateTime.getDate() && diffDate > 1440)) {

            timeNow = (!isNaN(dateTime.getTime())) ? (("0" + dateTime.getDate()).slice(-2) + '/' + ("0" + (dateTime.getMonth() + 1)).slice(-2).toString() + '/' + dateTime.getFullYear()) : '';

        } else if (isSameDay(dateTime, dateTimeNow)) {
            timeNow = (!isNaN(dateTime.getTime())) ? (padTo2Digits(dateTime.getHours()) + ':' + padTo2Digits(dateTime.getMinutes())) : '';
        }

        return timeNow;
    }

    function padTo2Digits(num: Number) {
        return num.toString().padStart(2, '0');
    }

    const isSameDay = (a: Date, b: Date) => {
        return a.getFullYear() === b.getFullYear() &&
            a.getMonth() === b.getMonth() &&
            a.getDate() === b.getDate()
    }

    const messagesUnread = () => {
        return props.chat.messages - props.chat.messages_read;
    }

    const handleprofilePicError = (target: EventTarget & HTMLImageElement) => {
        target.onerror = null;
        target.src = process.env.PUBLIC_URL + '/user.png';

        if(!props.chat.history) {
            chatService.getChatMetadata(props.chat.companyID, props.chat.phone)
                .then(res => {
                    if (res != null && res != undefined && !!res.profileThumbnail) {
                        target.src = res.profileThumbnail
                    }
                });
        }
    }

    messagesUnread();

    if (props.chat.history) {
        return (
            <SidebarItem className={activeChat ? 'chatActive' : ''} onClick={!activeChat ? () => handleChat(props.chat.id) : undefined}  >

                <ImageWrapper>
                    <SidebarItemImage src={props.chat.picture}
                        onError={({ currentTarget }) => handleprofilePicError(currentTarget)}
                    />
                    {props.chat.agent_id && props.chat.agent_id !== 0 ?
                        <SidebarAttendantimage
                            src={props.chat.agent?.picture ? props.chat.agent?.picture : process.env.PUBLIC_URL + '/user.png'}
                        />
                        : ''}
                </ImageWrapper>

                <SidebarNames>
                    <SidebarItemName>{props.chat.name}</SidebarItemName>
                    <SidebarItemTagsContainer>
                        <ImageCompany companyID={props.chat.companyID} />
                        {props.chat.departmentID ? <SidebarDepartment chatId={props.chat.id} companyId={props.chat.companyID} departamentName={departmentName} /> : ''}
                    </SidebarItemTagsContainer>

                </SidebarNames>
                <SidebarItemInfoRight>
                    <SidebarItemTime>{getTime(props.chat.updatedAt)}</SidebarItemTime>
                    {!props.chat.history.history && !props.isHistory && (messagesUnread() !== 0) ? <SidebarItemUnread>{messagesUnread()}</SidebarItemUnread> : ''}
                    {!props.chat.history.history && !props.isHistory && !activeChat && (props.chat.messages === 0) ? <SidebarItemNew>new</SidebarItemNew> : ''}
                    {props.isHistory ? <SidebarHistory>histórico</SidebarHistory> : ""}
                </SidebarItemInfoRight>
            </SidebarItem>
        )
    }
    else {
        return (
            <SidebarItem className={activeChat ? 'chatActive' : ''} onClick={!activeChat ? () => handleChat(props.chat.id) : undefined}  >

                <ImageWrapper>
                    <SidebarItemImage src={props.chat.picture || process.env.PUBLIC_URL + '/user.png'}
                        onError={({ currentTarget }) => handleprofilePicError(currentTarget)}
                    />
                    {props.chat.agent_id && props.chat.agent_id !== 0 ?
                        <SidebarAttendantimage
                            src={props.chat.agent?.picture ? props.chat.agent?.picture : process.env.PUBLIC_URL + '/user.png'}
                        />
                        : ''}
                </ImageWrapper>

                <SidebarNames>
                    <SidebarItemName>{props.chat.name}</SidebarItemName>
                    <SidebarItemTagsContainer>
                        <ImageCompany companyID={props.chat.companyID} />
                        {props.chat.departmentID ? <SidebarDepartment chatId={props.chat.id} companyId={props.chat.companyID} departamentName={departmentName} /> : ''}
                    </SidebarItemTagsContainer>
                </SidebarNames>
                <SidebarItemInfoRight>
                    <SidebarItemTime>{getTime(props.chat.updatedAt)}</SidebarItemTime>
                    {!props.chat.history && (messagesUnread() !== 0) ? <SidebarItemUnread>{messagesUnread()}</SidebarItemUnread> : ''}
                    {!props.chat.history && !activeChat && (props.chat.messages === 0) ? <SidebarItemNew>new</SidebarItemNew> : ''}
                    {props.chat.history ? <SidebarHistory>histórico</SidebarHistory> : ""}
                </SidebarItemInfoRight>
            </SidebarItem>
        )
    }
}
export default SidebarChatItem;
