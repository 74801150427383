import { useEffect, useRef, useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { IoMdSend } from 'react-icons/io';
import * as S from './styles';
import { useOnClickOutside } from 'usehooks-ts'

export interface IOption{
    id: number;
    title: string;
    body: string;
}

interface IProps{
    messageEmpty?: string;
    listData: IOption[];
    visibility: boolean;
    selectEditOption: ( id: number ) => void
    selectSendOption: ( id:number ) => void
    clickOutside?: (clicked: boolean ) => void
}


const PredefinedMessages = ({
    messageEmpty= "Sem mensagens prontas cadastradas.",
    listData = [],
    visibility,
    selectEditOption,
    selectSendOption,
    clickOutside,
 }:IProps) => {
    
    const [ listFiltered, setListFiltered ] = useState<IOption[]>(listData);
    const [ visibilityProp,setVisibityProp] = useState<boolean>(visibility);
    
    const ref = useRef(null)

    useEffect( () => {
        setVisibityProp(visibility);
    },[visibility])

    useEffect( () => {
        setListFiltered(listData);
    },[listData])

    useOnClickOutside(ref, () => { clickOutside && clickOutside(true) });
   
    return(
            <S.PredefinedMessagesContainer isActive={visibilityProp} ref={ref} >
                    <S.PredefinedMessagesWrapper>
                        {listFiltered && listFiltered.length > 0 ? 
                        listFiltered.map( item => 
                        <S.PredefinedMessageItemWrapper key={item.id} onClick={() => console.log("teste")} >
                            <S.PredefinedMessageTitle onClick={() => selectEditOption(item.id) }>{item.title}</S.PredefinedMessageTitle>
                            <S.PredefinedMessageContent onClick={() => selectEditOption(item.id) }>{item.body}</S.PredefinedMessageContent>
                            <S.PredefinedMessageIconsWrapper>
                                <BsPencilSquare onClick={() => selectEditOption(item.id) }/>
                                <IoMdSend onClick={() => { selectSendOption(item.id)}}/>
                            </S.PredefinedMessageIconsWrapper>
                        </S.PredefinedMessageItemWrapper>
                        )
                        : <S.PredefinedMessageEmpty>{messageEmpty}</S.PredefinedMessageEmpty> }
                    </S.PredefinedMessagesWrapper>
                </S.PredefinedMessagesContainer >
    )
}

export { PredefinedMessages }