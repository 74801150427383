import axios from 'axios'
import {ICompany,ICreateDepartment,IDepartment} from '../interface/IModels';

const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'department/' || "";
  
export interface IDepartmentEdit{
  id: number,
  idCompany: number,
  name: string
  initials:string,
}

export default {
    async getDepartmentByID ( id:number  ){

      const tokenJWT = localStorage.getItem("auth");

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenJWT}`,
      } 
      
      let url = baseUrl + "getDepartment.php"; 

      return await axios.post<IDepartmentEdit>( url,
      { id },
      {
        headers
      }).then( request  => 
      { 
        return request.data;
      }).catch( err => {
        return false;
      });
    
    },
    async getRelationUserDepartments(){
      const tokenJWT = localStorage.getItem("auth");
      const userID   = localStorage.getItem("id");

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenJWT}`,
      } 
      
      let url = baseUrl + "getRelationUserDepartments.php"; 

      return await axios.post<IDepartment[]>( url,
      { id_user: userID },
      {
        headers
      }).then( request  => 
      { 
        return request.data;
      }).catch( err => {
        return false;
      });
    },
    async createDepartment(department: ICreateDepartment) {
      const tokenJWT = localStorage.getItem("auth");
      const userID = localStorage.getItem("id");

      const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
      };
      const url = baseUrl + "create.php";
  
      try {
          const response = await axios.post<{ id: number }>(
              url,
              { name: department.name, id_company: department.id_company, initials: department.initials },
              { headers }
          );
  
          if (response.data.id) {
              return response.data.id; 
          } else {
              return false; 
          }
      } catch (error) {
          return false;
      }
  },
  async updateDepartament(department: IDepartment) {
    const tokenJWT = localStorage.getItem("auth");
    const userID = localStorage.getItem("id");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenJWT}`,
    };
    const url = baseUrl + "updateDepartment.php";

    try {
        const response = await axios.post<{ id: number }>(
            url,
            { id: department.id, name: department.name, id_company: department.id_company, initials: department.initials },
            { headers }
        );

        if (response.data) {
            return response.data; 
        } else {
            return false; 
        }
    } catch (error) {
        return false;
    }
},
    async getDepartmentByCompanyID ( companyId:number  ){
      if(!companyId) return false;

      const tokenJWT = localStorage.getItem("auth");

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenJWT}`,
      } 
      
      let url = baseUrl + "getDepartmentsByCompany.php"; 

      return await axios.post(url,
        {
          "id_company":companyId
      },
      {
        headers
      }).then( request  => 
      { 
        return request.data;
      }).catch( err => {
        return false;
      });
    },
    async listAllDepartaments (){

      const tokenJWT = localStorage.getItem("auth");

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenJWT}`,
      } 
      
      let url = baseUrl + "listDepartmentsByUser.php"; 

      return await axios.post(url,
        {},
      {
        headers
      }).then( request  => 
      { 
        return request.data;
      }).catch( err => {
        return false;
      });
    },
    async deleteDepartament(departamentId: number) {
      const tokenJWT = localStorage.getItem("auth");
  
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
      };
      
      const url = baseUrl + "delete.php";
  
      try {
          const response = await axios.post(url, { id: departamentId }, { headers });
  
          if (response.data) {
              return response.data;
          } else {
            return false;
          }
      } catch (error) {
          return false; 
      }
  }
}  

