import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { IDepartment } from "../../interface/IModels";
import chatService from "../../service/chatService";
import departmentService from "../../service/departmentService";
import { changeDepartmentChat } from "../../store/chat-actions";
import { ModalOption, ModalOptionsWrapper, ModalTitle, ModalTitleWrapper, ModalWrapper, TagWrapper, Wrapper } from "./style";

interface ISidebarDepartmentProps {
  departamentName: string,
  companyId: number,
  chatId: number,
}

export const SidebarDepartment: React.FC<ISidebarDepartmentProps> = ({ departamentName, companyId, chatId }) => {

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [departaments, setDepartamets] = useState<IDepartment[]>([]);

  const dispatch = useAppDispatch();

  const handleOptionClick = (depId: number) => {
    chatService.updateChatDepartament(chatId, depId)
      .then(() => {
        // remove chat from list
        dispatch(changeDepartmentChat(chatId, depId))
        // dispatch(fetchChats());
      })
    setModalOpen(false);
  }

  useEffect(() => {
    if (modalOpen) {
      departmentService.getDepartmentByCompanyID(companyId).then(result => {
        setDepartamets(result)
      })
    }
  }, [modalOpen, companyId]);


  return (
    <Wrapper>
      <TagWrapper role="button" onContextMenu={(e) => {
        e.preventDefault()
        setModalOpen(true);
      }}>
        {departamentName}
      </TagWrapper>
      {modalOpen &&
        <ModalWrapper onMouseLeave={() => { setModalOpen(false) }}>
          <ModalTitleWrapper>
            <ModalTitle>Departamentos</ModalTitle>
          </ModalTitleWrapper>
          <ModalOptionsWrapper>
            {departaments.map((dep: IDepartment) => (
              <ModalOption
                key={dep.id}
                onClick={() => { handleOptionClick(dep.id) }}
              >
                {dep.name}
              </ModalOption>
            ))}
          </ModalOptionsWrapper>
        </ModalWrapper>
      }
    </Wrapper>
  );
}

export default SidebarDepartment;
