import styled from 'styled-components'

interface IPropsChatBodyItem {
    isAgent:boolean,
    isText: boolean,
}

interface IPropsChatBodyItemContainer{
    isAgent: boolean,
}

export const ChatBodyItemContainer = styled.div<IPropsChatBodyItemContainer>`
    display: flex;
    margin-left:9%;
    margin-right:9%;
    margin-bottom:20px;
    justify-content:${
        props => props.isAgent ? 'flex-end' : 'flex-start'
    }
    
`;

export const ChatBodyItemMessage = styled.div<IPropsChatBodyItem>`

    border-radius:10px;
    box-shadow:${
        props => ( props.isText ) ? '0 1px 0.5px rgb(0 0 0 / 13%);' : '' 
    }
    display:flex;
    color:var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    background-color:${
        props => ( props.isText ) ?  ( (props.isAgent ) ? 'var(--chat-send);' : 'var(--chat-incoming);' ) : ''
    };
    
`;
export const TimeMessage = styled.span`
    color: #999;
    font-size: 9px;
    position: relative;
    padding-top: 12px;
    padding-left: 15px;
    padding-right:10px;
  
`;

export const TimeMessageDocument = styled.span`
    color: #999;
    font-size: 9px;
    position: relative;
    padding-top: 12px;
    padding-right:10px;
`;
export const TimeMessageImage = styled.span`
    margin-right:10px;
`;

export const MessageContainer = styled.div`
    display:flex;
    flex-direction:column;
    padding: 6px 7px 8px 9px;
`;

export const MessageImageContainer = styled.div`
    border-radius:3px;
    position:relative;
    margin: 5px 5px 0px 5px;
`;


export const MessageTimeImage = styled.div`
    position: absolute;
    width: 100%;
    color: #fff;
    font-size:12px;
    text-align: right;
    bottom: 4px;
    padding-bottom:5px;
    padding-top:5px;
    background-image: linear-gradient(to top,rgba(var(--overlay-rgb),.5),rgba(var(--overlay-rgb),0));
`;

export const MessageImage = styled.img`
    border-radius:3px;
    max-width:300px;
    max-height:300px;
`;

export const MessageLinkDocument = styled.a`
    background-color: var(--chat-incoming);
    padding: 5px 10px;
    border-radius: 4px;
    color: var(--text-primary);
    text-decoration: none;

`
