import styled from "styled-components";

export const ButtonWErapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const IconButton = styled.div`
  background-color: #68C16C;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  border-radius: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  text-aling:center;
  margin-right:8px; 
`;

export const WrapperText = styled.div`
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color:var(--background-secondary);
  border-radius:5px;
  padding:5px 10px;
  margin-bottom:10px;

`;

