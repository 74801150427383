import styled from "styled-components";

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  width:100%;
`;

export const CardWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width:80%;
    min-height:10rem;
    border-radius:4px;
    background-color: var(--webhook-background);
`;

export const CardItem = styled.div`
    width:80%;
    padding: 1rem;
    border-radius:4px;
    background-color:#f1f1f1;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

`;

export const CardName = styled.div`
`;

export const CardIcons = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 40px;
`;

export const ModalButtonRow = styled.div`
  display:flex;
`;

export const ModalButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: #c8c8c1;
  border-radius: 6px;
  cursor:pointer;
  margin-right:15px;
`;

export const ModalTitle = styled.h3`
  margin:0px;
`
