
//http://192.168.50.178:8100/api/configuration/get.php

import axios from "axios";

const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'configuration/' || "";


interface IConfig{
    id: number;
    config_key: string;
    id_company: number;
    config_value: string;
    created_at: Date;
}
 async function findConfigByKeyAndCompany(id_company: number, config_key: string){

    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    } 
    
    let url = baseUrl + "get.php"; 

    const config = await axios.post<IConfig|false>( url,
    {  id_company, config_key  },
    { headers}
    ).then( request  => {
        return request.data;
    }).catch( err => {
      return false;
    });

    return config;

 };

 async function findConfigByKeyAndCompaniesList(id_companies_list: number[], config_key: string){

  const tokenJWT = localStorage.getItem("auth");

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${tokenJWT}`,
  } 
  
  let url = baseUrl + "getByCompanies.php"; 

  const config = await axios.post<IConfig[]|false>( url,
  {  id_companies_list, config_key  },
  { headers}
  ).then( request  => {
      return request.data;
  }).catch( err => {
    return false;
  });

  return config;

};

async function saveListAttendanceOptionConfig (idCompany: number, listAttendanceOption: string, agentsSelected?: string[]){
  const tokenJWT = localStorage.getItem("auth");

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${tokenJWT}`,
  } 
  
  let url = baseUrl + "saveListAttendanceConfig.php"; 

  return await axios.post<boolean>( url,
  { idCompany, listAttendanceOption, agentsSelected },
  {
    headers
  }).then( request  => 
  { 
    return request.data;
  }).catch( err => {
    return false;
  });
};

async function getListAttendanceOptionConfigByCompanyId (idCompany: number){
  const tokenJWT = localStorage.getItem("auth");

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${tokenJWT}`,
  } 
  
  let url = baseUrl + `getListAttendanceConfig.php?idCompany=${idCompany}`; 

  return await axios.get<any>( url,
  { headers }).then( request  => 
  { 
    return request.data;
  }).catch( err => {
    return false;
  });
};

export default {
    findConfigByKeyAndCompany,
    findConfigByKeyAndCompaniesList,
    saveListAttendanceOptionConfig,
    getListAttendanceOptionConfigByCompanyId
}