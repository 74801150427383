import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDropdown, IoIosArrowDropdownCircle } from 'react-icons/io';
import { v4 } from 'uuid';
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { ChatItemModel } from "../../models/redux-models";
import { fetchMessagesHistory } from "../../store/chat-actions";
import ChatBodyItem from "../ChatBodyItem";
import * as C from './styles';

import './index.css';
import configService from "../../service/configService";

const ChatBody: React.FC<{ chat: ChatItemModel }> = (props) => {

    const scrollRef = useRef<HTMLDivElement>(null);
    const scrollMidle = useRef<HTMLDivElement>(null);

    const [loadHistory, setLoadHistory] = useState<boolean>(false);
    const [chatID, setChatID] = useState<number>();
    const [historyOpenedChats, setHistoryOpenedChats] = useState<number[]>([]);
    const [showDifferenceAttendanceTime, setShowDifferenceAttendanceTime] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const historysState = useAppSelector(state => state.chat.chat.history);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatID]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [props.chat.messages_chat]);

    useEffect(() => {
        setChatID(props.chat.id)
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [props.chat.id]);

    useEffect(() => {
        scrollMidle.current?.scrollIntoView({ behavior: 'auto' });
    }, [loadHistory]);

    useEffect(() => {

        configService.findConfigByKeyAndCompany(props.chat.companyID, "showDifferenceAttendanceTime").then(configResponse => {

            if (typeof configResponse === "boolean") return;

            const configurationValue = configResponse.config_value;

            try {

                const object = JSON.parse(configurationValue);

                if (object.showDifferenceAttendanceTime != undefined && typeof object.showDifferenceAttendanceTime == 'boolean') {

                    setShowDifferenceAttendanceTime(object.showDifferenceAttendanceTime);
                }

            } catch (error) {
                console.log(error);
            }
        }).catch(error => { console.log(error) });

    }, []);

    useEffect(() => {
        if (chatID === props.chat.id) {
            scrollMidle.current?.scrollIntoView({ behavior: 'smooth' });

        } else {
            setChatID(props.chat.id);
            setLoadHistory(false);
            scrollMidle.current?.scrollIntoView({ behavior: 'smooth' });
        }

    }, [historysState, chatID, props.chat.id]);

    const messageRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());
    const scrollToMessage = (messageId: string) => {
        const messageElement = messageRefs.current.get(messageId);
        if (messageElement) {
            messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

            messageElement.style.backgroundColor = 'grey';
            messageElement.style.borderRadius = "10px";
            messageElement.style.transition = 'background-color 0.3s ease';

            setTimeout(() => {
                if (messageElement) {
                    messageElement.style.backgroundColor = 'transparent';
                }
            }, 500); // 500ms de destaque
        }
    };

    function padTo2Digits(num: Number) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(dateString: string) {
        let date = new Date(dateString);
        return [
            (padTo2Digits(date.getHours()) + ':' + padTo2Digits(date.getMinutes())) + " - " +
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    const handleHistory = (id: number) => {
        dispatch(fetchMessagesHistory(id))
    };

    const toggleOpenHistory = (id: number) => {

        if (historyOpenedChats.includes(id)) {
            const filteredList = historyOpenedChats.filter((history) => history != id);
            setHistoryOpenedChats(
                filteredList
            );

        } else {
            historyOpenedChats.push(id);
            setHistoryOpenedChats(
                historyOpenedChats
            );

            handleHistory(id);
        }

    }

    const calculateTotalMinutes = (start: string, end: string) => {
        const dateStartString = formatDate(start);
        const dateEndString = formatDate(end);

        const startTime = new Date(
            parseInt(dateStartString.slice(15, 19)),
            parseInt(dateStartString.slice(12, 14)) - 1, 
            parseInt(dateStartString.slice(9, 11)),
            parseInt(dateStartString.slice(0, 2)), 
            parseInt(dateStartString.slice(3, 5))
        );
        const endTime = new Date(
            parseInt(dateEndString.slice(15, 19)),
            parseInt(dateEndString.slice(12, 14)) - 1, 
            parseInt(dateEndString.slice(9, 11)),
            parseInt(dateEndString.slice(0, 2)), 
            parseInt(dateEndString.slice(3, 5))
          );

        const diffInMs = endTime.getTime() - startTime.getTime();

        return Math.round(diffInMs / 60000);
    };

    function renderItems() {
        const isHaveItemsToRender = props.chat.messages_chat && props.chat.messages_chat.length > 0 && props.chat.messages_chat[0].id !== 0;

        let itemsIDs: number[] = [];

        if (props.chat.isMessagesLoading) {
            return (
                <>
                    <C.LoadMessageContainer>
                        <C.LoadMessageItem className="include_pulse_animation">Carregando novas mensagens...</C.LoadMessageItem>
                    </C.LoadMessageContainer>

                </>
            );
        };

        if (isHaveItemsToRender) {
            return props.chat.messages_chat.map(element => {
                if (itemsIDs.find((id) => id === element.id)) {
                    return <></>;
                } else {
                    itemsIDs.push(element.id);
                }

                return (
                    <ChatBodyItem
                        key={element.whatsapp_message_id} chat={props.chat} message={element} history={historysState.historys}
                        ref={(el) => messageRefs.current.set(element.whatsapp_message_id ?? "", el)}
                        onClickReplyMessage={() => scrollToMessage(element.whatsapp_reference_message_id ?? "")}
                    />
                );
            });
        };
    };

    function renderHistories() {
        if (!loadHistory) {
            return (
                <C.ChatContainerLoadHistory>
                    <C.ChatLoadHistory onClick={() => setLoadHistory(true)}>Carregar históricos</C.ChatLoadHistory>
                </C.ChatContainerLoadHistory>
            );
        };

        if (historysState && historysState.history && historysState.historys && historysState.historys.length > 0) {
            return historysState.historys.map((el, index) => {
                return (
                    <div key={v4()}>
                        <C.ChatHistorySelect key={el.id}>
                            <C.ChatHistorySeparatorData>
                                {formatDate(el.created_at)}

                                <C.ChatHistoryButtonLoad onClick={() => { toggleOpenHistory(el.id) }}>

                                    {historyOpenedChats.includes(el.id) ? <IoIosArrowDropdown /> : <IoIosArrowDropdownCircle />}

                                </C.ChatHistoryButtonLoad>

                                {formatDate(el.updated_at)}
                            </C.ChatHistorySeparatorData>

                            <C.ChatHistorySeparatorWrapper>
                                <C.ChatHistorySeparator />
                                {showDifferenceAttendanceTime ?
                                    <C.ChatHistoryTime>
                                        | {calculateTotalMinutes(el.created_at, el.updated_at)} min
                                    </C.ChatHistoryTime> :
                                    <></>
                                }

                            </C.ChatHistorySeparatorWrapper>

                        </C.ChatHistorySelect>

                        {historyOpenedChats.includes(el.id)
                            ?
                            <>
                                {el.messages.map((message) => {
                                    return (<ChatBodyItem
                                        key={message.id} chat={props.chat} message={message} history={historysState.historys}
                                        ref={(el) => messageRefs.current.set(message.whatsapp_message_id ?? "", el)}
                                        onClickReplyMessage={() => scrollToMessage(message.whatsapp_reference_message_id ?? "")}
                                    />)
                                })}

                                {(index + 2 === historysState.historys.length ? <div ref={scrollMidle} /> : "")}
                                {(index + 1 === historysState.historys.length) ? <C.ChatHistorySeparatorEnd /> : ""}
                            </>

                            : <></>
                        }


                    </div>
                );
            })
        };

        return <></>;
    };

    return (
        <C.ChatBodyContainer>
            {renderHistories()}


            {renderItems()}
            <div ref={scrollRef} />

        </C.ChatBodyContainer>
    );

}

export default ChatBody;