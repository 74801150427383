import { makeStyles, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableProps, TableRow, withStyles } from "@mui/material"
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useState } from "react";
import * as S from "./styles";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import React from "react";

function objectValues<T extends {}>(obj: T) {
    return Object.keys(obj).map((objKey) => obj[objKey as keyof T]);
}


function objectKeys<T extends {}>(obj: T) {
    return Object.keys(obj).map((objKey) => objKey as keyof T);
}
  
type PrimitiveType = string | number | boolean   ;

function isValidProps(value: any): value is PrimitiveType {

return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    React.isValidElement(value)
    )
}
    
export interface MinTableItem {
    id: PrimitiveType;
}

export interface RowType<T extends MinTableItem> {
    value: T;
    key: string
}

type TableHeaders<T extends MinTableItem> = Record<keyof T, string>;

type CustomRenderers<T extends MinTableItem> = Partial<Record<keyof T, (it: T) => React.ReactNode>>;

interface TablePropsGeneric<T extends MinTableItem> {
    items: RowType<T>[];
    headers: TableHeaders<T>;
    searchKey?: string;
    searchValue?: string;
    compress?: boolean;
    customRenderers?: CustomRenderers<T>;
    styleTable?: Object;
    onClickEditButton?: (id:any) => void;
    onClickDeleteButton?: (id:any) => void;
}


export default function TablePM<T extends MinTableItem>(props: TablePropsGeneric<T>) {

    const TableCustom = styled(Table)<TableProps>(({ theme }) => ({
        '&.MuiTable-root': {
            boxShadow:"2px 3px 9px 0px rgb(0 0 0 / 9%)"
        },
    }));
  
    const handleEdit = (param: any) => {
        if (props.onClickEditButton) props.onClickEditButton(param);
    }
    const handleDelete = (param: any) => {
        if( props.onClickDeleteButton ) props.onClickDeleteButton(param);
    }

    function renderRow(items: RowType<T>) {

        if ( props.searchKey && props.searchValue) {
            const filter = objectKeys(items.value).find(itemProperty => {
                if ( typeof itemProperty != 'string' ) return '';
                return itemProperty === props.searchKey; 
            });
            
            if (filter && items.value?.[filter] ){
                if (filter && !(String(items.value?.[filter]).toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1) ) return '';
            }
        }
        return (
          <TableRow key={`tbody-tr-${items.key}`}>
            {objectValues(items.value).map((itemProperty, index) => {
                return <TableCell key={index} align="center"><div style={props.compress?{maxHeight:'20px', overflow:'hidden'}:{}}>{ isValidProps(itemProperty) ? itemProperty : ""  }</div></TableCell>
            })}
            <TableCell align="right">
                <S.WrapperButtons>
                   <S.ItemButton onClick={ () => handleEdit(items.key) }><AiTwotoneEdit /></S.ItemButton>
                   <S.ItemButton onClick={ ()=> handleDelete(items.key) }><AiTwotoneDelete /></S.ItemButton> 
                </S.WrapperButtons>
            </TableCell>
          </TableRow>
        );
    }
    
    return(
        <TableContainer component={Paper} sx={{boxShadow:"none", maxHeight: '100%'}}>
            
            <S.WrapperTable>
                <TableCustom sx={ (props.styleTable) ? props.styleTable : {} } aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {objectValues(props.headers).map((headerValue, index) => (
                                <TableCell sx={{ whiteSpace: 'nowrap'}} key={index} align="center">{isValidProps(headerValue) ? headerValue : "" }</TableCell>
                            ))}
                            <TableCell align="right">Opções</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        { 
                        props.items.length ? props.items.map(renderRow) :
                        <TableRow>
                            <TableCell colSpan={objectValues(props.headers).length + 1}><S.IsEmpty>Sem registros...</S.IsEmpty></TableCell>
                        </TableRow>
                        }                          
                    </TableBody>
                    {/* <TableFooter>
                        <TableRow>
                            <TablePagination 
                                colSpan={props.items.length + 1}
                                count={renderRow.length}
                                labelRowsPerPage={'Items por página'}
                                rowsPerPage={3}
                                page={1}
                                onPageChange={()=> {}}
                            />
                        </TableRow>
                    </TableFooter> */}
                </TableCustom>
               
                

            </S.WrapperTable>
        
        </TableContainer>
    )

}
