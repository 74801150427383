import * as S from "./styles";
import {IoMdCheckmark} from "react-icons/io"

interface BigOkButtonProps {
  handleFunction: Function;
  name?: string;
  sizeIcon?: string;
  buttonLayout?: boolean;
  iconActive?:boolean;
}

const BigOkButton: React.FC<BigOkButtonProps> = ({handleFunction, name, sizeIcon, buttonLayout, iconActive}) => {
  return (
    <S.ButtonWErapper onClick={() => {handleFunction()}}>

      {buttonLayout ?
      <S.WrapperButton>
        {iconActive ? <S.IconButton><IoMdCheckmark size={sizeIcon || "1.3rem"} color="white"></IoMdCheckmark></S.IconButton> : "" }
        <S.WrapperText>{name}</S.WrapperText>
      </S.WrapperButton>
      :
        iconActive ? <S.IconButton><IoMdCheckmark size={sizeIcon || "1.3rem"} color="white"></IoMdCheckmark></S.IconButton> : ""
      }
    </S.ButtonWErapper>
  );
}

export default BigOkButton;
