import { useCallback, useEffect, useState } from "react";
import BigOkButton from "../../Buttons/BigOkButton";
import FormPasswerdInput from "../../FormPasswordInput";
import FormTextInput from "../../FormTextInput";
import {
  TitleContainer,
  FormWrapper,
  InputsContainer,
  UserDataWrapper,
  NewUserForm,
} from "./styles";
import * as S from './styles';
import departmentService from "../../../../service/departmentService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import userService from "../../../../service/userService";
import { UserRole } from "../../../../util/enums";
import { useForm } from "react-hook-form";
import FormEmailInput from "../../FormEmailInput";
import { ICompany, IDepartment } from "../../../../interface/IModels";
import SelectCustom from '../../../Select/';
import Select, { MultiValue, SingleValue, ActionMeta } from 'react-select';

interface OptionsInterface {
  label: string;
  value: string;
};

interface mainForm {
  name: string;
  email: string;
  password: string;
  picture: string;
  companysSelected: number;
  departmentsSelectd: number;
  selectedoptionRole: string;
}

interface EditUserprops { }
interface IUser {
  createdAt: Date,
  updatedAt: Date,
  id: number,
  login: string,
  name: string,
  picture: string,
  roleID: UserRole,
  validID: number,
  departments: IDepartment[],
  companys: ICompany[]
}

interface ILocation {
  user: IUser
}

const EditUser: React.FC<EditUserprops> = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<mainForm>();

  const { id } = useParams();

  const nav = useNavigate();

  const state: ILocation = useLocation().state as ILocation;

  const [allDepartments, setAllDepartments] = useState<IDepartment[]>([]);
  const [handlerErrorSelectCompany, setHandlerErrorSelectCompany] = useState<boolean>(false);
  const [handlerErrorSelectDepartment, setHandlerErrorSelectDepartment] = useState<boolean>(false);

  const user = state ? state.user : {
    departments: [],
    companys: [],
    roleID: 1,
  };

  const [userS, setUserS] = useState<IUser | {
    departments: never[];
    companys: never[];
    roleID: number;
  }>(user);

  const [companiesSelected, setCompaniesSelected] = useState<number[]>(user.companys.map(el => el.id));

  const [defaultOptionsCompany, setDefaultOptionsCompany] = useState<OptionsInterface[]>(user.companys.map(el => {
    return { label: el.name, value: el.id.toString() }
  }));

  const [departmentsSelect, setDepartmentsSelect] = useState<OptionsInterface[]>(user.departments.map(el => {
    return { label: el.name, value: el.id.toString() }
  }));

  const [defaultOptionsDepartment, setDefaultOptionsDepartment] = useState<OptionsInterface[]>(user.departments.map(el => {
    return { label: el.name, value: el.id.toString() }
  }));

  const [optionsCompany, setOptionsCompany] = useState<OptionsInterface[]>(
    user.companys.map(el => {
      return { label: el.name, value: el.id.toString() }
    })
  );

  const [optionsDepartments, setOptionsDepartments] = useState<OptionsInterface[]>(
    allDepartments.map(el => {
      return { label: el.name, value: el.id.toString() }
    })
  );

  useEffect(() => {

    let companiesInUse: OptionsInterface[] = [];
    let departmentsFiltered: OptionsInterface[] = [];

    companiesSelected.map(companyID => {
      user.companys.forEach(c => {
        if (c.id === companyID) companiesInUse.push({ label: c.name, value: c.id.toString() });
      })
    });

    companiesInUse.map(companySelect => {

      const departmentFilter = allDepartments.filter(dep => {
        return dep.id_company === parseInt(companySelect.value)
      })

      const filteredDep = departmentFilter.map(dep => { return { label: dep.name, value: dep.id.toString() } });
      departmentsFiltered = [...departmentsFiltered, ...filteredDep];
    });

    setOptionsDepartments(departmentsFiltered);

  }, [allDepartments])

  const handleSearchDepartmentsNewUser = async (selectedOptions: OptionsInterface[]) => {

    let departmentsFiltered: OptionsInterface[] = [];

    setOptionsDepartments([]);

    for (const comp of selectedOptions) {

      const result = await departmentService.getDepartmentByCompanyID(parseInt(comp.value));

      if (!result) return;

      const arrayFormatted = result.map((dep: any) => {
        return { label: dep.name, value: dep.id }
      });
      departmentsFiltered = [...arrayFormatted, ...departmentsFiltered];
    }

    const filterDep = departmentsSelect.filter(d => d.value === departmentsFiltered.find(df => df.value)?.value);
    setDepartmentsSelect(filterDep);
    setOptionsDepartments(departmentsFiltered);
  }
  const handleComp = (selectedOptions: OptionsInterface[]) => {

    let departmentsFiltered: OptionsInterface[] = [];
    if (!state) {

      setCompaniesSelected(selectedOptions.map(c => parseInt(c.value)));
      if(selectedOptions.length>0) setHandlerErrorSelectCompany(false);

      handleSearchDepartmentsNewUser(selectedOptions);
      return;
    }

    if (selectedOptions.length === 0) {

      setCompaniesSelected([]);
      setDepartmentsSelect([]);
      setOptionsDepartments([]);
      setDefaultOptionsCompany([]);

      return;
    }

    if (allDepartments.length === 0) return;

    const companiesSelect: number[] = []

    selectedOptions.map(companySelect => {

      companiesSelect.push(parseInt(companySelect.value));

      const departmentFilter = allDepartments.filter(dep => {
        return dep.id_company == parseInt(companySelect.value)
      })

      const filteredDep = departmentFilter.map(dep => { return { label: dep.name, value: dep.id.toString() } });
      departmentsFiltered = [...departmentsFiltered, ...filteredDep];
    });

    if (defaultOptionsCompany) setDefaultOptionsCompany([]);
    if (defaultOptionsDepartment) setDefaultOptionsDepartment([])

    setCompaniesSelected(companiesSelect);
    if(companiesSelect.length>0) setHandlerErrorSelectCompany(false);

    setOptionsDepartments(departmentsFiltered);

    const depFilter = departmentsSelect.filter(d => {
      const exists = departmentsFiltered.find(df => df.value === d.value);
      return exists;
    })

    setDepartmentsSelect(depFilter);

  }

  const handleOptionDepartmentSelected = (newValue: MultiValue<{ value: string; label: string; }>, actionMeta: ActionMeta<any>) => {
    switch (actionMeta.action) {
      case 'select-option':
        let newSelecteds = departmentsSelect.map(e => e) || [];
        newSelecteds.push(actionMeta.option);

        setDepartmentsSelect([...newSelecteds]);

        if(newSelecteds.length>0) setHandlerErrorSelectDepartment(false);
        break;

      case 'remove-value':
        let newSelectedsRemove = newValue.filter(e => {
          return e.value != actionMeta.removedValue.value
        });

        setDepartmentsSelect([...newSelectedsRemove]);

        if(newSelectedsRemove.length>0) setHandlerErrorSelectDepartment(false);
        break;

      case 'clear':
        setDepartmentsSelect([]);
        break;
    }

  };

  const [showPassState, setShowPassState] = useState<boolean>(false);

  const [selectedoptionRole, setSelectedOptionRole] = useState<string>(user.roleID.toString());

  const roleOptions = [
    { value: UserRole.master.toString(), label: 'Administrador' },
    { value: UserRole.agent.toString(), label: 'Agente' },
  ];

  useEffect(() => {

    if (!state) {

      userService.getRelationUserCompany().then(response => {
        if (!response) return;
        setOptionsCompany(response.map(comp => { return { label: comp.name_company, value: comp.id_company.toString() } }));
        let departmentsFiltered: OptionsInterface[] = [];

        response.forEach(comp => {
          departmentService.getDepartmentByCompanyID(parseInt(comp.id_company)).then(result => {
            if (!result) return;

            departmentsFiltered = [...result.map((dep: any) => {
              return { label: dep.name, value: dep.id }
            })];
          });
        });

        setOptionsDepartments(departmentsFiltered);

      });

      return;
    }

    handleInitPreSelectedOptions(user.companys, user.departments);

    userService.getRelationUserCompany().then(response => {
      if (!response) return;
      setOptionsCompany(response.map(comp => { return { label: comp.name_company, value: comp.id_company.toString() } }));

      departmentService.listAllDepartaments().then((res) => {
        setAllDepartments(res);
      });
    });

  }, [userS])

  const handleInitPreSelectedOptions = (companies: ICompany[], departments: IDepartment[]) => {
    const companiesOptions = user.companys.map(el => {
      return { label: el.name, value: el.id.toString() }
    })

    const departmentsOptions = user.departments.map(dep => {
      return { label: dep.name, value: dep.id.toString() }
    });

    setOptionsDepartments(departmentsOptions);
    setOptionsCompany(companiesOptions);
  }

  const handleRoleSelect = (newValue: SingleValue<{ value: string; label: string; }>, actionMeta: ActionMeta<{ value: string; label: string; }>) => {
    setSelectedOptionRole(newValue?.value || "");
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: '0px solid transparent',
      borderRadius: '25px',
      // margin: '5px 10px',
      borderTop: '1px solid var(--border-color)',
      ':hover': {
        borderTop: '1px solid var(--border-color)'
      }
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'var(--background-company)',
      color: 'var(--text-company)',
      borderRadius: 25,
      placeholder: 'var(--text-primary)',

    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'var(--background-company)',
      color: 'var(--text-company)',
      borderRadius: 25,
      padding: "4px 10px",
      width: 'auto',
      placeholder: 'var(--text-primary)',

    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      color: 'var(--text-company)',
    }),
    multiValueRemove: (provided: any, state: any) => ({
      ...provided,
      ':hover': {
        backgroundColor: 'var(--text-primary)',
        color: 'var(--bar-background)',
        borderRadius: 8,
      }
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: 'var(--text-primary)',
      ':hover': {
        color: '#fff'
      }
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: 'var(--text-primary)',
    }),
  };


  const onSubmit = (data: mainForm) => {

    const departmentsSelectd = departmentsSelect.map(d => parseInt(d.value));

    if(companiesSelected.length <= 0) {
      setHandlerErrorSelectCompany(true);
      return;
    }

    if(departmentsSelectd.length <= 0) {
      setHandlerErrorSelectDepartment(true);
      return;
    }

    if (id != "NEW") {
      try {
        userService.updateUser(
          id || '0',
          data.name,
          data.email,
          data.password,
          data.picture,
          1,
          companiesSelected,
          departmentsSelectd,
          selectedoptionRole,
        ).then(response => {
          nav('/admin/users')
        }).catch(err => console.log(err))
      } catch (error) {
        console.log(error)
      }
    } else {

      try {
        userService.createUser(
          data.name,
          data.email,
          data.password,
          data.picture,
          1,
          companiesSelected,
          departmentsSelectd,
          selectedoptionRole,
        ).then(response => {
          nav('/admin/users')
        })
          .catch(err => console.log(err))
      } catch (error) {
        console.log(error)
      }
    }

  };

  return (
    <FormWrapper autoComplete="off" onSubmit={() => { }}>
      <UserDataWrapper>
        <TitleContainer>
          {id == "NEW" ? 'Criar usuário' : 'Editar Usuário'}
        </TitleContainer>
        <NewUserForm>
          <InputsContainer>
            <FormTextInput
              label="Nome"
              placeholder="Nome do usuário"
              register={register}
              errors={errors.name}
              name='name'
              defaultValue={state ? state.user.name : ""}
            />
            <FormEmailInput
              label="Email"
              placeholder="Email do usuário"
              register={register}
              errors={errors.email}
              name='email'
              defaultValue={state ? state.user.login : ""}
            />
            <FormPasswerdInput
              label="Senha"
              placeholder="Senha"
              showpass={showPassState}
              handleChangeShow={setShowPassState}
              register={register}
              errors={errors.password}
              required={false}
              name='password'
            />
            <>
              <FormTextInput
                label="Link da foto"
                placeholder="URL"
                register={register}
                errors={errors.picture}
                name='picture'
                required={false}
                defaultValue={state ? state.user.picture : ""}
              />
            </>

          </InputsContainer>
          <BigOkButton
            iconActive={true}
            name={id != "NEW" ? "Atualizar usuário" : "Criar usuário"}
            buttonLayout={true}
            handleFunction={handleSubmit(onSubmit)} />
        </NewUserForm>
      </UserDataWrapper>
      <UserDataWrapper>

        <TitleContainer>
          Relações
        </TitleContainer>

        <S.WrapperRelations>
          <S.WrapperRelationField>
            <S.LabelSelect>Tipo de usuário</S.LabelSelect>
            <Select
              options={roleOptions}
              value={roleOptions.find(role => role.value === selectedoptionRole)}
              onChange={handleRoleSelect}
              styles={customStyles}
              placeholder="Selecione o tipo de usuário"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'var(--background-company)',
                  primary: 'var(--bar-background)',
                  neutral0: 'var(--bar-background)'
                },
              })}
            />
          </S.WrapperRelationField>

          <S.WrapperRelationField>
            <S.LabelSelect>Empresas</S.LabelSelect>
            <SelectCustom defaultValue={defaultOptionsCompany} options={optionsCompany} onSelected={(content) => handleComp(content)} />
            {
              handlerErrorSelectCompany ? 
              <S.LabelErrorSelect>Selecione ao menos uma empresa</S.LabelErrorSelect> : 
                <></>
            }
          </S.WrapperRelationField>

          <S.WrapperRelationField>
            <S.LabelSelect>Departamentos</S.LabelSelect>
            <Select
              options={optionsDepartments}
              isMulti={true}
              onChange={handleOptionDepartmentSelected}
              value={departmentsSelect}
              placeholder="Selecione os departamentos"
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'var(--background-company)',
                  primary: 'var(--bar-background)',
                  neutral0: 'var(--bar-background)'
                },
              })}
            />
            {
              handlerErrorSelectDepartment ? 
              <S.LabelErrorSelect>Selecione ao menos um departamento</S.LabelErrorSelect> : 
                <></>
            }
          </S.WrapperRelationField>

        </S.WrapperRelations>
      </UserDataWrapper>
    </FormWrapper>
  );
};

export default EditUser;
