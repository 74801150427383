import { Route, Routes } from 'react-router-dom';
import './App.css';

import Chat from './components/Chat';
import Login from './components/Login';
import * as C from './styles/styles';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Adminlayout from './components/Admin/AdminLayout';
import ListConfig from './components/Admin/Config/ListConfig';
import ListDepartaments from './components/Admin/Departament/ListDepartaments';
import ShowGraphics from './components/Admin/Graphics/ShowGraphics';
import NewCompany from './components/Admin/NewCompany';
import { PredefinedMessagesManager } from './components/Admin/PredefinedMessagesManager';
import ShowReport from './components/Admin/Report/ShowReport';
import EditUser from './components/Admin/User/EditUser';
import ListUsers from './components/Admin/User/ListUsers';
import NewUser from './components/Admin/User/NewUser';
import Webhook from './components/Admin/Webhook';
import ProtectedRoute, { AdminProtectedRoute } from './components/Auth';
import { ProfilePage } from './components/Profile';
import { IdProvider } from "./Contexts/Contexts";

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};


function App() {
  return (
    <C.ContainerApp>
      <IdProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path='/chat' element={<ProtectedRoute />}>
              <Route path='/chat' element={<Chat />} />
            </Route>

            <Route path='/profile' element={<ProtectedRoute />}>
              <Route path='/profile' element={<ProfilePage />} />
            </Route>

            <Route path='/admin' element={<AdminProtectedRoute />}>

              <Route path='/admin/config' element={
                <Adminlayout children={<ListConfig />} />
              } />

              <Route path='/admin/users' element={
                <Adminlayout children={<ListUsers />} />
              } />

              <Route path='/admin/departaments' element={
                <Adminlayout children={<ListDepartaments />} />
              } />
              <Route path='/admin/departaments/:id' element={
                <Adminlayout children={<ListDepartaments />} />
              } />
              <Route path='/admin/new-company' element={
                <Adminlayout children={<NewCompany />} />
              } />
              <Route path='/admin/new-user' element={
                <Adminlayout children={<NewUser />} />
              } />
              <Route path='/admin/edit-user/:id' element={
                <Adminlayout children={<EditUser />} />
              } />
              <Route path='/admin/reports' element={
                <Adminlayout children={<ShowReport />} />
              } />

              <Route path='/admin/graphics' element={
                <Adminlayout children={<ShowGraphics />} />
              } />
              <Route path='/admin/webhook' element={
                <Adminlayout children={<Webhook />} />
              }></Route>
              <Route path='/admin/webhook/:id' element={
                <Adminlayout children={<Webhook />} />
              }></Route>
              <Route path='/admin/predefined-messages' element={
                <Adminlayout children={<PredefinedMessagesManager />} />
              }></Route>

              {/* <Route path='/admin/profile' element={
                <Adminlayout children={<ProfilePage />} />
              }></Route> */}


            </Route>

          </Routes>
        </LocalizationProvider>
      </IdProvider>
    </C.ContainerApp>
  );

}

export default App;
