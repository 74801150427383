import React , { useState } from 'react';
import * as C from './styles';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as MUI from '@mui/material';

import { useNavigate } from "react-router-dom";

import loginService from '../../service/loginService';

const Login: React.FC = props => {

    const [ email, setLogin ] = useState<string>();
    const [ password, setPassword ] = useState<string>();
    const [ formError, setformError ] = useState<boolean>();
    const [ userInvalid, setUserInvalid ] = useState<boolean>(false);

    const navigation = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement> ) => {
      
        event.preventDefault();
        
        if(localStorage.getItem("selected-companys") == "{}") {
          localStorage.setItem("selected-companys", JSON.stringify([]));
        }

        const data = new FormData(event.currentTarget);

        let email    = data.get('email');
        let password = data.get('password');
        
        if ( !email || !password ) {
            setformError(true);
        }else{

          loginAuth(email.toString(),password.toString()).then(
            response => {
              if( response ) {
                  navigation('/chat');
              }else{
                setUserInvalid(true)
              }
            }
          );

        }
    }

    async function loginAuth( login: string, password: string) {
      return await loginService.login(login,password).then(
          response => {
            return response;
          }
        )
    }

  return (
    <Container component="main" >
      <C.ContainerForm>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

        {/* <img style={{display:'flex', margin:'0 auto'}} src={process.env.PUBLIC_URL + '/logo-flowbix.png'} alt="logo" ></img> */}

        <C.headerH1>Login</C.headerH1>
        <C.headerH2>Acesso do atendente</C.headerH2>
        <TextField
            error={ formError && !email }
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            color="success" 
            name="email"
            autoComplete="email"
            onChange={ e => setLogin(e.target.value)}
            sx={{mt:3}}
        />

        <TextField
            margin="normal"
            required
            fullWidth
            color="success"
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            error={ formError && !password }
            onChange={ e => setPassword(e.target.value)}

        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ padding:'14px', backgroundColor:'var(--btn-login)', '&:hover':{backgroundColor:'var(--btn-login-hover)'} }} >Login</Button>
        
        { userInvalid ? <MUI.Alert sx={{mt:3}}severity="error">
          <MUI.AlertTitle>Usuário Inválido</MUI.AlertTitle>
          Usuário não encontrado — <strong>confira suas credenciais!</strong>
        </MUI.Alert> : <></>
        }
        </Box>
      </C.ContainerForm>
    </Container>
  );
};

export default Login;
