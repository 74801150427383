import styled from 'styled-components'



interface IAtributes{
    isEmojiActive: boolean
}

interface IOptionsPredefinedMessages{
    isActive: boolean;
}
export const ChatFooter = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--bar-background);
    
`;

export const WrapperPastFiles = styled.div`
    width: 100%;
    padding: 10px;
    background-color: #e6e6e6;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    height: 45px;
    box-sizing: border-box;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
`;

export const IconPastWrapper = styled.div`
    font-size:large;
`;

export const WrapperPastInfo = styled.div`
    display:flex;
`;
export const SpanTitlePast = styled.span`
    font-weight:500;
`

export const SpanBodyPast = styled.span`
    font-weight:300;
    margin-left:10px;
`
export const ChatFooterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding:10px;
    align-items: center;
`;

export const TextareaInput = styled.textarea`
    resize: none;
    overflow: auto;
    outline: 0px;
    height:37px;
    box-sizing: border-box;
    flex: 1 1 auto;
    width: inherit;
    min-width: 0;
    min-height: 20px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    will-change: width;
    border-radius: 10px;
    border: none;
    padding: 8px 6px 6px 20px;
    background-color:var(--input-background);
    color:var(--text-primary);
    &::-webkit-scrollbar {
        display: none;
      }
`;

export const IconInput = styled.span`
    padding-left: 10px;
    padding-right: 10px;
    color: var(--icon-color);
    cursor:pointer;
    font-size: 22px;

    @keyframes mic {
        0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
}
`

export const ChatIconsContainer = styled.div<IAtributes>`
    position:absolute;
    bottom:80px;
    display:${
        props => props.isEmojiActive ? 'block' : 'none'
    }
`;

export const ChatReplyMessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    border: 1;
`;

export const ChatReplyMessageLabel = styled.div`

`;