import { AppBar, Box, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CreateButton } from "../../../../components/Admin/PredefinedMessagesManager/styles";
import { Header } from "../../../../components/Profile/styles";
import { ErrorSnackbar } from "../../../../components/Snackbars/ErrorSnackbar";
import { SuccessSnackbar } from "../../../../components/Snackbars/SucessSnackbar";
import { useMainContext } from "../../../../Contexts/Contexts";
import departmentService from "../../../../service/departmentService";
import ListDepartamentCard from "../ListDepartamentCard";
import NewDepartament from "../NewDepartament";
import { Wrapper } from "./style";

const ListDepartaments = () => {

  const [depsState, setDepsState] = useState<any[]>([])
  const Contexts = useMainContext();

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    Contexts.setPopupModalOpen(false);
  };

  const handleCloseErrorPopup = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    Contexts.setPopupModalErrorOpen(false);
  };

  const nav = useNavigate();

  const handleRegister = () => {
    nav('/admin/new-departament')
  }
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    departmentService.listAllDepartaments().then(deps => {
      setDepsState(deps);

    })
    Contexts.setUpdateDepartmentHandle(false)
  }, [Contexts.updateDepartmentHandler])

  return (
    <Wrapper>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflowY: "scroll" }}>
        <Header>
          <Box sx={{ display: 'flex' }}>
            <AppBar position="static" sx={{ backgroundColor: 'var(--background-company)', padding: '0.2rem', paddingTop: '0' }} >
              <Toolbar sx={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                  <h2> Departamentos</h2>
                </Typography>

                <div style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center', height: '40%' }}>

                  <CreateButton onClick={() => Contexts.setNewDepartmentOpen(true)} endIcon={<FaPlus />} ><h4>Cadastrar novo</h4></CreateButton>
                </div>

              </Toolbar>
            </AppBar>
          </Box>
        </Header>

        <TableContainer component={Paper} sx={{ width: '95%', alignContent: 'center', alignSelf: 'center', marginTop: '2rem', color: 'var(--text-primary)', borderRadius: '0' }}>
          <Table sx={{ minWidth: 350, backgroundColor: 'var(--wrapper-input-admin-background)' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '5%', color: 'var(--text-primary)' }}>ID</TableCell>
                <TableCell sx={{ color: 'var(--text-primary)', width: '8%' }}>Sigla</TableCell>
                <TableCell sx={{ color: 'var(--text-primary)' }}>Nome</TableCell>
                <TableCell align="right" sx={{ color: 'var(--text-primary)' }}>Ações</TableCell>
              </TableRow>
            </TableHead>
            {(depsState && depsState.length) ? depsState.map(user => (
              <ListDepartamentCard name={user.name} initials={user.initials} key={user.id} id={user.id} />
            )) : undefined}
          </Table>

        </TableContainer>

        {Contexts.newDepartmentOpen && <NewDepartament />}


        {/* <Snackbar open={Contexts.popupModalOpen} autoHideDuration={2000} onClose={handleClose}>
  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
    Ação concluída!
  </Alert>
</Snackbar>

<Snackbar open={Contexts.popupModalErrorOpen} autoHideDuration={2000} onClose={handleCloseErrorPopup}>
  <Alert onClose={handleCloseErrorPopup} severity="error" sx={{ width: '100%' }}>
    Oops, algo deu errado!
  </Alert>
</Snackbar> */}

        <SuccessSnackbar severity="success" snackbarText={Contexts.popupModalText} />
        <ErrorSnackbar severity="error" snackbarText={Contexts.popupModalErrorText} />
      </Box>

    </Wrapper >
  );
}

export default ListDepartaments
