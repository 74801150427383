import { ButtonsContainer, CardWrapper, ModalButton, ModalButtonRow, ModalTitle } from "./styles";
import { IoMdRemoveCircle, IoMdCreate } from 'react-icons/io';
import { IoShieldSharp } from 'react-icons/io5';
import Modal from 'react-modal';
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import userService from "../../../../service/userService";
import { UserRole } from "../../../../util/enums";
import { Avatar, TableCell, TableRow } from "@mui/material";
import { AdminPanelSettings, CloseFullscreen, DeleteOutline, EditOutlined, Person } from "@mui/icons-material";

interface IUser {
  createdAt: Date;
  updatedAt: Date;
  id: number;
  login: string;
  name: string;
  picture: string;
  roleID: UserRole;
  validID: number;
}

interface ListUserProps {
  user: IUser
}

const ListUserCard: React.FC<ListUserProps> = ({ user }) => {

  const nav = useNavigate();

  const [modalDeleteUser, setModalDeleteUser] = useState<boolean>(false);

  const handleDelete = useCallback((id: number) => {
    userService.deleteUser(id);
    setModalDeleteUser(false);
    nav('/chat');
  }, [nav]);

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '400px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'var(--background)',
      border: '1px solid var(--background)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
  };

  return (
    <>
      <TableRow
        key="TableUsers"
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {user.name}
        </TableCell>


        <TableCell align="right" component="th" scope="row" >
          {(user.roleID === UserRole.admin || user.roleID === UserRole.master) && <AdminPanelSettings sx={{ paddingRight: "0.4rem" }} /> || <Person sx={{ paddingRight: "0.4rem" }} />}
        </TableCell>

        <TableCell align="right" component="th" scope="row">
          <EditOutlined sx={{ cursor: 'pointer' }} onClick={() => {
            nav(`/admin/edit-user/${user.id}`, { state: { user: user } })
          }} />
          <DeleteOutline sx={{ cursor: 'pointer' }} onClick={() => { setModalDeleteUser(true) }} />
        </TableCell>

      </TableRow>

      {/* <CardWrapper>
        {user.name}
        <ButtonsContainer>
          {(user.roleID === UserRole.admin || user.roleID === UserRole.master) && <IoShieldSharp />}
          <IoMdCreate onClick={() => { nav(`/admin/edit-user/${user.id}`, { state: { user: user } }) }} />
          <IoMdRemoveCircle onClick={() => { setModalDeleteUser(true) }} />
        </ButtonsContainer>
      </CardWrapper> */}
      <Modal
        isOpen={modalDeleteUser}
        contentLabel="Modal"
        style={customStyles} >
        <ModalTitle>Excluir Usuário</ModalTitle>
        <p>Deseja excluir {user.name}?</p>
        <ModalButtonRow>
          <ModalButton className='activeButtonModal' onClick={() => { handleDelete(user.id as unknown as number) }}>Sim</ModalButton>
          <ModalButton onClick={() => { setModalDeleteUser(false) }}>Não</ModalButton>
        </ModalButtonRow>
      </Modal>
    </>
  )
}

export default ListUserCard;
