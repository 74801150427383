import { FC } from 'react';

import * as S from './styles';

interface Props {
  title?: string;
  value?: string;
  description?: string;
};

const Stat: FC<Props> = ({
  title = 'Titulo',
  value = 'Valor',
  description = 'Descrição',
}: Props) => {
  return (
    <S.Container>
      <S.ContentContainer>
        <S.Item>{title}</S.Item>
        <S.Value>{value}</S.Value>
        <S.Item>{description}</S.Item>
      </S.ContentContainer>
    </S.Container>
  );
};

export default Stat;