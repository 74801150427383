import styled from 'styled-components'

export const ContainerForm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0 auto;
    max-width:500px;
`;

export const headerH1 = styled.h1`
    font-size:xx-large;
    text-align: center;
    margin-bottom: 10px;
`

export const headerH2 = styled.h2`
    font-size: Large;
    font-weight: 500;
    margin: 5px;
    text-align: center;
`