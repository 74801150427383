import { configureStore } from '@reduxjs/toolkit';
import chatSlice from './chat-slice'


import rootSaga from '../sagas/root';

import createSagaMiddleware from "redux-saga";

const saga = createSagaMiddleware();

const store = configureStore({
    reducer: { chat: chatSlice.reducer },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saga),
});

saga.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;