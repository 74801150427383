import styled from 'styled-components'

interface Hover {
    isHovered: boolean
}

export const SidebarHeaderContainer = styled.div`
    margin: 0;
    height: 60px;
    min-height: 60px;
    width: 100%;
    background-color: var(--bar-background);
`;

export const ProfileWrapper = styled.div<Hover>`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-Items: center;
    width: 41.5px;
    height: 41.5px;
    border-radius: 100%;
    position: absolute;
    transition: .2s;
    background: ${props => props.isHovered ? "var(--profile-img-marker)" : "none"}
`;

export const SidebarHeaderRow = styled.div`
    display: flex;
    padding: 10px 10px 10px 20px;
    justify-content: space-between;
    align-items: center;
    position: relative;
   // gap: 0.5rem;
`;

export const ProfileLinker = styled.div`
    display: flex;
    align-items: center;
    padding: 0.2rem;
    border-radius: 22px;
    transition: .2s;
    &:hover {
        cursor: pointer;
        background-color: var(--profile-marker);
        img {
            transition: .4s;
        }
    }
`;

export const SidebarHeaderActionsRow = styled.div`
    display: flex;
    flex: 1;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
`;
export const SidebarHeaderActions = styled.div`
    display: flex;
    color: #6e6e6e;
    cursor: pointer;
    margin-left: 10px;
    align-items: center;
    gap: 0.1rem;
`;

export const SidebarHeaderImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 100%;
`;

export const SidebarHeaderName = styled.div`
    max-height: 16px;
    width: 100%;

    color: var(--text-primary);
    padding-left:10px;

    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    
    line-height: 16px;
`;

export const ApiStatusWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: default;
`;

export const SidebarHeaderDropdownLi = styled.li`
    font-size: 12px;
    list-style: none;
    margin-bottom: 8px;
    padding: 10px;
    color: var(--text-primary);
    &:hover {
        background-color: var( --background-menu-hover );
    }
`;

export const SidebarHeaderDropdownUl = styled.ul`
    padding: 0px;
    margin: 0px;
`;

export const SidebarHeaderNotification = styled.span`
    color: var(--icon-active);
`

export const SidebarHeaderDefault = styled.span`
    color: rgb(110, 110, 110);
`