import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import * as CF from './styles';

import {
    BsChatSquareText,
    BsEmojiSmile,
    BsMic,
    BsPaperclip,
    BsTrash,
    BsXSquare
} from 'react-icons/bs';
import { IoMdSend } from "react-icons/io";

import { useChat } from '../Chat/chatContext';

import Picker, { IEmojiData } from 'emoji-picker-react';

import { useOnClickOutside } from 'usehooks-ts';

import { PredefinedMessages } from '../../components/PredefinedMessages';
import { useAppSelector } from '../../hooks/redux-hooks';
import { FileUpload } from '../../interface/IModels';
import { ChatItemModel } from '../../models/redux-models';
import predefinedMessagesService from '../../service/predefinedMessageService';
import { MessageReplyComponent } from '../MessageReply';


interface PastFileControl {
    open: true
}
const ChatFooter: React.FC<{ chat: ChatItemModel }> = (props) => {

    const [textArea, setTextAreaValue] = useState<string>("");
    const [recording, setRecording] = useState<boolean>(false);
    // const [isMessageReply, setIsMessageReply] = useState<boolean>(false);
    const [recorder, setRecorder] = useState<MediaRecorder>();
    const [recordingChunks, setRecordingChunks] = useState<any[]>([]);

    const [predefinedMessagesActive, setPredefinedMessagesActive] = useState<boolean>(false);

    const [list, setList] = useState<{ id: number, title: string, body: string, departments: number[] }[]>([]);

    const [listFiltered, setListFiltered] = useState<{ id: number, title: string, body: string }[]>(list);

    const departmentsRedux = useAppSelector(state => state.chat.departments);
    const chat = useAppSelector(state => state.chat.chat);

    useEffect(() => {
        // is change chat
        if (chat.isMessagesLoading) {
            setTextAreaValue("");
        }
        setMessageReply({id:'', content:null});
    }, [chat]);

    useEffect(() => {
        setListFiltered(list);
    }, [list]);

    useEffect(() => {
        if (!predefinedMessagesActive) setListFiltered(list);
    }, [predefinedMessagesActive]);

    useEffect(() => {
        predefinedMessagesService.listPredefinedMessagesByCompany(props.chat.companyID).then(
            res => {
                if (typeof res == 'boolean') return;

                const listPredefinedMessages = res.map(pm => {
                    const item = {
                        id: pm.id,
                        title: pm.title,
                        body: pm.body,
                        departments: pm.departments.map(dep => dep.id),
                    }
                    return item;
                });

                const departmentsIDsRedux = departmentsRedux.map((dep: { id: any; }) => dep.id);

                const filterList = listPredefinedMessages.filter(item => {
                    return item.departments.some(el => departmentsIDsRedux.includes(el));
                })

                setList(filterList);
            }
        );

        document.addEventListener('paste', function (evt: ClipboardEvent) {
            const clipboardItems = evt?.clipboardData?.items;
            if (!clipboardItems) return;

            if (clipboardItems[0].type.indexOf('image') !== -1) {

                const blob = clipboardItems[0].getAsFile();

                if (!blob) return;

                const newUrl = URL.createObjectURL(blob);
                // setImage(newUrl);

                handlePastFile(blob);

            }
        });
    }, []);

    const [pastFile, setPastFile] = useState<FileUpload | null>();

    const handlePastFile = async (file: File) => {

        const fileBase64 = await toBase64(file);

        if (fileBase64 && typeof fileBase64 == "string") {

            const fileUpload: FileUpload = {
                name: file.name,
                mimeType: file.type,
                content: fileBase64.replace('data:', '').replace(/^.+,/, ''),
            };

            setPastFile(fileUpload)
            // setInputFiles([fileUpload]);
        }
    }

    const micButtonRef = useRef<any>(null);

    // After setup add event listeners
    useEffect(() => {

        if (recorder) {
            recorder.ondataavailable = (e) => {
                setRecordingChunks(state => [...state, e.data]);
            }

            recorder.onstop = () => {
                setRecording(false);
                recorder.stop();
                setRecordingChunks([]);
                // setRecorder(undefined);
            }

            recorder.onstart = () => {
                setRecording(true);
                setRecordingChunks([]);
            }
        }
    }, [recorder, recordingChunks])

    const handleCancelMic = () => {
        recorder?.stop();

        if (recorder) {
            recorder.stream.getTracks().forEach(t => t.stop())
        }

        setRecording(false);
        setRecorder(undefined);
    }
    const handleMicClick = () => {

        if (!recorder && navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    const mediaRec = new MediaRecorder(stream);

                    mediaRec.start(1000);
                    setRecorder(mediaRec);
                    return;
                });
        }

        else if (recorder && recorder.state === "recording") {

            recorder.stop();
            recorder.stream.getTracks().forEach(t => t.stop())

            const blob = new Blob(recordingChunks, { type: "audio/ogg; code=opus" });
            const reader = new FileReader();

            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                let base64data = reader.result;

                if (base64data && typeof base64data == "string") {
                    const fileUpload: FileUpload = {
                        name: "",
                        mimeType: "audio/ogg; code=opus",
                        content: base64data.replace('data:', '').replace(/^.+,/, ''),
                    };

                    setInputFiles([fileUpload]);
                }

            }

        }
        setRecorder(undefined);
    }


    const {
        inputField,
        setInputField,
        inputFiles,
        setInputFiles,
        messageReply,
        setMessageReply
    } = useChat();

    useEffect( () => {
        refInput.current?.focus();
    },[messageReply]);

    const handlerSend = () => {
        if (pastFile) {
            setInputFiles([pastFile])
            setPastFile(null);
        }
        if (textArea.trim() === "" || textArea.length === 0) return;
        setInputField(formatText());
        setTextAreaValue("");
    };

    const handlerIcons = () => {
        setEmojiContainer(!emojiContainer);
    };

    const [chosenEmoji, setChosenEmoji] = useState<IEmojiData>();
    const [emojiContainer, setEmojiContainer] = useState<boolean>(false);

    const onEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, emojiObject: IEmojiData) => {
        setChosenEmoji(emojiObject);

        if (!emojiObject) return;
        let text = textArea;
        text += emojiObject.emoji;
        setTextAreaValue(text);

    };

    function formatText(content?: string) {
        let nameUser = localStorage.getItem("user");
        let nameFormat = `*${nameUser}*\n`;
        let contentText = content ? content : textArea;
        return nameFormat + contentText;
    }

    const ref = useRef(null);
    const refInput = useRef<HTMLTextAreaElement>(null);
    const inputFile = useRef<HTMLInputElement>(null)
    const refModalPredefinedMessage = useRef(null);

    useEffect(() => {
        refInput.current?.focus();
    }, [refInput]);

    const handleClickOutside = () => { if (emojiContainer) setEmojiContainer(false); }


    const handleKeyboard = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if ((e.shiftKey && e.key === "Enter")) return; 

        if (e.key === "Enter") {
            if (pastFile) {
                setInputFiles([pastFile])
                setPastFile(null);
            }
            if ((textArea.trim().length === 0 && textArea == ' ')) return;
            handlerSend();
        }
    }
    const handlerInputFile = () => {
        inputFile.current?.click();
    }

    const toBase64 = (file: Blob) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    function detectMimeType(base64String: string, fileName: string): string {
        let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
        if (ext === undefined || ext === null || ext === "") {
            ext = "bin";
        }
        ext = ext.toLowerCase();

        const signatures: { [key: string]: string } = {
            "JVBERi0": "application/pdf",
            "R0lGODdh": "image/gif",
            "R0lGODlh": "image/gif",
            "iVBORw0KGgo": "image/png",
            "TU0AK": "image/tiff",
            "/9j/": "image/jpg",
            "UEs": "application/vnd.openxmlformats-officedocument.",
            "PK": "application/zip"
        };

        for (let s in signatures) {
            if (base64String.indexOf(s) === 0) {
                let x = signatures[s];
                // if an office file format
                if (ext.length > 3 && ext.substring(0, 3) === "ppt") {
                    x += "presentationml.presentation";
                } else if (ext.length > 3 && ext.substring(0, 3) === "xls") {
                    x += "spreadsheetml.sheet";
                } else if (ext.length > 3 && ext.substring(0, 3) === "doc") {
                    x += "wordprocessingml.document";
                }
                // return
                return x;
            }
        }

        // if we are here we can only go off the extensions
        const extensions: { [key: string]: string } = {
            "xls": "application/vnd.ms-excel",
            "ppt": "application/vnd.ms-powerpoint",
            "doc": "application/msword",
            "xml": "text/xml",
            "mpeg": "audio/mpeg",
            "mpg": "audio/mpeg",
            "txt": "text/plain"
        };

        for (let e in extensions) {
            if (ext.indexOf(e) === 0) {
                let xx = extensions[e];
                return xx;
            }
        }

        // if we are here – not sure what type this is
        return "unknown";
    }

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        const files: FileUpload[] = [];

        const arrayPromises = Array.from(event.target.files).map(async file => {

            const fileBase64 = await toBase64(file);

            if (fileBase64 && typeof fileBase64 == "string") {

                const fileUpload: FileUpload = {
                    name: file.name,
                    mimeType: file.type,
                    content: fileBase64.replace('data:', '').replace(/^.+,/, ''),
                };

                return fileUpload;
            }

        });

        const filesPromise = await Promise.all(arrayPromises);

        filesPromise.forEach(p => {
            if (p) files.push(p);
        });

        setInputFiles(files);

        event.target.value = ""
    }

    const customStyles = {
        width: "400px",
        boxShadow: "none",
    }

    const internationalizationPicker = {
        smileys_people: 'Smileys e pessoas',
        animals_nature: 'Animais e natureza',
        food_drink: 'Comida e bebida',
        travel_places: 'Lugares e viagens',
        activities: 'Atividades',
        objects: 'Objetos',
        symbols: 'Símbolos',
        flags: 'Bandeiras',
        recently_used: 'Mais usados',
    }

    useOnClickOutside(ref, handleClickOutside);
    // useOnClickOutside(refModalPredefinedMessage, handleClickOutside);

    const renderRecord = () => {

        // if (textArea.length > 0 ){

            return (
                <CF.IconInput onClick={(handlerSend)} >
                    <IoMdSend />
                </CF.IconInput>
            );

        // } else {
        //     return (
        //         <>
        //             <CF.IconInput ref={micButtonRef} onClick={handleMicClick}
        //                 style={recording ? {
        //                     color: "red",
        //                     boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
        //                     transform: "scale(1)",
        //                     animation: "mic 1s infinite",
        //                     borderRadius: "4px"
        //                 } : undefined}
        //             >   
        //                 <BsMic />
        //             </CF.IconInput>
        //             {recording ? 
        //             <CF.IconInput onClick={handleCancelMic} >
        //                 <BsTrash />
        //             </CF.IconInput>
        //             : '' }
        //         </>
        //     )
        // }


    }

    const handleEditPredefinedMessage = (id_message: number) => {
        const existsPredefinedMessage = listFiltered.find(item => item.id === id_message);
        if (!existsPredefinedMessage) return;
        setTextAreaValue(existsPredefinedMessage.body);
    };

    const handleSendPredefinedMessage = (id_message: number) => {
        const existsPredefinedMessage = listFiltered.find(item => item.id === id_message);
        if (!existsPredefinedMessage) return;
        setPredefinedMessagesActive(false);

        const textFormat = formatText(existsPredefinedMessage.body);
        setInputField(textFormat);
        setTextAreaValue("");
    };

    // handle value input field to send message  
    const handleValueInput = (value: string) => {

        if (value.includes('\n') && predefinedMessagesActive) {
            if (listFiltered.length > 0) {
                const predefinedMessage = listFiltered[0];
                const textFormat = formatText(predefinedMessage.body);
                setInputField(textFormat);
                setTextAreaValue("");
                setPredefinedMessagesActive(false);
                return;
            }
        }

        if ((value.trim().length === 0 && value == ' ') || (value === '\n')) {
            return;
        }

        const regexShortCut = /\//g

        if (regexShortCut.exec(value) && value.length <= 25) {
            setPredefinedMessagesActive(true);

            const listFiltered = list.filter(item => {
                return (item.title.toUpperCase().indexOf(value.replace("/", "").toUpperCase()) > -1)
            });

            setListFiltered(listFiltered);
        }
        else {
            setPredefinedMessagesActive(false);
        }


        setTextAreaValue(value);
    }

    const useAutosizeTextArea = (
        textAreaRef: HTMLTextAreaElement | null,
        value: string
    ) => {
        useEffect(() => {
            if (textAreaRef) {
                // We need to reset the height momentarily to get the correct scrollHeight for the textarea
                const oldHeight = textAreaRef.style.height;
                textAreaRef.style.height = "0px";
                const scrollHeight = textAreaRef.scrollHeight;

                if (scrollHeight > 60) {
                    textAreaRef.style.height = 60 + "px";

                    return;
                }
                // We then set the height directly, outside of the render loop
                // Trying to set this with state or a ref will product an incorrect value.
                textAreaRef.style.height = scrollHeight + "px";
            }
        }, [textAreaRef, value]);
    };

    useAutosizeTextArea(refInput.current, textArea);

    return (
        <CF.ChatFooter>
            {pastFile ? <CF.WrapperPastFiles>
                <CF.WrapperPastInfo>
                    <CF.SpanTitlePast>Arquivo:</CF.SpanTitlePast><CF.SpanBodyPast>{pastFile?.name}</CF.SpanBodyPast>
                </CF.WrapperPastInfo>
                <CF.IconPastWrapper><BsXSquare onClick={() => setPastFile(null)} /></CF.IconPastWrapper>
            </CF.WrapperPastFiles> : ''}
            <CF.ChatFooterWrapper>

                <MessageReplyComponent messageReply={messageReply}/>

                <CF.IconInput>
                    <PredefinedMessages
                        listData={listFiltered}
                        visibility={predefinedMessagesActive}
                        selectEditOption={(id: number) => handleEditPredefinedMessage(id)}
                        selectSendOption={(id: number) => handleSendPredefinedMessage(id)}
                        clickOutside={(clicked: boolean) => setPredefinedMessagesActive(clicked ? false : true)}
                    ></PredefinedMessages>
                    <BsChatSquareText onClick={() => setPredefinedMessagesActive(!predefinedMessagesActive)} />
                </CF.IconInput>
                <CF.IconInput onClick={(handlerIcons)}   >
                    <BsEmojiSmile />
                </CF.IconInput>
                <CF.IconInput onClick={(handlerInputFile)}>
                    <BsPaperclip />
                </CF.IconInput>

                <CF.TextareaInput
                    onChange={(
                        ev: React.ChangeEvent<HTMLTextAreaElement>,
                    ): void => {
                        handleValueInput(ev.target.value)
                    }}
                    value={textArea}
                    onKeyUp={handleKeyboard}
                    placeholder="Digite aqui"
                    ref={refInput}
                />

                {renderRecord()}
                
                <CF.ChatIconsContainer ref={ref} isEmojiActive={emojiContainer}>
                    <Picker pickerStyle={customStyles} groupNames={internationalizationPicker} onEmojiClick={onEmojiClick} />
                </CF.ChatIconsContainer>

                <input type="file" onChange={handleUpload} ref={inputFile} multiple style={{ display: 'none' }}></input>
            </CF.ChatFooterWrapper>
        </CF.ChatFooter>
    )

}

export default ChatFooter;
