import axios from 'axios'

async function getFilteredReport(start: string, end: string, search: string, index: number, limit: number) {
  
  const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'report/' || "";

  const tokenJWT = localStorage.getItem("auth");

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${tokenJWT}`,
  }

  const url = baseUrl+'/list.php';

  return await axios.post<any>(url,
    {
      search: search,
      period_start: start,
      period_end: end,
      index: index,
      limit: limit
    },
    {
      headers
    }).then( request  => 
    {   
        return request.data;
    }).catch( err => {
        
        throw err;
    });
}

const ReportService = {
  getFilteredReport
}

export default ReportService;
