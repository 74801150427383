import { FC } from 'react';
import { ChatItemModel } from '../../models/redux-models';
import { SidebarTabEnum, useFilter } from '../SidebarChat/sidebarChatContext';
import SidebarChatItem from '../SidebarChatItem';
import * as S from './styles';

const SidebarListChats: FC<{ allChatsHistory: ChatItemModel[], filteredChatsHistory: ChatItemModel[], newMessages: ChatItemModel[], filteredMessages: ChatItemModel[] }> = (props) => {
    const { sidebarTabSelected, setSidebarTabSelected } = useFilter();

    function listComponents() {
        if (props.allChatsHistory && props.allChatsHistory.length > 0) {

            if (props.filteredChatsHistory && props.filteredChatsHistory.length > 0) {
                return props.filteredChatsHistory?.map((element) => <SidebarChatItem isHistory={true} key={element.id} chat={element} />);
            }
            return props.allChatsHistory?.map((element) => <SidebarChatItem isHistory={true} key={element.id} chat={element} />);
        }

        if (props.newMessages.length > 0 || props.filteredMessages.length > 0) {

            if (props.filteredMessages.length > 0) {
                switch (sidebarTabSelected) {
                    case SidebarTabEnum.IN_ATTENDANCE:
                        return props.filteredMessages?.filter(chat => chat.agent_id == localStorage.getItem("id")).map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
                    case SidebarTabEnum.NO_ATTENDANT:
                        return props.filteredMessages?.filter(chat => !chat.agent_id).map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
                    case SidebarTabEnum.IN_GENERAL_ATTENDANCE:
                        return props.filteredMessages?.map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
                }
            }

            switch (sidebarTabSelected) {
                case SidebarTabEnum.IN_ATTENDANCE:
                    return props.newMessages.filter(chat => chat.agent_id == localStorage.getItem("id")).map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
                case SidebarTabEnum.NO_ATTENDANT:
                    return props.newMessages.filter(chat => !chat.agent_id).map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
                case SidebarTabEnum.IN_GENERAL_ATTENDANCE:
                    return props.newMessages.map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
            }
        }

        return <></>;
    }

    return <>
        <S.SidebarChatContainer>
            {listComponents()}
        </S.SidebarChatContainer>
    </>;
};

export default SidebarListChats;
