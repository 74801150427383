import { useState } from "react";
import AdminSidebar from "../Sidebar";
import { ContentWrapper, ScreenWrapper } from "./styles";

interface AdminlayoutProps {
  children?: React.ReactNode
}

const Adminlayout: React.FC<AdminlayoutProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(true);
  const theme = localStorage.getItem('theme');
  return (
    <ScreenWrapper data-theme={theme ? theme : 'light'}>
      <AdminSidebar />
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </ScreenWrapper>
  );
}

export default Adminlayout
