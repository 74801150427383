import { CSSProperties, useState } from 'react';

import { ButtonText, ButtonWrapper } from "./styles";

interface SidebarButtonProps {
  title: string;
  handleFunction: Function;
  Icon: any;
  hideText?: boolean | undefined;
}

const SidebarButton: React.FC<SidebarButtonProps> = ({ title, handleFunction, Icon, hideText = false }) => {
  const [iconStyle] = useState<CSSProperties>({
    borderRadius: "100%",
    marginLeft: "10px"
  });

  return (
    <ButtonWrapper onClick={() => { handleFunction() }}>
      <Icon size="20px" style={iconStyle} />
      {!hideText && <ButtonText>
        {title}
      </ButtonText>}
    </ButtonWrapper>
  );
}

export default SidebarButton;
