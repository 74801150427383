import axios from 'axios';
import { ICompany } from '../interface/IModels';

const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'company/' || "";

export default {
  async getCompanyByID(id: number) {
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenJWT}`,
    }

    let url = baseUrl + "getCompany.php";

    return await axios.post<ICompany>(url,
      { id },
      {
        headers
      }).then(request => {
        return request.data;
      }).catch(err => {
        return false;
      });

  }

}