import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { ChatItemModel, CompanyItem, DepartmentItem } from "../models/redux-models";
import chatService from '../service/chatService';
import chatSlide from './chat-slice';
import { RootState } from './index';

import { IMessage } from '../interface/IModels';
import historyService from '../service/historyService';

export const chatActions = chatSlide.actions

export const fetchChat = (chat_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        chatService.getChatActive(chat_id).then((response) => {
            if (response) {
                dispatch(chatActions.setChatItem(response))
            }
        });
    }
}


export const setChat = (chat: ChatItemModel): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {

        dispatch(chatActions.setChat(chat));
    }
}

export const resetChat = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(chatActions.resetChat());
    }
}

export const resetState = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(chatActions.resetState());
    }
}

export const changeDepartmentChat = (chat_id: number, id_department: number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        const havePermissionInDepartment = getState().chat.departments.find(dep => dep.id == id_department);

        if (!havePermissionInDepartment) {

            dispatch(chatActions.resetChat());
            dispatch(chatActions.removeChat(chat_id));

        } else {
            const oldChat = getState().chat.chat;
            const newUpdatedChat = {
                ...oldChat,
                departmentID: id_department,
            }

            dispatch(chatActions.setChat(newUpdatedChat));

            dispatch(chatActions.updateChatInAttendance(newUpdatedChat));

        }


    }
}

export const changeNameUserChat = (chat_id: number, name: string): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        const oldChat = getState().chat.chat;
        const newUpdatedChat = {
            ...oldChat,
            name: name,
        }

        dispatch(chatActions.setChat(newUpdatedChat));

        dispatch(chatActions.updateChatInAttendance(newUpdatedChat));
    }
}

export const fetchUpdateChats = (chats: ChatItemModel[]): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {
        dispatch(chatActions.setChats(chats))

    }
}

export const fetchMessage = (message: IMessage): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {
        if (!message) return;
        dispatch(chatActions.setNewMessageChat(message));
        chatService.readMessages(message.chat_id);
    }

};

export const fetchChats = (): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        const response: ChatItemModel[] = await chatService.getChatsInAttendance(getState().chat.companysSelect, getState().chat.departments);
        dispatch(chatActions.getChatsInAttendance(response))
    }
};

export const socketReceivedMessage = (message: IMessage): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {

        const chat = getState().chat.chatsInAttendance.find(chat => chat.id === message.chat_id);

        if (chat) {

            let newObject = Object.assign({}, chat);
            newObject.messages++;
            dispatch(chatActions.updateChatInAttendance(newObject));
        }
        // }else{

        //     chatService.getChatActive(message.chat_id).then( res => {
        //         res.messages_chat.push(message);
        //         dispatch( chatActions.pushChatInAttendance(res));
        //     });
        // }
    }
};


//NotifyTeste
/*const showNotification = () => {

    if (Notification.permission === 'default' || Notification.permission == 'denied') {
        Notification.requestPermission();
      }

    const options = {
      body: 'Esta é a mensagem da notificação!',
      title: 'Este é o título!',
    };

         const notification = new Notification(options.title, options);
         console.log('teste de notify');
  };*/

const playNotification = () => {
    let not = localStorage.getItem("soundNotification");
    if (not === "true") {
        const audio = new Audio(process.env.PUBLIC_URL + '/message.mp3');
        audio.play();
        //  showNotification();
    }
}

export const socketInitAttendance = (chat_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        const chatExists = getState().chat.chatsInAttendance.find(chat => chat.id === chat_id);

        if (!chatExists) {

            chatService.getChatActive(chat_id).then(
                res => {
                    if (res) {
                        const companySelected = getState().chat.companysSelect.find(comp => comp.id === res.companyID);

                        if (!companySelected) return;

                        const exists = getState().chat.departments.find(dep => dep.id === res.departmentID);
                        if (exists) playNotification();

                        res.history.history = false;
                        res.messages_read = 0;
                        dispatch(chatActions.pushChatInAttendance(res));
                    }
                }
            )
        }

    }

}

export const socketInitAttendanceInDepartmentUpdate = (chat_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        const chatExists = getState().chat.chatsInAttendance.find(chat => chat.id === chat_id);

        if (!chatExists) {

            chatService.getChatActive(chat_id).then(
                res => {
                    if (res) {
                        const companySelected = getState().chat.companysSelect.find(comp => comp.id === res.companyID);

                        if (!companySelected) return;

                        const exists = getState().chat.departments.find(dep => dep.id === res.departmentID);
                        if (exists) playNotification();

                        res.history.history = false;
                        res.messages_read = 0;
                        dispatch(chatActions.pushChatInAttendance(res));
                    }
                }
            )
        } else {

            chatService.getChatActive(chat_id).catch(error => {
                dispatch(chatActions.resetChat());
                dispatch(chatActions.removeChat(chat_id));
            })

        }

    }

}

export const socketInitTransferAttendance = (chat_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {

        const idOldChat = getState().chat.chat.id;
        
        const chatExists = getState().chat.chatsInAttendance.find(chat => chat.id == chat_id);
        if (chatExists) {

            chatService.getChatActive(chat_id).then(res => {

                if (res) {

                    let newObject = Object.assign({}, chatExists);
                    newObject.agent = res.agent;
                    newObject.agent_id = res.agent_id;
                    newObject.departmentID = res.departmentID;
                    newObject.departmentName = res.departmentName;

                    dispatch(chatActions.updateChatInAttendance(newObject));
                    if(idOldChat == chat_id) {
                        dispatch(chatActions.setChatItem(newObject));
                    }
                }

            }).catch(error => {
                dispatch(chatActions.resetChat());
                dispatch(chatActions.removeChat(chat_id));
            })
        } else {

            chatService.getChatActive(chat_id).then(
                res => {
                    if (res) {
                        const companySelected = getState().chat.companysSelect.find(comp => comp.id === res.companyID);

                        if (!companySelected) return;

                        const exists = getState().chat.departments.find(dep => dep.id === res.departmentID);
                        if (exists) playNotification();

                        res.history.history = false;
                        res.messages_read = 0;
                        dispatch(chatActions.pushChatInAttendance(res));
                    }
                }
            )
        }

    }

}

export const socketChatEnd = (chat_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        const chatExists = getState().chat.chatsInAttendance.find(chat => chat.id === chat_id);

        if (chatExists) {
            dispatch(chatActions.removeChat(chat_id));
        }
    }
}


export const fetchCurrentChatAndsChats = (chat_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        chatService.getChatActive(chat_id).then((response) => {
            if (response) {
                dispatch(chatActions.setChatItem(response))
            }
        })
            .then(response => {
                chatService.getChatsInAttendance(getState().chat.companysSelect, getState().chat.departments).then(response => {
                    dispatch(chatActions.getChatsInAttendance(response))
                });
            });


    }

};

export const socketInitTransferAgent = (chat_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        const idOldChat = getState().chat.chat.id;

        const chatExists = getState().chat.chatsInAttendance.find(chat => chat.id == chat_id);
        if (chatExists) {

            chatService.getChatActive(chat_id).then(res => {

                if (res) {

                    let newObject = Object.assign({}, chatExists);
                    newObject.agent = res.agent;
                    newObject.agent_id = res.agent_id;

                    dispatch(chatActions.updateChatInAttendance(newObject));
                    if(idOldChat == chat_id) {
                        dispatch(chatActions.setChatItem(newObject));
                    }
                }

            }).catch(error => {
                dispatch(chatActions.resetChat());
                dispatch(chatActions.removeChat(chat_id));
            })
        }

    }

};


export const fetchSelectedChat = (chat_id: number, isHistory: boolean): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {

        if (!isHistory) {
            const idOldChat = getState().chat.chat.id;

            var oldChat = getState().chat.chatsInAttendance.find(chat => chat.id === idOldChat);

            if (oldChat) {

                let newObject = Object.assign({}, oldChat);
                newObject.messages_read = newObject.messages;
                dispatch(chatActions.updateChatInAttendance(newObject));
            }

            const chat = getState().chat.chatsInAttendance.find(chat => chat.id == chat_id);

            if (chat) {
                dispatch(chatActions.setChat({ ...chat, isMessagesLoading: true }));

                let newObjectChat = Object.assign({}, chat);
                newObjectChat.messages_read = newObjectChat.messages;
                dispatch(chatActions.updateChatInAttendance(newObjectChat));

                chatService.getChatActive(chat_id).then((response) => {
                    if (response) {
                        dispatch(chatActions.setChatItem(response))
                    }
                });
            }
        } else {
            chatService.getChatActive(chat_id).then((response) => {
                if (response) {
                    response.isHistory = true;
                    dispatch(chatActions.setChatItem(response))
                }
            });

        }

    }

};

export const setDepartments = (departments: DepartmentItem[]): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(chatActions.setDepartments(departments));
    }

};

export const addCompanyItem = (company: CompanyItem): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {
        dispatch(chatActions.addCompanyItem(company));
    }

};


export const setCompanys = (companys: CompanyItem[]): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {
        dispatch(chatActions.setCompanys(companys));
    }

};


export const setSelectedCompanies = (companys: CompanyItem[], departments: DepartmentItem[]): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {
        dispatch(chatActions.setSelectedCompanys(companys));
        dispatch(chatActions.setDepartments(departments));

        if (companys.length == 0) {
            dispatch(chatActions.setChat({
                id: 0,
                agent: null,
                agent_id: null,
                name: "",
                phone: "",
                picture: "",
                messages: 0,
                messages_read: 0,
                updatedAt: "",
                isGroup: false,
                companyID: 0,
                departmentID: 0,
                messages_chat: [],
                history: {
                    history: false,
                    historys: [],
                },
                extra_payload: "",
                isMessagesLoading: false,
            }));
        }
        chatService.getChatsInAttendance(companys, departments).then(response => {
            dispatch(chatActions.getChatsInAttendance(response))
        });
    }
};

export const fetchMessagesHistory = (chat_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        historyService.getMessagesHistory(chat_id).then(res => {
            if (typeof res == "boolean") return;

            type ParamsHistory = {
                messages: IMessage[],
                chat_id: number,
            }
            const params: ParamsHistory = {
                chat_id: chat_id,
                messages: res,
            }
            dispatch(chatActions.setMessagesHistory(params));
        })
    }
}