import { Tab, Tooltip } from "@mui/material";
import Tabs from '@mui/material/Tabs';

import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { UserRole } from '../../util/enums';
import { useFilter } from '../SidebarChat/sidebarChatContext';
import configService from "../../service/configService";

const SidebarTabPanel: FC<{ inAttendanceCount: number, noAttendantCount: number, inGeneralAttendanceCount: number }> = (props) => {
    const { sidebarTabSelected, setSidebarTabSelected } = useFilter();

    const [canSeeGeneralAttendanceList, setCanSeeGeneralAttendanceList] = useState<boolean>(false);

    const handleChangeTab = (event: SyntheticEvent, newTabSelected: number) => {
        setSidebarTabSelected(newTabSelected);
    };

    useEffect(() => {
        const selectedCompanies = localStorage.getItem("selected-companys");
        const selectedCompaniesObj = selectedCompanies ? JSON.parse(selectedCompanies) : "";
        
        const companyId = selectedCompaniesObj ? selectedCompaniesObj[0].value : 0;
        if(!companyId) return;

        const role = localStorage.getItem("role");

        configService.getListAttendanceOptionConfigByCompanyId(companyId).then(res => {
            switch (res.listAttendanceOption) {
                case "admin-only":

                    if (role === UserRole.master.toString() || role === UserRole.admin.toString()) {
                        setCanSeeGeneralAttendanceList(true);
                    }

                    break;
                case "all":
                    setCanSeeGeneralAttendanceList(true);

                    break;
                case "some":

                    if (res.agentsSelected?.length > 0) {
                        const id = localStorage.getItem("id")?.toString();
                        if (res.agentsSelected.includes(parseInt(id ?? "0"))) setCanSeeGeneralAttendanceList(true);
                    }

                    if (role === UserRole.master.toString() || role === UserRole.admin.toString()) {
                        setCanSeeGeneralAttendanceList(true);
                    }

                    break;
                default:

                    if (role === UserRole.master.toString() || role === UserRole.admin.toString()) {
                        setCanSeeGeneralAttendanceList(true);
                    }

                    break;
            }
        });
        setSidebarTabSelected(0);

    }, []);

    return (<Tabs
        value={sidebarTabSelected}
        onChange={handleChangeTab}
        variant="fullWidth"
        textColor="inherit"
        sx={{ backgroundColor: 'var(--background-department)', color: 'var(--text-department)' }}
        TabIndicatorProps={{ style: { background: 'var(--text-department)' } }}
    >
        <Tooltip title="Atendimentos que você é o responsável" >
            <Tab label={`Atendendo ${props.inAttendanceCount}`} wrapped />
        </Tooltip>
        <Tooltip title="Atendimentos sem responsável" >
            <Tab label={`Fila ${props.noAttendantCount}`} wrapped />
        </Tooltip>
        <Tooltip title="Todos os atendimentos em andamento" >
            {!canSeeGeneralAttendanceList ?
                <span
                    style={{cursor: 'not-allowed'}}
                >
                    <Tab
                        label={`Geral`}
                        disabled={!canSeeGeneralAttendanceList}
                    />
                </span> :
                <Tab
                    label={`Geral ${props.inGeneralAttendanceCount}`}
                    wrapped
                    disabled={!canSeeGeneralAttendanceList}
                />
            }
        </Tooltip>
    </Tabs>);
};

export default SidebarTabPanel;
