import styled from "styled-components";

export const SelectedItemRow = styled.div`
  display: flex;
  width: 100%;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #EAF1E8;
`;
