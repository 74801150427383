import styled from 'styled-components'


export interface ContainerChatProps {
  hide: boolean;
}

export const ContainerChat = styled.div<ContainerChatProps>`
    display: ${props => props.hide ? "none" : "flex"};
    flex-direction: column;
    width: 70%;
    background-color: var(--background-chat);
    @media (max-width: 768px) {
      position: fixed;
      left: 0;
      width: 100vw;
      height: 100vh;
    }
`;

export const ContainerRoot = styled.div`
  background-color: var(--background);
  height: 100vh;
  @media (min-width: 1600px) {
    height: calc(100vh - 20px);
    padding-top: 20px;
  }
`;

export const ContainerApp = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: var(--background);
  margin:0 auto;
  box-shadow: 0 6px 18px rgb(11 20 26 / 5%);
  @media (min-width: 1600px) {
     height: calc(100vh - 20px);
 
  }
`;

export const ModalButtonRow = styled.div`
  display:flex;
`;

export const ModalButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: #c8c8c1;
  border-radius: 6px;
  cursor:pointer;
  margin-right:15px;
`;

export const ModalTitle = styled.h3`
  margin:0px;
`;
