import { NoInfer } from '@reduxjs/toolkit/dist/tsHelpers';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface ContextType {
  id: string | null;
  setId: (id: string | null) => void;
  webhookId: string | null;
  setWebhookID: (id: string | null) => void;
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  newDepartmentOpen: boolean;
  setNewDepartmentOpen: (newDepartmentOpen: boolean) => void;
  editWebhookOpen: boolean;
  setEditWebhookOpen: (newDepartmentOpen: boolean) => void;
  updateDepartmentHandler: boolean;
  setUpdateDepartmentHandle: (updateDepartmentHandler: boolean) => void;
  popupModalOpen: boolean;
  setPopupModalOpen: (popupModalOpen: boolean) => void;
  popupModalErrorOpen: boolean;
  setPopupModalErrorOpen: (popupModalErrorOpen: boolean) => void;
  popupModalErrorText: string;
  setPopupModalErrorText: (popupModalErrorText: string) => void;
  popupModalText: string;
  setPopupModalText: (popupModalText: string) => void;
  updatedDepartment: { chatId: number; departmentId: number } | null;
  setUpdatedDepartment: (popupModalText: { chatId: number; departmentId: number } | null) => void;
}

const Context = createContext<ContextType | undefined>(undefined);

interface ProviderProps {
  children: ReactNode;
}

export const IdProvider: React.FC<ProviderProps> = ({ children }) => {
  const [id, setId] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [updateDepartmentHandler, setUpdateDepartmentHandle] = useState<boolean>(false);
  const [newDepartmentOpen, setNewDepartmentOpen] = useState<boolean>(false);
  const [popupModalOpen, setPopupModalOpen] = useState<boolean>(false);
  const [popupModalErrorOpen, setPopupModalErrorOpen] = useState<boolean>(false);
  const [popupModalText, setPopupModalText] = useState<string>('');
  const [popupModalErrorText, setPopupModalErrorText] = useState<string>('');
  const [webhookId, setWebhookID] = useState<string | null>(null);
  const [editWebhookOpen, setEditWebhookOpen] = useState<boolean>(false);
  const [updatedDepartment, setUpdatedDepartment] = useState<{ chatId: number; departmentId: number } | null>(null);

  return (
    <Context.Provider value={{
      popupModalErrorText, setPopupModalErrorText,
      popupModalText, setPopupModalText,
      popupModalErrorOpen, setPopupModalErrorOpen,
      id, setId,
      modalOpen, setModalOpen,
      newDepartmentOpen, setNewDepartmentOpen,
      updateDepartmentHandler, setUpdateDepartmentHandle,
      popupModalOpen, setPopupModalOpen,
      webhookId, setWebhookID,
      editWebhookOpen, setEditWebhookOpen,
      updatedDepartment, setUpdatedDepartment
    }}>
      {children}
    </Context.Provider>
  );
};

export const useMainContext = (): ContextType => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useContext deve ser um valor dentro do IDProvider');
  }
  return context;
};