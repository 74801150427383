import { TitleContainer, Wrapper } from "./styles";

const NewCompany: React.FC = () => {
  return (
    <Wrapper>
      <TitleContainer>
        Nova Compania
      </TitleContainer>
    </Wrapper>
  );
};

export default NewCompany;
