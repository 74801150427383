import { ReactNode, useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux-hooks";

import * as S from './styles';

const ImageCompany: React.FC<{companyID:number}> = (props) => {

    const [ companyImage, setCompanyImage] = useState<ReactNode>(<></>);
    
    const companys = useAppSelector( state => state.chat.companysSelect);

    useEffect( () => {
        renderImageCompany(props.companyID);
    }, []);

    const validateUrlCompany = async (url: string) => {
        return new Promise(function(resolve, reject){
            var img = new Image()
            img.onload = function(){
                resolve(url)
            }
            img.onerror = function(){
                reject(url)
            }

            img.src = url
        });
    }
    
    const renderImageCompany = async ( idCompany: number ) => {

        const company = companys.find( company => company.id === idCompany );

        const url =  (company && company.url || "") ;

        const isValidUrl = await validateUrlCompany(url).then( res => {
            return true;
        }).catch( error => {
            return false;
        });
        const urlCompany = isValidUrl ? url : "";
        
        if ( urlCompany ){
            setCompanyImage(
                <S.WrapperEmployeeImage>
                    <S.EmployeeImage src={urlCompany} />
                </S.WrapperEmployeeImage>
           );

        }else{
            setCompanyImage( <></> );
        }
    
    }

    return (
        <>{companyImage}</>
    );
};


export default ImageCompany;
    