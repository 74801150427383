import axios from 'axios'

const baseUrl: string = process.env.REACT_APP_URL_APP_BOT_API + 'zapi/' || "";

interface MessageData {
    message: string;
    phone: number;
}

const getZAPIByID = async (id: number) => {
    const tokenJWT = localStorage.getItem("auth");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenJWT}`,
    };

    let url = baseUrl + "getZAPIData.php";

    try {
        const response = await axios.post(url, { id }, { headers });
        return response.data;
    } catch (err) {
        console.error('Error in getZAPIByID:', err);
        return false;
    }
};

const sendMessage = async (messageDataJson: MessageData, url: string) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Client-Token': 'F86e6d40befa14914aa4077c8986569b6S',
    });

    const messageData = { message: messageDataJson.message, phone: messageDataJson.phone };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(messageData),
        });

        if (!response.ok) {
            return false;
        } else {
            return response;
        }
    } catch (error) {
        console.error('Error sending message:', error);
        throw error;
    }
};

export { getZAPIByID, sendMessage };