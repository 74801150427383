import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { ChatItemModel, DepartmentItem } from '../../models/redux-models';

import { SidebarChatContext, SidebarHistoryContext, SidebarTabEnum, } from './sidebarChatContext';
import SidebarSearch from '../SidebarSearch';
import SidebarTabPanel from '../SidebarTabPanel';
import Select, { ActionMeta, MultiValue } from 'react-select';
import toast from 'react-hot-toast';
import departmentService from '../../service/departmentService';
import historyService from '../../service/historyService';
import { setSelectedCompanies } from '../../store/chat-actions';
import SidebarListChats from '../SidebarListChats';

const SidebarChat: React.FC = React.memo((props) => {

    const [newMessages, setNewMessage] = useState<ChatItemModel[]>([]);
    const [filteredChatsHistory, setFilteredChatHistory] = useState<ChatItemModel[]>([]);
    const [allChatsHistory, setAllChatsHistory] = useState<ChatItemModel[]>([]);
    const [inputField, setInputField] = useState<string>("");
    const [historyMode, setHistoryMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sidebarTabSelected, setSidebarTabSelected] = useState<SidebarTabEnum>(SidebarTabEnum.IN_ATTENDANCE);

    const [unreadMode, setUnreadMode] = useState<boolean>(false);
    const [optionsCompany, setOptionsCompany] = useState<Array<{ value: string; label: string; }>>();
    const [selectedOptionsCompany, setSelectedOptionsCompany] = useState<Array<{ value: string; label: string; }>>();
    const [filteredMessages, setFilteredMessages] = useState<ChatItemModel[]>([]);

    const dispatch = useAppDispatch();
    const allChats = useAppSelector(state => state.chat.chatsInAttendance);
    const companies = useAppSelector(state => state.chat.companys);
    const chat = useAppSelector(state => state.chat);

    const departments = useAppSelector(state => state.chat.departments);

    const inAttendanceCountValue = allChats.filter(chat => chat.agent_id == localStorage.getItem("id")).length;
    const noAttendantCountValue = allChats.filter(chat => !chat.agent_id).length;
    const inGeneralAttendanceCountValue = allChats.length;

    useEffect(() => {
        let messages = [...allChats];
        messages.sort((a, b) => {
            return (new Date(b.updatedAt).getTime()) - (new Date(a.updatedAt).getTime());
        });
        setNewMessage(() => {
            return [...messages]
        });
    }, [allChats]);

    useEffect(() => {
        if (allChatsHistory.length > 0) {
            getHistory();
        }
    }, [inputField]);

    useEffect(() => {
        setInputField("");
        getHistory();
    }, [historyMode]);

    useEffect(() => {
        if (unreadMode) {
            let unreadMessages = newMessages.filter(element => {
                if (element.messages - element.messages_read !== 0) {
                    return element
                }
            });
            setNewMessage(unreadMessages)
        } else {
            let messages = [...allChats];
            messages.sort((a, b) => {
                return (new Date(b.updatedAt).getTime()) - (new Date(a.updatedAt).getTime());
            });

            setNewMessage(messages);
        }
    }, [unreadMode]);

    useEffect(() => {

        const newCompanies: Array<{ value: string; label: string; }> = [];
        companies?.map(e => {
            let newOption = {
                value: String(e.id),
                label: e.name,
            }
            newCompanies.push(newOption);
        });

        let result = []
        const selectedCompanies_local = localStorage.getItem("selected-companys");
        if (selectedCompanies_local) {
            result = JSON.parse(selectedCompanies_local);
            setSelectedOptionsCompany(result);
        }
        setOptionsCompany(newCompanies)

    }, [companies]);

    useEffect(() => {

        const filters = companies.filter(company => {
            if (selectedOptionsCompany?.filter(e => e.label == company.name).length) return company;
        })
        let deps: DepartmentItem[] = JSON.parse(localStorage.getItem("selected-deps")!)
        handleSetCompanyAndDepartment();
    }, [selectedOptionsCompany]);

    const getHistory = () => {

        if (historyMode && allChatsHistory.length <= 0) {

            const filtersCompanySelected = companies.filter(company => {
                if (selectedOptionsCompany?.filter(e => e.label == company.name).length) return company;
            });

            let deps: any[] = JSON.parse(localStorage.getItem("selected-deps")!);
            const filtersDeptFromCompany = deps.filter(dept => {
                if (filtersCompanySelected?.filter(e => e.id == dept.id_company).length) return dept;
            });

            historyService.getChatsHistory(filtersCompanySelected, filtersDeptFromCompany, inputField).then(response => {
                setFilteredChatHistory(response);
                setAllChatsHistory(response);
            }).catch(err => {
                toast.error('Ocorreu um erro ao obter os atendimentos já finalidados. Por gentileza, tente novamente.');
            })
        } else if (historyMode && allChatsHistory.length > 0) {

            let filteredChats = allChatsHistory.filter(element => {
                if (inputField != '' &&
                    (element.phone.toLowerCase().includes(inputField.toLowerCase()) || element.name.toLowerCase().includes(inputField.toLowerCase()))
                ) {
                    return element
                }
            });
            setFilteredChatHistory(filteredChats);

        } else {
            setFilteredChatHistory([]);
            setAllChatsHistory([]);
        }
    }

    const handleSetCompanyAndDepartment = () => {
        if (companies.length > 0) {

            const filtersCompanySelected = companies.filter(company => {
                if (selectedOptionsCompany?.filter(e => e.label == company.name).length) return company;
            });

            if (filtersCompanySelected.length == 0) {
                dispatch(setSelectedCompanies([], []));
                return;
            }

            departmentService.getRelationUserDepartments().then(response => {
                if (typeof response == "boolean") return

                if (!historyMode) {
                    dispatch(setSelectedCompanies(filtersCompanySelected, response));
                } else {
                    setInputField("");
                    getHistory();
                }
            });
        }
    }

    useEffect(() => {
        let newMessages = allChats.filter(element => {
            if (inputField != '' &&
                (element.phone.toLowerCase().includes(inputField.toLowerCase()) || element.name.toLowerCase().includes(inputField.toLowerCase()))
            ) {
                return element
            }
        });
        setFilteredMessages([...newMessages])
    }, [allChats, inputField]);



    const handleCompanySelect = (newValue: MultiValue<{ value: string; label: string; }>, actionMeta: ActionMeta<any>) => {
        switch (actionMeta.action) {
            case 'select-option':
                let newSelecteds = selectedOptionsCompany?.map(e => e) || [];
                newSelecteds.push(actionMeta.option)
                setSelectedOptionsCompany([...newSelecteds]);
                localStorage.setItem("selected-companys", JSON.stringify([...newSelecteds]));
                localStorage.setItem("selected-deps", JSON.stringify(chat.departments))
                break;

            case 'remove-value':
                let newSelectedsRemove = newValue.filter(e => {
                    return e.value != actionMeta.removedValue.value
                });
                setSelectedOptionsCompany([...newSelectedsRemove]);
                localStorage.setItem("selected-companys", JSON.stringify([...newSelectedsRemove]));
                localStorage.setItem("selected-deps", JSON.stringify(chat.departments))
                break;

            case 'clear':
                setSelectedOptionsCompany([]);
                localStorage.setItem("selected-companys", JSON.stringify([]));
                localStorage.setItem("selected-deps", JSON.stringify([]))
                break;
        }

    };

    const getSelectDefaults = () => {
        let result = undefined;
        const selectedCompanies_local = localStorage.getItem("selected-companys");
        if (selectedCompanies_local) {
            result = JSON.parse(selectedCompanies_local);
        }
        return result;
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            border: '0px solid transparent',
            borderRadius: '25px',
            margin: '5px 10px',
            borderTop: '1px solid var(--border-color)',
            ':hover': {
                borderTop: '1px solid var(--border-color)'
            }
        }),
        multiValue: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: 'var(--background-company)',
            color: 'var(--text-company)',
            borderRadius: 25,
            placeholder: 'var(--text-primary)',

        }),
        multiValueLabel: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--text-company)',
        }),
        multiValueRemove: (provided: any, state: any) => ({
            ...provided,
            ':hover': {
                backgroundColor: 'var(--text-primary)',
                color: 'var(--bar-background)',
                borderRadius: 8,
            }
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--text-company)'
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--text-primary)',
        }),
    }

    return (
        <SidebarChatContext.Provider value={{ inputField, setInputField, sidebarTabSelected, setSidebarTabSelected }} >
            <Select
                isMulti
                defaultValue={getSelectDefaults()}
                options={optionsCompany}
                value={selectedOptionsCompany}
                styles={customStyles}
                placeholder={'Selecione a empresa'}
                noOptionsMessage={() => 'Nenhuma opção disponível'}
                onChange={(newValue, actionMeta) => handleCompanySelect(newValue, actionMeta)}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--background-company)',
                        primary: 'var(--bar-background)',
                        neutral0: 'var(--bar-background)'
                    },
                })}
            />
            <SidebarHistoryContext.Provider value={{ historyMode, unreadMode, setHistoryMode, setUnreadMode }} >
                <SidebarSearch companiesSelected={chat.companysSelect} isLoading={isLoading} />
                {!historyMode && selectedOptionsCompany?.length ? <SidebarTabPanel inAttendanceCount={inAttendanceCountValue} noAttendantCount={noAttendantCountValue} inGeneralAttendanceCount={inGeneralAttendanceCountValue} /> : <></>}
                <SidebarListChats allChatsHistory={allChatsHistory} filteredChatsHistory={filteredChatsHistory} newMessages={newMessages} filteredMessages={filteredMessages} />
            </SidebarHistoryContext.Provider>
        </SidebarChatContext.Provider>
    );
});

export default SidebarChat;
