import { ErrorMessage, Input, InputWrapper, Label } from "./styles";

interface FormTextInputProps {
  label: string;
  placeholder: string;
  register: Function
  errors: any
  name: string
  required?: boolean
  defaultValue?: string
}

const FormTextInput: React.FC<FormTextInputProps> = ({ required, label, placeholder, register, errors, name, defaultValue }) => {
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <Input
        type="text"
        placeholder={placeholder}
        defaultValue={defaultValue || ''}
        {...register(name, {
          required: {
            value: required,
            message: "Campo necessário"
          }
        })}
      />
      {errors && <ErrorMessage>{errors.message}</ErrorMessage>}
    </InputWrapper>
  );
}

export default FormTextInput;
