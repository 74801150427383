import { FC, useState, useEffect } from 'react';
import Select, {MultiValue,ActionMeta} from 'react-select';

interface OptionsInterface {
  label: string;
  value: string;
};

interface Props {
  options?: OptionsInterface[];
  placeholder?: string;
  defaultValue?: OptionsInterface[] ;
  selectValue?: OptionsInterface[] | undefined;
  onSelected?: (selectedOptions: OptionsInterface[]) => void;
};

/**
 * @description Componente de Select com opções multiplas
 * @description onSelected tem o mesmo comportamento do onChange do input
 */
const CustomSelect = ({
  options = [],
  placeholder = 'Selecione uma opção',
  defaultValue = [],
  onSelected,
}: Props) => {
  const [ selectedOptions, setSelectedOptions ] = useState<OptionsInterface[]>(defaultValue||[]);

  const handleOptionSelected = (newValue: MultiValue<{ value: string; label: string; }>, actionMeta:ActionMeta<any>) => {
    switch( actionMeta.action ){
      case 'select-option': 
        let newSelecteds = selectedOptions.map(e => e) || [];
        newSelecteds.push(actionMeta.option);

        setSelectedOptions( [...newSelecteds] );
      break;

      case 'remove-value':
        let newSelectedsRemove = newValue.filter( e => {
            return e.value != actionMeta.removedValue.value
        });

        setSelectedOptions( [...newSelectedsRemove] ); 
        break;
  
      case 'clear': 
        setSelectedOptions([]); 
        break;
    }
    
  };

  const customStyles = {
    control: (provided:any, state:any) => ({
      ...provided,
        border: '0px solid transparent',
        borderRadius:'25px',
        // margin: '5px 10px',
        borderTop:'1px solid var(--border-color)',
        ':hover': {
            borderTop:'1px solid var(--border-color)'
        }
    }),
    multiValue: (provided:any, state:any) => ({
        ...provided,
        backgroundColor: 'var(--background-company)',
        color: 'var(--text-company)',
        borderRadius:25,
        placeholder:'var(--text-primary)',
        
    }),
    multiValueLabel: (provided:any, state:any) => ({
        ...provided,
        color: 'var(--text-company)',
    }),
    multiValueRemove: (provided:any, state:any) => ({
        ...provided,
        ':hover': {
            backgroundColor: 'var(--text-primary)',
            color: 'var(--bar-background)',
            borderRadius:8,
        }
    }),
    option: (provided:any, state:any) => ({
        ...provided,
        color:'var(--text-primary)',
        ':hover':{
          color:'#fff'
        }
    }),
    input: (provided:any, state:any) => ({
        ...provided,
        color:'var(--text-primary)',
    }),
  };

  useEffect(() => {
    if (onSelected) {
      onSelected(selectedOptions);
    }
  }, [selectedOptions]);


  return (
    <Select 
      isMulti
      options={options}
      value={ defaultValue.length ? defaultValue : selectedOptions }
      styles={customStyles}
      placeholder={placeholder}
      noOptionsMessage={ () => 'Nenhuma opção disponível'}
      onChange={ (newValue,actionMeta) => handleOptionSelected(newValue,actionMeta)}
      theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'var(--background-company)',
            primary: 'var(--bar-background)',
            neutral0: 'var(--bar-background)'
          },
        })}
      />
  );
};

export default CustomSelect;