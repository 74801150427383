import styled from "styled-components";

export const ScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding-left: 1rem;
  flex-direction: row;
  flex: 9;
  background-color: var(--background-admin-default);
  align-items: center;
`
