import { Dialog } from "@mui/material";
import styled, { keyframes } from 'styled-components'

interface IHistoryMode{
    isActive:boolean,
}
export const SidebarSearchContainer = styled.div`
    margin: 0;
    width: 100%;
    background-color: var(--bar-background);
    border-top:1px solid var(--border-color);
    border-bottom:1px solid var(--border-color);   
`;

export const SidebarSearchRow = styled.div`
    padding:10px 0px 10px 10px;
    position:relative;
    display:flex;

`;

export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: #0f0f0f2b;
  }
`;

export const ModalButtonRow = styled.div`
  display:flex;
`;

export const ModalButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: #c8c8c1;
  border-radius: 6px;
  cursor:pointer;
  margin-right:15px;
`;

export const ModalTitle = styled.h3`
  margin:0px;
`;


export const WrapperContainerSelect = styled.div`

    background-color: #e6e6e6;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 10px;
`;

export const WrapperSelectWebhook = styled.div`
  display:flex;
  flex-direction: column;
`;

export const LabelSelectWebhook = styled.label`
  margin-bottom:10px;
`;

export const SelectWebhook = styled.select`
  min-width: 200px;
  border: 1px solid #cccbcb;
  background-color: #e4e4e4;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;

`;

export const SidebarSearchInput = styled.input`
    border: none;
    border-radius: 20px;
    width: 100%;
    flex:10;
    height: 15px;
    padding: 15px;
    padding-left:35px;
    box-sizing: border-box;
    color: #696969;
    background-color:var(--input-background);
    color:var(--text-primary);
    outline: none;
`;

export const  SidebarSearchIcon = styled.div`
    position:absolute;
    top:17px;
    left:22px;
    color: var(--icon-color);
`;

export const SidebarHistoryIcon = styled.div<IHistoryMode>`
    display:flex;
    align-items:center;
    justify-content:center;
    gap: 4px;
    padding-left:5px;
    padding-right:10px;
    cursor:pointer;
    color:${
        props => props.isActive ? 'var(--icon-active)' : 'var(--icon-color)'
    }
`;

export const SidebarOptionsIcon = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    gap: 4px;
    padding-left:5px;
    padding-right:10px;
    cursor:pointer;
    color:var(--icon-color)
`;

export const spinner = keyframes`
  from{
    -webkit-transform: rotate(360deg);
  }
  to{
    -webkit-transform: rotate(0deg);
  }
`;

export const Circle = styled.div`
  display:flex;
  align-items:center;
  -webkit-animation: ${spinner} 1s linear infinite;

`;