import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userService from "../../../../service/userService";
import BigPlusButton from "../../Buttons/BigPlusButton";
import ListUserCard from "../ListUserCard";
import { TitleContainer, UsersContainer, Wrapper } from "./styles";
import { AppBar, Box, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { CreateButton } from "../../PredefinedMessagesManager/styles";
import { FaPlus } from "react-icons/fa";
import { useMainContext } from "../../../../Contexts/Contexts";
import { Header } from "../../Webhook/ListWebhook/styles";
import { string } from "zod";


const ListUsers: React.FC = () => {

  const [usersState, setUsersState] = useState<any[]>([]);

  const nav = useNavigate();

  const handlePlusClick = useCallback(() => {
    nav('/admin/edit-user/new')
  }, [nav]);

  useEffect(() => {
    userService.listAllUsers().then(users => {
      setUsersState(users)
    })
  }, [])
  const Contexts = useMainContext();

  const openModal = (id: string) => {
    Contexts.setId(id);
    Contexts.setEditWebhookOpen(true);
    nav(`/admin/edit-user/${id}`)
  }

  return (
    <Wrapper>

      <UsersContainer>
        <Header>
          <Box sx={{ display: 'flex' }}>
            <AppBar position="static" sx={{ backgroundColor: 'var(--background-company)', padding: '0.2rem', paddingTop: '0' }} >
              <Toolbar sx={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                  <h2> Usuários</h2>
                </Typography>

                <div style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center', height: '40%' }}>
                  <CreateButton onClick={() => {
                    openModal("NEW")
                  }} endIcon={<FaPlus />} ><h4>Cadastrar novo</h4></CreateButton>
                </div>

              </Toolbar>
            </AppBar>
          </Box>
        </Header>

        <TableContainer component={Paper} sx={{ width: '95%', alignContent: 'center', alignSelf: 'center', marginTop: '2rem', color: 'var(--text-primary)', borderRadius: '0' }}>
          <Table sx={{ minWidth: 350, backgroundColor: 'var(--wrapper-input-admin-background)' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: 'var(--text-primary)' }}>Nome</TableCell>
                <TableCell align="right" sx={{ color: 'var(--text-primary)' }}>Admin</TableCell>
                <TableCell align="right" sx={{ color: 'var(--text-primary)', width: "13%" }}>Ações</TableCell>
              </TableRow>
            </TableHead>
            {usersState.map(user => (
              <ListUserCard key={user.id} user={user} />
            ))}
          </Table>
        </TableContainer>

      </UsersContainer>
    </Wrapper>
  );
}

export default ListUsers;
