import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    -webkit-font-smoothing: antialiased;
    height: 98vh;
    min-height: 98vh;
    max-height: 98vh;
    block-size: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-secondary);
    -webkit-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    color: var(--text-primary);
    overflow: visible;
    
    input {
      color: var(--text-primary);
    }

    @media (max-width: 768px) {
      position: fixed;
      top: 72px;
      height: calc(100vh - 72px);
      width: 96vw;
    }
`;

export const TitleContainer = styled.span`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: xx-large;
`;

export const DepartamentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  border-radius: 0px 0px 16px 16px;
  align-items: center;
  justify-content: center;
  margin-top: 1.9rem;
  gap: 1rem;
  overflow-y: scroll;
`;
