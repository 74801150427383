import { alpha, Button, InputBase, styled } from "@mui/material";
import styledComponents from "styled-components";

export const ContainerPredefinedMessagesManager = styledComponents.div`
    display: flex;
    width: 100%;
    margin: 1.2rem;
    max-width: 1800px;
    height: 98vh;
    flex-direction:column;
    background: #fff;
    -webkit-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    @media (max-width: 768px) {
      position: fixed;
      top: 72px;
      height: calc(100vh - 72px);
      width: 96vw;
    }
`;


export const WrapperContainer = styledComponents.div`
    display:flex;
    justify-content:center;
    border:1xp solid outline;
    flex-direction:column;
`;

export const WrapperForm = styledComponents.div`
    display:flex;
    justify-content:space-between;
    flex-direction:column;
    align-items:space-between;
`;

export const StyleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'var(--background)',
  border: '1px solid #f1f1f1',
  boxShadow: 24,
  outline: 'none',
  p: 4,
};

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


export const CreateButton = styled(Button)({
  backgroundColor: 'var(--btn-registerNew)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'var(--btn-registerNew-hover)',
  }
});

export const WrapperHeader = styledComponents.div`
    margin-bottom:1.2rem;
`;