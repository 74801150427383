import styled, { keyframes } from 'styled-components'
import { Dialog } from "@mui/material";

export const Header = styled.div`
    display: flex;
    align-items:center;
    padding-left:10px;
    min-height: 60px;
    background-color:var(--bar-background);
    width: 100%;
    box-sizing:border-box;
`;

export const ProfileLinker = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0.6rem 0 0.2rem;
    border-radius: 22px;
    transition: .2s;
    &:hover {
        cursor: pointer;
        background-color: var(--profile-marker);
        img {
            transition: .4s;
        }
    }
`;

interface Hover {
  isHovered: boolean
}

export const ProfileWrapper = styled.div<Hover>`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-Items: center;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    transition: .2s;
    background: ${props => props.isHovered ? "var(--profile-img-marker)" : "none"}
`;

export const IconHeader = styled.div`
  position: relative;

  display:flex;
  border-radius:100%;
 
`;

export const UserName = styled.div`
    font-weight: 700;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var( --text-primary);
    display: flex;
    align-items:center;
    margin-left:10px;
`;

export const CompanyName = styled.div`
  font-weight:100;
  font-size: 12px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var( --text-secondary);
  display: flex;
  align-items:center;
  margin-left:6px;
`;

export const ChatHeaderChangeAgent = styled.img`
  color: var(--text-primary);
`;

export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: #0f0f0f2b;
  }
`;

export const ChatHeaderItemImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 100%;
`;

export const ChatHeaderItemEmployeeImage = styled.img`
    max-width:30px;
`;
export const ChatHeaderBorderItemEmployeeImage = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    max-width:30px;
`;

export const ChatWrapperItemEmployeeImage = styled.div`
    display:flex;
    justify-content:flex-end;
    flex:1;
`;


export const ChatItemClose = styled.div`
  padding-right:20px;
  color:var(--text-primary);
  font-size:11px;
`;

export const ChatItemCloseButton = styled.div``;


export const ModalButtonRow = styled.div`
  display:flex;
`;

export const ModalButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: #c8c8c1;
  border-radius: 6px;
  cursor:pointer;
  margin-right:15px;
`;

export const ModalTitle = styled.h3`
  margin:0px;
`

export const InfoRow = styled.div`
  display:flex;
  flex-direction:column;
`;
export const InfoRowDetails = styled.div`
  display:flex;
`;
export const Number = styled.div`
  font-weight:100;
  font-size: 12px;
  color: var( --text-secondary);
  display: flex;
  margin-left: 10px;
`;

export const SubHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperSelectWebhook = styled.div`
  display:flex;
  flex-direction: column;
`;

export const WrapperContainerSelect = styled.div`

    background-color: #e6e6e6;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 10px;
`;

export const LabelSelectWebhook = styled.label`
  margin-bottom:10px;
`;

export const LabelTicketInformation = styled.label`
  margin-top:10px;
`;

export const SelectWebhook = styled.select`
  min-width: 200px;
  border: 1px solid #cccbcb;
  background-color: #e4e4e4;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;

`;


export const spinner = keyframes`
  from{
    -webkit-transform: rotate(360deg);
  }
  to{
    -webkit-transform: rotate(0deg);
  }
`;

export const Circle = styled.div`
  display:flex;
  align-items:center;
  -webkit-animation: ${spinner} 1s linear infinite;

`;

export const ContainerBackButton = styled.div`
    @media (max-width: 768px) {
      display: flex;
    }

    @media (min-width: 768px) {
      display: none;
    }
`;

export const Separator = styled.hr`
`;