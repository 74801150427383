import React, { ChangeEvent, useEffect, useState } from 'react';

import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import { FaSearch, FaSpinner } from 'react-icons/fa';
import { ImHistory } from 'react-icons/im';
import Modal from 'react-modal';
import configService from '../../service/configService';
import { UserRole } from '../../util/enums';
import { useFilter, useHistory } from '../SidebarChat/sidebarChatContext';
import * as CS from './styles';

import { BiMessageError } from "react-icons/bi";
import { BsFilter, BsThreeDotsVertical } from "react-icons/bs";
import { CompanyItem } from '../../models/redux-models';
import departmentService from '../../service/departmentService';
import userService from '../../service/userService';

const SidebarSearch: React.FC<{ companiesSelected: CompanyItem[], isLoading: boolean }> = props => {

  const { inputField, setInputField } = useFilter();
  const { historyMode, setHistoryMode, unreadMode, setUnreadMode } = useHistory();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const [optionsUser, setOptionsUser] = useState<Array<{ name: string; id: string; }>>([]);
  const [optionsDepartment, setOptionsDepartment] = useState<Array<{ name: string; id: string; }>>([]);
  const [departmentSelected, setDepartmentSelected] = useState<string>("");
  const [userSelected, setUserSelected] = useState<string>("");


  const toggleOpenDialog = async () => {
    setOpenDialog(!openDialog);
  };

  const handleClose = () => {
    setOpenFilterDialog(false)
  }

  // useEffect(() => {

  //   props.companiesSelected.map((company) => {
  //     departmentService.getDepartmentByCompanyID(company?.id ?? "").then(res => {
  //       setOptionsDepartment(res.push(optionsDepartment));
  //     })
  //     userService.listUserByCompanyID(company?.id??"").then(res => {
  //       // console.log(res);
  //     });
  //   });

  // }, [props.companiesSelected]);

  useEffect(() => {
    if (openFilterDialog) {
      // departmentService.getDepartmentByCompanyID(18).then(res => {
      //     setOptionsDepartment(res);
      // })
      // userService.listUserByCompanyID(18).then(res => {
      //     setOptionsUser(res);
      // });

      let selectedCompanies = [];
      const selectedCompanys_local = localStorage.getItem("selected-companys");

      if (selectedCompanys_local) {
        selectedCompanies = JSON.parse(selectedCompanys_local);
      }

      selectedCompanies.map((company: any) => {

        departmentService.getDepartmentByCompanyID(company.value).then(res => {
          if (typeof optionsDepartment === "object" && optionsDepartment.length > 0) {
            setOptionsDepartment(res.push(optionsDepartment));
          } else {
            setOptionsDepartment(res);
          }
        });

        userService.listUserByCompanyID(company.value).then(res => {
          if (typeof optionsUser === "object" && optionsUser.length > 0) {
            setOptionsUser(res.push(optionsUser));
          } else {
            setOptionsUser(res);
          }
        });
      });
    }

  }, [openFilterDialog]);

  const handleChangeUserSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setUserSelected(e.target.value);
  };

  const handleChangeDepartmentSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setDepartmentSelected(e.target.value);
  };

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '400px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'var(--background)',
      border: '1px solid var(--background)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
    }
  };

  const handleClickFilter = () => {
    setOpenFilterDialog(!openFilterDialog)
    handleCloseMenu();
  }

  const handleClickSetHistoryMode = async () => {
    // props.isLoading = true;

    let companies: any[] = JSON.parse(localStorage.getItem("selected-companys")!);

    if (historyMode || !companies || companies.length <= 0) {
      setHistoryMode(!historyMode);
      return;
    }

    let verifyIfIsAdminToAccessHistory = false;

    const companiesIdList = companies.map(c => c.value);

    await configService.findConfigByKeyAndCompaniesList(companiesIdList, "verifyIfIsAdminToAccessHistory").then(configResponse => {

      if (typeof configResponse !== "boolean") {
        verifyIfIsAdminToAccessHistory = configResponse.some(item => {
          const configValue = JSON.parse(item.config_value);
          return configValue.verifyIfIsAdminToAccessHistory === true;
        });
      }

    }).catch(error => { console.log(error) }
    );


    if (verifyIfIsAdminToAccessHistory) {

      const role = localStorage.getItem("role");
      if (role === UserRole.master.toString() || role === UserRole.admin.toString()) {
        setHistoryMode(!historyMode);

      } else {
        toast.error("Sua empresa permite o acesso do histórico de conversas apenas para administradores.");
      }

    } else {
      setHistoryMode(!historyMode);
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <CS.SidebarSearchContainer>
      <CS.SidebarSearchRow>
        <CS.SidebarSearchIcon>
          <FaSearch />
        </CS.SidebarSearchIcon>
        <CS.SidebarSearchInput
          type="text"
          placeholder='Pesquise por uma conversa'
          value={inputField}
          onChange={(e) => setInputField(e.target.value)}
        />

        {/* <Tooltip title="Opções">
          <CS.SidebarOptionsIcon>
            <IconButton onClick={handleOpenMenu} color="inherit">
              <BsThreeDotsVertical color="var(--icon-color)" />
            </IconButton>
          </CS.SidebarOptionsIcon>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: localStorage.getItem('theme') === 'dark' ? '#212c34' : '#eeeeef',
            },
          }}
        >
          <MenuItem
            disabled={props.isLoading}
            onClick={() => handleClickSetHistoryMode()}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: historyMode ? 'var(--icon-active)' : localStorage.getItem('theme') == 'dark' ? 'white' : '#696969' }}
          >
            <span style={{ fontSize: '14px' }}>
              Abrir histórico
            </span>
            <ImHistory />
          </MenuItem>

          <MenuItem
            onClick={() => setUnreadMode(!unreadMode)}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: unreadMode ? 'var(--icon-active)' : localStorage.getItem('theme') == 'dark' ? 'white' : '#696969' }}
          >
            <span style={{ fontSize: '14px' }}>
              Mostrar mensagens não lidas
            </span>
            <BiMessageError style={{ paddingLeft: '1vh' }} />
          </MenuItem>

          <MenuItem
            onClick={handleClickFilter}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: localStorage.getItem('theme') == 'dark' ? 'white' : '#696969' }}
          >
            <span style={{ fontSize: '14px' }}>
              Filtrar atendimentos
            </span>
            <BsFilter style={{ paddingLeft: '1vh' }} />
          </MenuItem>

        </Menu> */}

        <Tooltip title={historyMode ? "Fechar histórico" : "Abrir histórico"}>
          <CS.SidebarHistoryIcon isActive={historyMode}>
            <ImHistory onClick={() => handleClickSetHistoryMode()} />
          </CS.SidebarHistoryIcon>
        </Tooltip>

        <Tooltip title={unreadMode ? "Mostrar todas as mensagens" : "Mostrar mensagens não lidas"}>
          <CS.SidebarHistoryIcon isActive={unreadMode}>
            <BsFilter onClick={() => setUnreadMode(!unreadMode)} />
          </CS.SidebarHistoryIcon>
        </Tooltip>

        <Modal
          isOpen={openFilterDialog}
          shouldCloseOnOverlayClick={true}
          contentLabel="Modal"
          style={customStyles} >
          <CS.ModalTitle>Filtrar atendimento</CS.ModalTitle>

          <CS.WrapperContainerSelect>
            <CS.WrapperSelectWebhook>
              <CS.LabelSelectWebhook><strong>Filtrar por atendente</strong></CS.LabelSelectWebhook>
              <CS.SelectWebhook
                disabled={!optionsUser}
                key="selectUserInput"
                onChange={e => handleChangeUserSelect(e)}>
                <option value="invalid">--</option>
                {optionsUser && optionsUser.length > 0 ?

                  optionsUser.map(user => {
                    return <option key={user.id} value={user.id}>{user.name}</option>
                  })

                  : ""}
              </CS.SelectWebhook>
            </CS.WrapperSelectWebhook>

            <CS.WrapperSelectWebhook>
              <CS.LabelSelectWebhook><strong>Filtrar por departamento</strong></CS.LabelSelectWebhook>
              <CS.SelectWebhook
                disabled={!optionsDepartment}
                key="selectDepartmentInputFilter"
                onChange={e => handleChangeDepartmentSelect(e)}>
                <option value="invalid">--</option>
                {optionsDepartment && optionsDepartment.length > 0 ?

                  optionsDepartment.map(department => {
                    return <option key={department.id} value={department.id}>{department.name}</option>
                  })

                  : ""}
              </CS.SelectWebhook>
            </CS.WrapperSelectWebhook>
          </CS.WrapperContainerSelect>

          <CS.ModalButtonRow>
            <CS.ModalButton className='activeButtonModal' onClick={() => console.log('filtrar')} disabled={false} >Filtrar</CS.ModalButton>
            <CS.ModalButton onClick={() => { setOpenFilterDialog(!openFilterDialog) }} >Cancelar</CS.ModalButton>
            {false ? <CS.Circle><FaSpinner /></CS.Circle> : ''}
          </CS.ModalButtonRow>

        </Modal>

      </CS.SidebarSearchRow>

    </CS.SidebarSearchContainer>
  );
};

export default SidebarSearch;
