import styled from "styled-components";

export const CardWrapper = styled.div`
  display: flex;
  width: 85%;
  height: 2rem;
  background-color: #EAF1E8;
  color:#333;
  padding-left: 4px;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 2px;
  padding: 4px;
  width: 3rem;
`;

export const ModalButtonRow = styled.div`
  display:flex;
`;

export const ModalButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: #c8c8c1;
  border-radius: 6px;
  cursor:pointer;
  margin-right:15px;
`;

export const ModalTitle = styled.h3`
  margin:0px;
`;
