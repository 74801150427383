import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 4rem;
  justify-content: space-between;
`;


export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  width:100%;
`;

export const CardWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width:80%;
    min-height:10rem;
    border-radius:4px;
    background-color: var(--background-secondary);

`;

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 2rem 0;

`;

export const WrapperAddWebhook = styled.div`
  display:flex;
  justify-content:flex-end;
  font-size:xx-large;
`;

export const Header = styled.header`
       width: 100%;
       padding: 0.5rem auto;
       img{
        border-radius: 50%;
       }
`;