import axios from 'axios'
import { IMessage } from '../interface/IModels';
import { ChatItemModel, CompanyItem, DepartmentItem } from '../models/redux-models';


const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'chat/' || "";


export default {
    async getChatsHistory( companys:CompanyItem[], departments:DepartmentItem[],filter:string ) {

        const tokenJWT = localStorage.getItem("auth");

        let valuePromisse:ChatItemModel[] = [];
  
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 
        let companyIDs = companys.map( e => e.id);
        let departmentIDs = departments.map( e => e.id);
  
        let url = baseUrl + "getChatsHistoryV2.php";

        return await axios.post<ChatItemModel[]>(url,
            {companys:companyIDs, departments:departmentIDs ,filter: filter },{ headers }
        ).then( response  => {
            valuePromisse = response.data;
            return valuePromisse;
        }).catch( err => {
            console.log(err)
            return valuePromisse;
        });
  
    },
    async getMessagesHistory( chat_id:number) {

        const tokenJWT = localStorage.getItem("auth");
        
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 
        const url = baseUrl + "getMessagesHistory.php"; 

        return await axios.post<IMessage[]>(url,
            { chat_id: chat_id },{ headers }
        ).then( response  => {
            return response.data;
        }).catch( err => {
            console.log(err)
            return false;
        });
  
        
    }
}