import { Outlet, Navigate } from "react-router-dom";
import { UserRole } from "../../util/enums";

const ProtectedRoute = () => {

    const isAuth = !!localStorage.getItem("auth");

    return isAuth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;

export const AdminProtectedRoute = () => {
    const role = localStorage.getItem("role");

    if (role === UserRole.master.toString() || role === UserRole.admin.toString()) {
        return <Outlet />;
    }

    return <Navigate to="/" />;
    // return isAuth && role ? <Outlet /> : <Navigate to="/" />;
};
