import { FileUpload, IMessage } from "../../interface/IModels";


export const SET_MESSAGE = "messageType/SET_MESSAGE";

export interface SetMessageAction {
  type: typeof SET_MESSAGE;
  message: IMessage,
  phone: string,
  fileUpload?: FileUpload,
}

export type MessageAction =
  SetMessageAction;