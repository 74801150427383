import axios from 'axios'

export interface ILogoutResponse {
  success:boolean,
  message:string
}

export default {
    async logout( userId: string) {

        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";
        
        const url = baseUrl+'/logoutAgent.php';

        return await axios.post<ILogoutResponse>(url,
        { userId },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }).then( request  => 
        {
          return Boolean(request.data.success); 
        }).catch( err => {
          console.log(err);
          return false;
        });
    }
}