import styled from "styled-components";

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  background-color: var(--background-sidebar-item);
  flex-direction: column;
  border-right: 1px solid var(--border-color);
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: auto;
  padding-top: 1rem;
`;
