import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { TitleContainer, Wrapper, NewDepForm, InputsContainer, StyledDialog, InputDiv } from "./styles";
import departmentService, { IDepartmentEdit } from "../../../../service/departmentService";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Dialog, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useMainContext } from "../../../../Contexts/Contexts";
import userService, { IRelationCompany } from "../../../../service/userService";
import React from "react";
import { Check, Close } from "@mui/icons-material";
import { IoClose } from "react-icons/io5";
import { ErrorSpan } from "../NewDepartament/styles";

export const EditDepartament: React.FC = () => {

  interface FormItens {
    name: string;
    initials: string;
    selectedOptionCompany: string;
  }

  const Contexts = useMainContext();

  const [actualDepartament, setActualDepartament] = useState<IDepartmentEdit | null>(null)

  const [optionsCompany, setOptionsCompany] = useState<Array<{ value: string; label: string; }>>([]);
  const [selectedOptionCompany, setSelectedOptionCompany] = useState<string>();

  const companys = useAppSelector(state => state.chat.companys);
  const [companies, setCompanies] = useState<IRelationCompany[]>([]);
  const [companySelected, setCompanySelected] = useState<number>(0);
  const [departments, setDepartments] = useState<{ id: number, value: string }[]>([]);
  const [open, setOpen] = React.useState(false);
  const [companyName, setCompanyName] = React.useState<string>();

  const searchDepartmentsByCompany = async (id: number) => {
    departmentService.getDepartmentByCompanyID(id).then(
      result => {

        if (!result) return;

        const departmentsOptionsFormatted = result.map((dep: { name: string; id: string; }) => {
          return { value: dep.name, id: parseInt(dep.id) }
        });

        setDepartments(departmentsOptionsFormatted);

      }
    );
  }

  useEffect(() => {

    userService.getRelationUserCompany().then(response => {


      const companies = response;
      const allCompanies = companies.map(company => company);
      setCompanies(allCompanies)


      if (response.length > 0) {

        const companySelect = parseInt(response[0].id_company);
        setCompanySelected(companySelect);

        searchDepartmentsByCompany(companySelect);
      }
    });
  }, []);

  useEffect(() => {

    departmentService.getDepartmentByID(parseInt(Contexts.id!) || 0)
      .then(result => {
        if (typeof result !== "boolean") {
          setActualDepartament(result);
          setCompanyName(result.idCompany.toString());
        }
      })
  }, [Contexts.id])

  const handleChangeCompanySelect = (event: SelectChangeEvent<typeof companyName>) => {
    const {
      target: { value },
    } = event;
    setCompanyName(value);
    setSelectedOptionCompany(value);
  };

  const onSubmit = (data: FormItens) => {
    try {
      departmentService.updateDepartament({
        id_company: companyName as unknown as number,
        initials: data.initials,
        name: data.name,
        id: parseInt(Contexts.id!)
      }).then(result => {

        Contexts.setUpdateDepartmentHandle(true)
        if (!result) {
          Contexts.setPopupModalErrorOpen(true)
          Contexts.setPopupModalErrorText('O nome utilizado já existe!');
        } else {
          Contexts.setPopupModalText('Departamento alterado!');
          Contexts.setPopupModalOpen(true)
        }
      }
      )
    } catch (error) {
      Contexts.setPopupModalErrorText('Parece que ocorreu algum erro no servidor, poderia tentar novamente?');
      Contexts.setPopupModalErrorOpen(true);
    }
    handleClose()
  }

  useEffect(() => {

    const newCompanys: Array<{ value: string; label: string; }> = [];

    companies.forEach(e => {
      let newOption = {
        value: String(e.id_company),
        label: e.name_company,
      }
      newCompanys.push(newOption);
    })

    // setSelectedOptionCompany(newCompanys);
    setOptionsCompany(newCompanys)


  }, [companies]);

  const nav = useNavigate();

  const handleClose = () => {
    nav('/admin/departaments')
    Contexts.setModalOpen(false)
  };

  useEffect(() => {
    if (!actualDepartament) return
    setValue('name', actualDepartament.name)
    { actualDepartament.initials ? setValue('initials', actualDepartament.initials) : setValue('initials', actualDepartament.name) }

  }, [actualDepartament])

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormItens>({
  });
  const theme = localStorage.getItem('theme');

  return (
    <StyledDialog open={Contexts.modalOpen} onClose={handleClose} sx={{ backdropFilter: 'none' }} data-theme={theme ? theme : 'light'}>

      <Wrapper>
        <TitleContainer>
          Editar Departamento
        </TitleContainer>
        <NewDepForm>
          <InputsContainer>
            {/*<FormTextInput
            label="Nome"
            placeholder="Nome da empresa"
            register={register}
            errors={errors.name}
            name='name'
            defaultValue={actualDepartament? actualDepartament.name : ''}
          />*/}
            <InputDiv>
              <TextField
                key="nameEditInput"
                autoFocus
                margin="dense"
                id="name"
                error={errors.name ? true : false}
                label="Nome"
                placeholder="Sigla da empresa"
                type="text"
                {...register("name", { required: true, maxLength: 150, minLength: 2 })}
                fullWidth
                variant="filled"
                InputLabelProps={{ shrink: true, style: { color: 'var(--text-modal)' } }}
              />
              {errors.name && <ErrorSpan>Este campo precisa de pelo menos dois caracteres!</ErrorSpan>}
            </InputDiv>

            <InputDiv>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                error={errors.initials ? true : false}
                label="Sigla"
                key="initialsEditInput"
                placeholder="Sigla da empresa"
                type="text"
                fullWidth
                {...register("initials", { required: false, maxLength: 15, minLength: 2 })}
                variant="filled"
                InputLabelProps={{ shrink: true, style: { color: 'var(--text-modal)' } }}
              />
              {errors.initials && <ErrorSpan>Este campo precisa de pelo menos dois caracteres!</ErrorSpan>}
            </InputDiv>
            { /* <FormSelectInput
            label="Empresa"
            placeholder="Nome da empresa"
            options={optionsCompany}
            handleOnChange={handleCompanySelect}
        /> */}
            <FormControl sx={{ m: 1, minWidth: 120, width: '100%', alignSelf: 'center', color: 'red' }}>
              <InputLabel id="demo-simple-select-autowidth-label" sx={{ fontSize: '16px', color: 'var(--text-modal)' }} >Selecione a empresa</InputLabel>
              <Select
                key="selectEditInput"
                labelId="demo-simple-select-label"
                id="demo-simple-select-autowidth"
                value={companyName ? companyName : ''}
                onChange={handleChangeCompanySelect}
                autoWidth
                displayEmpty
                input={<OutlinedInput sx={{ color: "red" }} id="demo-simple-select-label" label="Selecione a empresa" />}
                sx={{
                  backgroundColor: 'var(--input-bg-color)', width: '100%', color: "var(--text-modal)", '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                }}
              >

                <MenuItem disabled={true} value="">
                  <em>Selecione a empresa</em>
                </MenuItem>

                {optionsCompany.map(e => (
                  <MenuItem value={e.value}> {e.label} </MenuItem>
                ))}


              </Select>
            </FormControl>

          </InputsContainer>

          <div style={{ display: 'flex', gap: "1rem", padding: '1rem 0', alignSelf: 'right', justifyContent: 'right', width: '100%' }}>
            <Button sx={{ color: 'var(--primary)' }} color="secondary" onClick={handleClose} startIcon={<IoClose />}>Fechar</Button>

            <Button variant="contained" color="success" onClick={handleSubmit(onSubmit)} startIcon={<Check />}>Editar departamento</Button>
          </div>

        </NewDepForm>
      </Wrapper>
    </StyledDialog>
  );
};

export default EditDepartament;
