import styled from "styled-components";

type PageNumberProps = {
  actualPage: boolean
};

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 1rem;
  justify-content: center;
  align-items: center;
`;

export const NavWrapper = styled.div`
  display: flex;
  width: 15%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`

export const PagNumsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

export const PagNumber = styled.span<PageNumberProps>`
  font-size: large;
  color: ${props => props.actualPage ? '#68C16C' : '#111111'};
  transition: 0.2s font-size;
  cursor: pointer;
  &:hover {
    color: #68C16C;
    font-size: x-large;
  }
`

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
`
