import { ArrowBack, Brightness4, Brightness7, CircleTwoTone, Menu as MenuIcon, Message, Settings } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, FormControl, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Modal, OutlinedInput, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import md5 from 'md5';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BsPencil } from 'react-icons/bs';
import { useLocation, useNavigate } from "react-router-dom";
import * as zod from 'zod';
import { useMainContext } from '../../Contexts/Contexts';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { IMessage } from '../../interface/IModels';
import chatService from '../../service/chatService';
import departmentService from '../../service/departmentService';
import ProviderService from '../../service/providerService';
import { getZAPIByID } from '../../service/sendMessage';
import userService from '../../service/userService';
import webhookService from '../../service/webhookService';
import { fetchChats, fetchMessagesHistory } from '../../store/chat-actions';
import { EMessage } from '../../util/enums';
import { adminSideMenuItems } from '../Admin/Sidebar';
import { ChatContext, useChat } from '../Chat/chatContext';
import { DepartmentItems } from '../ChatHeader';
import { ErrorSnackbar } from '../Snackbars/ErrorSnackbar';
import { SuccessSnackbar } from '../Snackbars/SucessSnackbar';
import { MenuItens } from './MenuItens';
import * as C from './styles';
import { ProfileWrapper } from './styles';

interface CompanyItems {
    value: string;
    label: string;
}

const SidebarHeader: React.FC<{ func: (data: string) => void }> = (props) => {

    let prefUser = localStorage.getItem('theme');
    const Contexts = useMainContext();

    const [isDarkTheme, setDarkTheme] = useState<boolean>(prefUser === 'dark');
    const [agentProfilePicture, setAgentProfilePicture] = useState<string>("");
    const [notification, setNotification] = useState<boolean>();
    const [soundNotification, setSoundNotification] = useState<boolean>();
    const [providerStatus, setProviderStatus] = useState<boolean>(true);
    const [isHovered, setIsHovered] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElList, setAnchorElList] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openList = Boolean(anchorElList);
    const sendMessageForm = useForm<sendMessageFormData>({})
    const [companySelected, setCompanySelected] = useState<string>("");
    const [optionsCompany, setOptionsCompany] = useState<Array<{ value: string; label: string; }>>();
    const [zapiRequestUrl, setZapiRequestUrl] = useState<string>("");
    const [optionsDepartment, setOptionsDepartment] = useState<Array<{ name: string; id: string; }>>();
    const [departmentSelected, setDepartmentSelected] = useState<string>("");
    const [clientNumber, setClientNumber] = useState<string>("");
    const { inputField, setInputField, inputFiles, setInputFiles, messageReply, setMessageReply } = useChat();
    const [defaultWebhook, setDefaultWebook] = useState<string | undefined>();
    const [contacts, setContacts] = useState<{ label: string, value: number }[]>([]);
    const [companiesIds, setCompaniesIds] = useState<number[]>([]);
    const [companiesIdsLoaded, setCompaniesIdsLoaded] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const sendMessageFormSchema = zod.object({
        clientNumber: zod.number().min(10, 'Todo número deve ter no mínimo 10 caracteres'),
        clientName: zod.string().nullable().optional(),
        message: zod.string(),
        idCompany: zod.number(),
        idDepartment: zod.number()
    })
    type sendMessageFormData = zod.infer<typeof sendMessageFormSchema>

    const { handleSubmit, register, setValue, formState: { errors } } = sendMessageForm
    const [openModal, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    
    const handleClose = () => {
        if (!isUpdating) {
            setOpen(false);
        }
    };

    const nav = useNavigate();
    const pageRoute = String(useLocation().pathname);
    const agentName = localStorage.getItem("user") || '';
    const ref = useRef(null);
    const theme = localStorage.getItem('theme');

    const dispatch = useAppDispatch();

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeCompanySelect = (event: SelectChangeEvent<string>) => {
        setCompanySelected(event.target.value);

        //departmentGetter
        departmentService.getDepartmentByCompanyID(+event.target.value).then(res => {
            setOptionsDepartment(res);
        })
    };

    useEffect(() => {
        if (companiesIdsLoaded) return;
        userService.getRelationUserCompany().then((res) => {
            setCompaniesIds(res.map((r) => parseInt(r.id_company)));
            setCompaniesIdsLoaded(true);
        }).catch(() => setCompaniesIdsLoaded(true));
    })

    const handleSearch = (search: string) => {
        chatService.listContacts(search, companiesIds).then((res) => {
            const newContacts = res.map((c) => ({
                label: `${c.name ?? 'Sem nome'} - ${c.phone}`,
                value: parseInt(c.phone)
            })).filter(function (value, index, self) {
                return index === self.findIndex((v) => (
                    v.label === value.label && v.value === value.value
                ))
            })
            setContacts(newContacts);
        });
    }

    const setPicture = () => {
        let pictureLocal = localStorage.getItem("picture");
        let email = localStorage.getItem("login") || "";
        let encodingEmail = md5(email);
        let url = "https://www.gravatar.com/avatar/" + encodingEmail;

        if (!pictureLocal) {

            url = "https://www.gravatar.com/avatar/" + encodingEmail + "?s=2500";

            if (encodingEmail) {
                setAgentProfilePicture(url)
            } else {
                setAgentProfilePicture(process.env.PUBLIC_URL + '/user.png');
            }
        } else {
            setAgentProfilePicture(pictureLocal);
            url = pictureLocal;
        }
    }

    const handleChangeDepartmentSelect = (event: SelectChangeEvent<string>) => {
        setDepartmentSelected(event.target.value);
    };

    function formatText(content: string) {
        let nameUser = localStorage.getItem("user");
        let nameFormat = `*${nameUser}*\n`;
        let contentText = content;
        return nameFormat + contentText;
    }

    const verifyIfChatAlreadyOpen = async (clientNumber: string, companyId: number) : Promise<boolean> => {

        let possibleNumbers: string[] = [ clientNumber ];

        // create number with or without leading 9. ex: 55xx9xxxxxxxx, 55xxxxxxxxxx
        if(clientNumber.length === 12 && !['2', '3', '4', '5'].includes(clientNumber[4]))
            possibleNumbers.push(`${clientNumber.slice(0, 4)}9${clientNumber.slice(4)}`);
          else if(clientNumber.length === 13 && !['2', '3', '4', '5'].includes(clientNumber[4]))
            possibleNumbers.push(`${clientNumber.slice(0, 4)}${clientNumber.slice(5)}`);

        return await chatService.verifyIfChatAlreadyOpen(clientNumber, companyId, possibleNumbers);
    }

    const HandleDefaultSubmit = async (data: sendMessageFormData) => {
        setIsUpdating(true);

        if (!data.clientNumber.toString().startsWith("55")) {
            data.clientNumber = parseInt("55" + data.clientNumber.toString());
        }

        if(await verifyIfChatAlreadyOpen(data.clientNumber.toString(), data.idCompany)) {
            setIsUpdating(false);
            return toast.error("Cliente já está em atendimento.");
        }

        const messageData = { message: data.message, phone: data.clientNumber }

        let userName = "";
        if(data.clientName) userName = data.clientName;
        else userName = "Cliente " + data.clientNumber.toString().substring(data.clientNumber.toString().length - 4);

        const createTranshipmentData = { phone: data.clientNumber.toString(), name: userName, companyId: data.idCompany, departmentId: data.idDepartment };

        const getImageUrl = zapiRequestUrl + `profile-picture?phone=${data.clientNumber}`;
        const sendMessageUrl = zapiRequestUrl + `send-text`;

        const existingChat = await chatService.getChatByNumber(data.clientNumber.toString(), data.idCompany.toString());
        let chatId = 0;
        if (existingChat.id == null) {
            const res = await chatService.createChatByApp(createTranshipmentData, getImageUrl);
            dispatch(fetchChats())
            dispatch(fetchMessagesHistory(res.data.id))
            chatId = res.data.id;
        } else {
            dispatch(fetchMessagesHistory(existingChat.id));
            chatId = existingChat.id;
        }

        const message: IMessage = {
            id: 0,
            message: formatText(data.message),
            chat_id: chatId,
            message_time: new Date().getTime().toString(),
            sender: "agent",
            agent_id: Number(localStorage.getItem("id")),
            type_message: EMessage.TEXT,
            whatsapp_reference_message_id: messageReply.content?.whatsapp_message_id??""
        }

        try {
            if (!data.clientNumber.toString().startsWith("55")) {
                data.clientNumber = parseInt(`55${data.clientNumber.toString()}`)
            }

            const webhookDefault = await getWebhookByIdCompany(data.idCompany);
            if(!webhookDefault) {
                setIsUpdating(false);
                Contexts.setPopupModalErrorOpen(true);
                Contexts.setPopupModalErrorText('Ocorreu algum erro ao enviar a mensagem.');
                return;
            }
            const result = await chatService.createMessageAgent(message, data.clientNumber.toString(), undefined, {
                isDirect: true,
                department_id: data.idDepartment,
                // webhookUrl: defaultWebhook ?? ""
                webhookUrl: webhookDefault
            });
            setIsUpdating(false);
            setOpen(false);
            Contexts.setPopupModalOpen(true);
            Contexts.setPopupModalText('Mensagem enviada com sucesso!');
        } catch (e) {
            setIsUpdating(false);
            Contexts.setPopupModalErrorOpen(true);
            Contexts.setPopupModalErrorText('Ocorreu algum erro ao enviar a mensagem.');
        }
    }

    const getWebhookByIdCompany = async (idCompany: number) => {
        let webhook = "";
        await userService.getRelationUserCompany().then(async (res) => {
            await webhookService.listWebhooks(res.map((c) => idCompany.toString())).then(async (res) => {
                const defaultWebhookUrl = res.find((w) => w.name == 'DEFAULT__');
                if (defaultWebhookUrl) {
                    await webhookService.getWebhook(defaultWebhookUrl.id).then((res) => {
                        if (typeof res !== "boolean") {
                            webhook = res.url;
                        }
                    })
                }
            })
        })
        return webhook;
    }

    const handleChange = (event: string) => {
        setClientNumber(event)
    }

    function handleDarkTheme(isDarkThemeP: boolean) {
        setDarkTheme(!isDarkThemeP);
        window.location.reload();
    }

    useEffect(() => {
        setPicture();
        setNotification(localStorage.getItem('notification') === "true" ? true : false);
        setSoundNotification(localStorage.getItem('soundNotification') === "true" ? true : false);

        if (pageRoute.startsWith("/admin") === true) {
            console.log(pageRoute);
        }

    }, [useLocation()]);

    useEffect(() => {
        localStorage.setItem('theme', isDarkTheme ? 'dark' : 'light');
    }, [isDarkTheme]);

    useEffect(() => {
        if (companySelected == "")
            return

        getZAPIByID(+companySelected).then(res => {
            try {
                setZapiRequestUrl(`https://api.z-api.io/instances/${res.id_app}/token/${res.token_app}/`);
            } catch (error) {
                console.error("Erro ao definir url de requisição:", error)
            }

        })

    }, [companySelected]);

    useEffect(() => {
        userService.getRelationUserCompany().then(res => {
            const newData = res.map(item => ({
                value: item.id_company,
                label: item.name_company
            }));
            setOptionsCompany(newData);
        })

        const intervalCall = setInterval(() => {
            ProviderService.getApiStatus(1)
                .then(res => {
                    setProviderStatus(res.connected);
                })
        }, 60000);

        return () => {
            clearInterval(intervalCall);
        };
    }, []);

    useEffect(() => {
        userService.getRelationUserCompany().then((res) => {
            webhookService.listWebhooks(res.map((c) => c.id_company.toString())).then((res) => {
                const defaultWebhookUrl = res.find((w) => w.name == 'DEFAULT__');
                console.log(defaultWebhookUrl)
                if (defaultWebhookUrl) {
                    webhookService.getWebhook(defaultWebhookUrl.id).then((res) => {
                        if (typeof res !== "boolean") {
                            setDefaultWebook(res.url);
                        }
                    })
                }
            })
        })

    }, []);

    return (
        <C.SidebarHeaderContainer data-theme={theme == "dark" ? "dark" : "light"}>
            <ChatContext.Provider value={{ inputField, setInputField, inputFiles, setInputFiles, messageReply, setMessageReply }}>
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: '#005c4b',
                        border: 'var(--bar-background)',
                        boxShadow: 24,
                        color: "#cacaca",
                        p: 4
                    }}>
                        <form onSubmit={handleSubmit(HandleDefaultSubmit)} className="FormEditarUsuario">
                            <h2 style={{ marginTop: "10" }}>Formulário de envio de mensagem</h2>
                            <Autocomplete id="filled-basic"
                                options={contacts ?? []}
                                disabled={isUpdating}
                                freeSolo
                                fullWidth
                                noOptionsText="Nenhum contato encontrado"
                                onInputChange={(_, value) => setValue("clientNumber", parseInt(value))}
                                onChange={(_, value) => {
                                        setValue("clientNumber", (value as any)?.value ?? 0);
                                        const label = (value as any)?.label;
                                        let name = "";
                                        if(label) {
                                            let partes = label.split(' - ');
                                            name = partes.slice(0, -1).join(' - ');
                                        }
                                        setValue("clientName", name);
                                    }
                                }
                                renderInput={(params) => <TextField {...params}
                                    helperText={errors.clientNumber?.message}
                                    InputLabelProps={{ style: { color: '#dddddd' } }}
                                    label="Número ou nome do cliente"
                                    variant="filled"
                                    fullWidth
                                    onChange={(event) => handleSearch(event.target.value)}
                                >
                                </TextField>}
                                sx={{ input: { color: "#fff" }, paddingBottom: "1.2rem" }}
                            />
                            <FormControl required sx={{ minWidth: 130, width: '100%', marginBottom: ".5rem" }}>
                                <div>Selecione a Empresa</div>
                                <Select
                                    key="selectEditInput"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select-autowidth"
                                    value={companySelected}
                                    {...register("idCompany", { required: true })}
                                    onChange={handleChangeCompanySelect}
                                    autoWidth
                                    inputProps={{ style: { color: 'var(--text-modal)' } }}
                                    input={<OutlinedInput id="demo-simple-select-label" label="Selecione a empresa:" />}
                                    sx={{
                                        backgroundColor: 'var(--input-bg-color)', width: '100%', color: "var(--text-modal)", '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                    }}
                                    disabled={isUpdating}
                                >
                                    {optionsCompany ? optionsCompany.map((e: CompanyItems) => (
                                        <MenuItem value={e.value.toString()} key={e.value}> {e.label} </MenuItem>
                                    )) : <MenuItem> <p>Nenhuma empresa cadastrada!</p> </MenuItem>}

                                </Select>
                            </FormControl>
                            <FormControl required sx={{ minWidth: 130, width: '100%', marginBottom: ".5rem" }}>
                                <div>Selecione o departamento</div>
                                <Select
                                    disabled={isUpdating || !optionsDepartment}
                                    key="selectDepartmentInput"
                                    labelId="demo-simple-select-department-label"
                                    id="demo-simple-select-autowidth"
                                    value={departmentSelected}
                                    {...register("idDepartment", { required: true })}
                                    onChange={handleChangeDepartmentSelect}
                                    autoWidth
                                    inputProps={{ style: { color: 'var(--text-modal)' } }}
                                    input={<OutlinedInput id="demo-simple-select-department-label" label="Selecione o departamento:" />}
                                    sx={{
                                        backgroundColor: 'var(--input-bg-color)', width: '100%', color: "var(--text-modal)", '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                    }}
                                >
                                    {optionsDepartment ? optionsDepartment.map((e: DepartmentItems) => (
                                        <MenuItem value={e.id} key={e.id}> {e.name} </MenuItem>
                                    )) : <MenuItem> <p>Por favor, selecione uma empresa!</p> </MenuItem>}
                                </Select>
                            </FormControl>

                            <FormControl required sx={{ minWidth: 130, width: '100%', marginBottom: "1.5rem" }}>
                                <TextField sx={{ width: '100%', color: 'white' }}
                                    id="filled-basic"
                                    multiline
                                    rows={8}
                                    label="Mensagem"
                                    InputLabelProps={{ style: { color: 'white' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                    variant="filled"
                                    {...register("message", { required: true })}
                                    error={Boolean(errors.message)}
                                    helperText={errors.message?.type == "required" ? 'Campo necessário' : ''}
                                    disabled={isUpdating}
                                />
                            </FormControl>

                            <Button sx={{ width: "100%" }} type='submit' variant='contained' color='success' disabled={isUpdating}> 
                                {isUpdating ? <CircularProgress size={24} /> : 'Enviar Mensagem'} 
                            </Button>
                        </form>
                    </Box>
                </Modal>
            </ChatContext.Provider>

            <C.SidebarHeaderRow>
                {pageRoute.startsWith("/admin") && <IconButton
                    sx={{ display: { sm: "inline", md: "none" } }}
                    onClick={(event) => setAnchorElList(event.currentTarget)}
                >
                    <MenuIcon></MenuIcon>
                </IconButton>}
                <Menu
                    anchorEl={anchorElList}
                    open={openList}
                    onClose={() => setAnchorElList(null)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                        style: {
                            background: "var(--menu-background)",
                            color: "var(--input-bg-color)"
                        },
                    }}
                >
                    <MenuList>
                        {adminSideMenuItems.map((item) => <MenuItem key={item.navigateTo} onClick={() => {
                            nav(item.navigateTo);
                            setAnchorElList(null);
                        }}>
                            <ListItemIcon>
                                <>{item.icon({})}</>
                            </ListItemIcon>
                            <ListItemText>
                                {item.text}
                            </ListItemText>
                        </MenuItem>)}
                    </MenuList>

                </Menu>
                <Tooltip title="Alterar perfil">
                    <C.ProfileLinker onClick={() => { nav("/profile") }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
                        <C.SidebarHeaderImage src={agentProfilePicture} />
                        <ProfileWrapper isHovered={isHovered} onMouseEnter={() => setIsHovered(true)} >
                            <BsPencil size={18} style={{ marginLeft: ".75em", color: "var(--icon-color)", opacity: isHovered ? "1" : "0", transition: "0.3s" }} />
                        </ProfileWrapper>
                        <C.SidebarHeaderName>{agentName}</C.SidebarHeaderName>
                    </C.ProfileLinker>
                </Tooltip>
                <C.SidebarHeaderActionsRow>
                    <C.SidebarHeaderActions >
                        <C.ApiStatusWrapper >
                            <Tooltip title={providerStatus ? "Status: Conectado " : "Status: Desconectado"}>
                                <CircleTwoTone sx={{ color: providerStatus ? "green" : "red" }} />
                            </Tooltip>
                        </C.ApiStatusWrapper>
                    </C.SidebarHeaderActions>
                    {defaultWebhook && <C.SidebarHeaderActions>
                        <C.ApiStatusWrapper>
                            <Tooltip title={"Enviar mensagem"}>
                                <Message onClick={handleOpen} sx={{ cursor: "pointer" }} />
                            </Tooltip>
                        </C.ApiStatusWrapper>
                    </C.SidebarHeaderActions>}
                    <C.SidebarHeaderActions>

                        <Box sx={{ cursor: "pointer", marginTop: "0.28rem" }} data-theme={theme ? theme : 'light'} onClick={() => { handleDarkTheme(isDarkTheme); }}>

                            <C.SidebarHeaderDefault>
                                {isDarkTheme ?
                                    <Tooltip title="Ativar modo claro">
                                        <Brightness4 sx={{ fontSize: 25 }} />
                                    </Tooltip> :
                                    <Tooltip title="Ativar modo escuro">
                                        <Brightness7 sx={{ fontSize: 25 }} />
                                    </Tooltip>
                                }
                            </C.SidebarHeaderDefault>
                        </Box>

                        <C.SidebarHeaderDefault>
                            {pageRoute.startsWith("/admin") === true ?
                                <C.SidebarHeaderDefault>
                                    <Tooltip title="Voltar para o chat">
                                        <ArrowBack fontSize='large' onClick={() => { nav("/chat"); }} />
                                    </Tooltip>
                                </C.SidebarHeaderDefault>

                                :
                                <C.SidebarHeaderDefault>

                                    <Tooltip title="Configurações">

                                        <IconButton
                                            aria-label="more"
                                            id="longButton"
                                            aria-controls={open ? 'long-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <Settings sx={{ fontSize: 27, color: "rgb(110, 110, 110)" }} fontSize='inherit' />
                                        </IconButton>

                                    </Tooltip>
                                </C.SidebarHeaderDefault>
                            }
                        </C.SidebarHeaderDefault>

                        <MenuItens handleCLoser={handleCloseMenu} handleOpener={open} anchorEl={anchorEl} />

                    </C.SidebarHeaderActions>
                </C.SidebarHeaderActionsRow>

            </C.SidebarHeaderRow>

            <SuccessSnackbar severity="success" snackbarText={Contexts.popupModalText} />
            <ErrorSnackbar severity="error" snackbarText={Contexts.popupModalErrorText} />

        </C.SidebarHeaderContainer >
    )
}
export default SidebarHeader; 