import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import departmentService from "../../../../service/departmentService";
import { ModalButtonRow, ModalTitle } from "./styles";
import { Button, TableCell, TableRow } from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { useMainContext } from "../../../../Contexts/Contexts";
import EditDepartament from "../EditDepartament";
import { StyledDialog } from "../EditDepartament/styles";

interface IListDepsProps {
  name: string,
  id: string,
  initials?: string
}

const ListDepartamentCard: React.FC<IListDepsProps> = ({ id, name, initials }) => {

  const nav = useNavigate();
  const { id: idParams } = useParams();

  const [modalNotification, setModalNotification] = useState<boolean>(false);
  const [editModalNotification, setEditModalNotification] = useState<boolean>(false);
  const [actualDepartament, setActualDepartament] = useState<any>(null)

  const openModal = (id: string) => {
    idContext.setId(id)
    idContext.setModalOpen(true)
    setEditModalNotification(true)
    nav(`/admin/departaments/${id}`)
  }

  const handleClose = () => {
    nav('/admin/departaments')
    setModalNotification(false)
  }

  const handleDelete = (id: number) => {
    try {

      departmentService.deleteDepartament(id).then(result => {
        idContext.setPopupModalOpen(true)
        idContext.setUpdateDepartmentHandle(true)
        if (!result) {
          idContext.setPopupModalErrorText('Item não encontrado ou já excluido!');
          idContext.setPopupModalErrorOpen(true)
        } else {
          idContext.setPopupModalText('Item excluido!');
          idContext.setPopupModalOpen(true)
        }
      });
    } catch (err) {
      idContext.setPopupModalErrorText('Algo deu errado!');
      idContext.setPopupModalErrorOpen(true)
    }

    setModalNotification(false);
  }

  const idContext = useMainContext();

  const findDepartment = (id: string) => {
    departmentService.getDepartmentByID(parseInt(id) || 0)
      .then(result => {
        setActualDepartament(result);
      })
      .catch(erro => {
        console.log(erro);
      });
  };
  useEffect(() => {
    if (typeof idParams == "string") {
      openModal(idParams);
    }
  }, [idParams])

  return (<>
    <TableRow
      key="TableDepartments"
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row" key={id} align="left">{id}</TableCell>
      <TableCell key={name + 2} component="th" scope="row">
        {initials ? initials : 'sem Sigla'}
      </TableCell>
      <TableCell key={name} component="th" scope="row">
        {name}
      </TableCell>

      <TableCell align="right" component="th" scope="row">
        <EditOutlined sx={{ cursor: 'pointer' }} onClick={() => { openModal(id) }} />
        <DeleteOutline sx={{ cursor: 'pointer' }} onClick={() => { setModalNotification(true) }} />
      </TableCell>
    </TableRow>

    <StyledDialog
      open={modalNotification}
      onClose={handleClose}
    >
      <div style={{ padding: '1rem', background: "var(--backgroundModal)" }}>

        <ModalTitle>Excluir Departamento</ModalTitle>
        <p>Deseja excluir {name}?</p>
        <ModalButtonRow style={{ gap: '1rem' }}>
          <Button sx={{ color: 'var(--primary)' }} color="secondary" onClick={handleClose}>Não</Button>
          <Button variant="contained" sx={{ width: '100%' }} color="success" onClick={() => { handleDelete(parseInt(id)) }}>Sim</Button>
        </ModalButtonRow>
      </div>
    </StyledDialog>

    {idContext.modalOpen && <EditDepartament />}

  </>
  );
}

export default ListDepartamentCard;
