import { Alert, Snackbar } from "@mui/material"
import { useMainContext } from "../../Contexts/Contexts";


interface SnacbackInterface {
  severity: 'error' | 'success',
  snackbarText: String;
}
export function SuccessSnackbar({ snackbarText }: SnacbackInterface) {
  const idContext = useMainContext();

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    idContext.setPopupModalOpen(false);
  };

  return (
    <Snackbar open={idContext.popupModalOpen} autoHideDuration={2000} onClose={handleClose}>
      <Alert onClose={handleClose} sx={{ width: '100%' }}>
        {snackbarText}
      </Alert>
    </Snackbar>
  )
}