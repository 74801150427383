import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 4rem;
  justify-content: space-between;
`;
export const Label = styled.div`
  font-size: x-large;
`;
export const Input = styled.input`
  height: 50%;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: #EAF1E8;
  &:focus {
        outline: 1px;
        box-shadow: 0px 0px 2px #0B131B;
    }
`;

export const ErrorMessage = styled.span`
  font-size: x-small;
  font-weight: 400;
  color: red;
`;


export const CardWrapper = styled.div`
    display:flex;
    flex: 1;
    flex-wrap: wrap;
    -webkit-font-smoothing: antialiased;
    margin: 0.5rem;
    margin-right: 1.5rem;
    height:98vh;
    min-height: 98vh;
    block-size: fit-content;
    flex-direction:column;
    background-color: var(--background-secondary);
    -webkit-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.23);
    color: var(--text-primary);
    input {
      color: var(--text-primary);
    }
    @media (max-width: 768px) {
      position: fixed;
      top: 72px;
      height: calc(100vh - 72px);
      width: 96vw;
    }
`;

export const SwitchWrapper = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    padding:5px;
`