import { useEffect, useState } from "react";

import webhookService, { IWebhook } from "../../../../service/webhookService";
import HeaderCard from "../../HeaderCard";
import CardWebhook from "../CardWebhook";
import { Alert, AppBar, Box, Button, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { IoIosAddCircle } from "react-icons/io";
import { useMainContext } from "../../../../Contexts/Contexts";
import * as S from './styles';
import { useNavigate, useParams } from "react-router-dom";
import { CreateButton } from "../../PredefinedMessagesManager/styles";
import { FaPlus } from "react-icons/fa";
import EditWebhook from "../EditWebhook";
import { SuccessSnackbar } from "../../../Snackbars/SucessSnackbar";
import { ErrorSnackbar } from "../../../Snackbars/ErrorSnackbar";

const ListWebhook: React.FC = () => {


    const [webhooksState, setWebhooksState] = useState<IWebhook[]>([]);
    const Contexts = useMainContext();

    const nav = useNavigate();

    useEffect(() => {

        let result = undefined
        const selectedCompanys_local = localStorage.getItem("selected-companys");
        if (selectedCompanys_local) {
            result = JSON.parse(selectedCompanys_local);

        }
        interface listCompany {
            label: string,
            value: string
        }

        let ids: string[] = result?.map((el: listCompany) => el.value);

        webhookService.listWebhooks(ids).then(webhooks => {
            setWebhooksState(webhooks);
        })
    }, [])

    const openModal = (id: string) => {
        Contexts.setId(id);
        Contexts.setEditWebhookOpen(true);
        nav(`/admin/webhook/${id}`)
    }

    return (
        <>
            <S.ContainerWrapper>
                <S.Header>
                    <Box sx={{ display: 'flex' }}>
                        <AppBar position="static" sx={{ backgroundColor: 'var(--background-company)', padding: '0.2rem', paddingTop: '0' }} >
                            <Toolbar sx={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                                    <h2> Webhooks</h2>
                                </Typography>

                                <div style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center', height: '40%' }}>
                                    <CreateButton onClick={() => {
                                        nav("/admin/webhook/NEW")
                                        openModal("NEW")
                                    }} endIcon={<FaPlus />} ><h4>Cadastrar novo</h4></CreateButton>
                                </div>

                            </Toolbar>
                        </AppBar>
                    </Box>
                </S.Header>
                <S.CardWrapper>
                    <S.ListWrapper>
                        {
                            (webhooksState && webhooksState.length > 0)
                                ?
                                webhooksState.map(webhook => {
                                    return <CardWebhook key={webhook.id} webhook={webhook} />
                                })
                                : ''
                        }
                    </S.ListWrapper>

                </S.CardWrapper>

            </S.ContainerWrapper>

            <EditWebhook />


            <SuccessSnackbar severity="success" snackbarText={Contexts.popupModalText} />
            <ErrorSnackbar severity="error" snackbarText={Contexts.popupModalErrorText} />
        </>
    )

}

export default ListWebhook;