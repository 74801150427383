import axios from 'axios'
import { DTOPredefinedMessageCreate, DTOPredefinedMessageUpdate, IPredefinedMessage} from '../interface/IModels';

const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'predefinedMessages/' || "";

export default {

    async listPredefinedMessagesByCompany ( id_company:number  ): Promise<boolean | IPredefinedMessage[]>{

      if(!id_company) return false;

      const tokenJWT = localStorage.getItem("auth");

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenJWT}`,
      } 
      
      let url = baseUrl + "list.php"; 

      return await axios.post<IPredefinedMessage[]>( url,
      { id_company },
      {
        headers
      }).then( request  => 
      { 
        return request.data;
      }).catch( err => {
        return false;
      });
       
    },

    async createPredefinedMessage( pm : DTOPredefinedMessageCreate ){
        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 
        
        const url = baseUrl + "create.php"; 
  
        return await axios.post<{id: number}>( url, 
        { title: pm.title, body: pm.body, id_company: pm.id_company, ids_departments: pm.ids_departments },
        {headers})
        .then( request  => { 
          return request.data;
        }).catch( err => {
          return false;
        });
    },
    async deletePredefinedMessage( id : number ){
        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 
        
        const url = baseUrl + "delete.php"; 
  
        return await axios.post<{id: number}>( url, 
        { id: id },
        {headers})
        .then( request  => { 
            console.log(request.data, id)
          return request.data;
        }).catch( err => {
          return false;
        });
    },

    async updatePredefinedMessage( predefinedMessage:DTOPredefinedMessageUpdate ){

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 
        
        const url = baseUrl + "update.php"; 
  
        return await axios.post<{id: number}>( url, 
        {  
            ...predefinedMessage
        },
        {headers})
        .then( request  => { 
          return request.data;
        }).catch( err => {
          return false;
        });        
    }
    
}