import styled from "styled-components";

export const TagWrapper = styled.div`
    border-radius: 15px;
    padding: 4px 12px;
    font-size: 10px;
    background-color: var(--background-department);
    color: var(--text-department);
`;

export const ModalWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 30%;
  z-index: 10;
  display: flex;
  height: 8rem;
  width: 8rem;
  border-radius: 8px;
  background-color:var(--input-background);
`;

export const ModalTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 1rem;
  width: 100%;
  padding: 0.2rem;
  margin-bottom: 0.5rem;
`;

export const ModalTitle = styled.span`
  font-size: small;
  color:var(--text-primary);
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const ModalOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  overflow-y: scroll;
`;

export const ModalOption = styled.span`
  display: flex;
  margin-left: 0.5rem;
  font-size: small;
  color: var(--text-primary);
  &:hover {
    background-color: var(--background-item-hover);
  }
`;
