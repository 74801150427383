import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 4rem;
  justify-content: space-between;
`;
export const Label = styled.label`
  font-size: large;
  font-weight:500;
  display: block;
`;
export const Input = styled.input`
  margin-top:10px;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: var(--wrapper-input-admin-background);
  color: var(--wrapper-input-admin-color);
  border:1px solid var(--wrapper-input-admin-border);
  padding:10px 5px;
  &:focus {
        outline: 1px;
        box-shadow: 0px 0px 2px #0B131B;
    }
`;

export const ErrorMessage = styled.span`
  font-size: x-small;
  font-weight: 400;
  color: red;
`;
