import { AppBar, Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FaSave, FaSpinner } from "react-icons/fa";
import configService from "../../../../service/configService";
import userService from "../../../../service/userService";
import { Header } from "../../Webhook/ListWebhook/styles";
import { Circle, ConfigContainer, CreateButton, Wrapper } from "./styles";


const ListConfig: React.FC = () => {

  const [agentsOptionSelected, setAgentsOptionSelected] = useState<string[]>([]);
  const [agentsOption, setAgentsOption] = useState<Array<{ value: string; label: string; }>>([]);

  const [companyOptionSelected, setCompanyOptionSelected] = useState<string>();
  const [companiesOption, setCompaniesOption] = useState<Array<{ value: string; label: string; }>>([]);

  const [updating, setIsUpdating] = useState<boolean>(false);
  const [listAttendanceOptionSelected, setListAttendanceOptionSelected] = useState<string>("admin-only");
  const [listAttendanceOptions, setListAttendanceOptions] = useState<Array<{ value: string; label: string; }>>([
    { value: "admin-only", label: "Apenas administradores podem ver a aba geral" },
    { value: "all", label: "Todos agentes podem ver a aba geral" },
    { value: "some", label: "Alguns agentes podem ver a aba geral" }
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {

    userService.getRelationUserCompany().then(res => {
      const newData = res.map(item => ({
        value: item.id_company,
        label: item.name_company
      }));
      setCompaniesOption(newData);
    });

    const selectedCompanies = localStorage.getItem("selected-companys");
    const selectedCompaniesObj = selectedCompanies ? JSON.parse(selectedCompanies) : "";
    if (selectedCompaniesObj?.length == 1) {
      setCompanyOptionSelected(selectedCompaniesObj[0].value)
    }

  }, []);

  useEffect(() => {
    if (!companyOptionSelected) return;

    if (!updating) {
      let getFromRequest = false;

      configService.getListAttendanceOptionConfigByCompanyId(parseInt(companyOptionSelected)).then(res => {
        getFromRequest = true;
        if (res.listAttendanceOption) setListAttendanceOptionSelected(res.listAttendanceOption);
        else setListAttendanceOptionSelected('admin-only');

        if (listAttendanceOptionSelected == 'some' && res.agentsSelected?.length > 0) setAgentsOptionSelected(res.agentsSelected);
        else setAgentsOptionSelected([]);
      });

      if(listAttendanceOptionSelected == 'some') {
        userService.listUserByCompanyID(parseInt(companyOptionSelected)).then(res => {
          const newData = res.map((item: any) => ({
            value: item.id,
            label: item.name
          }));
          setAgentsOption(newData);
          if(!getFromRequest) setAgentsOptionSelected([]);
        });
      }
    } else {
      if(listAttendanceOptionSelected == 'some') {
        userService.listUserByCompanyID(parseInt(companyOptionSelected)).then(res => {
          const newData = res.map((item: any) => ({
            value: item.id,
            label: item.name
          }));
          setAgentsOption(newData);
        });
      }
    }

  }, [companyOptionSelected, listAttendanceOptionSelected]);

  const handleChangeOptionSelect = (event: SelectChangeEvent<typeof listAttendanceOptionSelected>) => {
    setIsUpdating(true);

    const {
      target: { value },
    } = event;
    setListAttendanceOptionSelected(value);
  };

  const handleChangeOptionAgentSelect = (event: SelectChangeEvent<typeof agentsOptionSelected>) => {
    const {
      target: { value },
    } = event;

    setAgentsOptionSelected(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeOptionCompanySelect = (event: SelectChangeEvent<typeof companyOptionSelected>) => {
    const {
      target: { value },
    } = event;
    setCompanyOptionSelected(value);
  };

  const saveConfig = () => {
    if (!companyOptionSelected || !listAttendanceOptionSelected) return;
    
    setIsUpdating(false);
    setIsLoading(true);

    configService.saveListAttendanceOptionConfig(parseInt(companyOptionSelected), listAttendanceOptionSelected, agentsOptionSelected).then(res => setIsLoading(false));
  }

  return (
    <Wrapper>

      <Header>
        <Box sx={{ display: 'flex' }}>
          <AppBar position="static" sx={{ backgroundColor: 'var(--background-company)', padding: '0.2rem', paddingTop: '0' }} >
            <Toolbar sx={{ flexDirection: 'row', alignItems: 'flex-start' }}>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                <h2>Configurações</h2>
              </Typography>

              <div style={{ alignSelf: 'center', flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '1em' }}>

                <CreateButton onClick={() => saveConfig()} endIcon={!!isLoading ? <Circle><FaSpinner /></Circle> : <FaSave />} ><h4>Salvar</h4></CreateButton>

                <FormControl sx={{ minWidth: 210, width: '100%' }}>
                  <Select
                    key="selectCompany"
                    value={companyOptionSelected ? companyOptionSelected : 'none'}
                    onChange={handleChangeOptionCompanySelect}
                    sx={{
                      backgroundColor: 'var(--input-bg-color)', width: '100%', color: "var(--text-modal)", '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                      }
                    }}
                  >
                    <MenuItem disabled={true} selected={true} value="none">
                      <em>Selecione a empresa</em>
                    </MenuItem>

                    {companiesOption.map(e => (
                      <MenuItem value={e.value}> {e.label} </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </div>
            </Toolbar>
          </AppBar>
        </Box>
      </Header>

      <ConfigContainer>

        <FormControl sx={{ m: 1, width: '40%' }} disabled={!companyOptionSelected || isLoading}>
          <InputLabel sx={{color: 'var(--text-modal)'}}>Visualização da aba Geral</InputLabel>
          <Select
            key="selectOption"
            value={listAttendanceOptionSelected ? listAttendanceOptionSelected : 'admin-only'}
            onChange={handleChangeOptionSelect}
            input={<OutlinedInput label="Visualização da aba Geral" />}
            sx={{
              color: "var(--text-modal)", '.MuiOutlinedInput-notchedOutline': {
                borderColor: "var(--text-modal)",
              }
            }}
          >

            {listAttendanceOptions.map(e => (
              <MenuItem value={e.value}> {e.label} </MenuItem>
            ))}

          </Select>
        </FormControl>

        {!!listAttendanceOptionSelected && listAttendanceOptionSelected == 'some' ?
          <FormControl sx={{ m: 1, width: '40%' }} disabled={!companyOptionSelected || isLoading}>
            <InputLabel sx={{color: 'var(--text-modal)'}}>Seleção de Agentes</InputLabel>
            <Select
              key="selectAgents"
              value={agentsOptionSelected ? agentsOptionSelected : []}
              onChange={handleChangeOptionAgentSelect}
              multiple
              input={<OutlinedInput label="Seleção de Agentes" />}
              sx={{
                color: "var(--text-modal)", '.MuiOutlinedInput-notchedOutline': {
                  borderColor: "var(--text-modal)",
                }
              }}
              renderValue={(selected) => selected.map(val => agentsOption.find(option => option.value === val)?.label).join(', ')}
            >

              {agentsOption.map(e => (
                <MenuItem key={e.value} value={e.value}>
                  <Checkbox checked={agentsOptionSelected.indexOf(e.value) > -1} />
                  <ListItemText primary={e.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          : <></>
        }

      </ConfigContainer>
    </Wrapper>
  );
}

export default ListConfig;
