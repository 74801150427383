import { ErrorMessage, Input, InputWrapper, Label } from "./styles";

interface FormTextInputProps {
  label: string;
  placeholder: string;
  register: Function
  errors: any
  name: string
  defaultValue?: string
}

const FormEmailInput: React.FC<FormTextInputProps> = ({defaultValue, label, placeholder, register, errors, name}) => {
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <Input
        type="text"
        placeholder={placeholder}
        defaultValue={defaultValue || ''}
        {...register(name,{required: "Campo necessário", pattern: {
          // eslint-disable-next-line no-useless-escape
          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          message:
            "Email inválido"
        }})}
      />
      {errors && <ErrorMessage>{errors.message}</ErrorMessage>}
    </InputWrapper>
  );
}

export default FormEmailInput;
