import { Box, Modal } from '@mui/material';
import React, { useEffect, useRef, useState } from "react";
import { IHistory, IMessage, IMessages } from "../../interface/IModels";
import { ChatItemModel } from "../../models/redux-models";
import { EMessage } from "../../util/enums";
import ChatBodyFileItem from "../ChatBodyFileItem";

import { FaFileAlt } from 'react-icons/fa';

import * as C from './styles';
import { BsCameraReelsFill, BsCardImage, BsMic, BsPaperclip } from 'react-icons/bs';
import toast from 'react-hot-toast';
import { useChat } from '../Chat/chatContext';

const ChatBodyItem = React.forwardRef<
    HTMLDivElement,
    { message: IMessage; chat: ChatItemModel; history: IHistory[], onClickReplyMessage: ()=>void }
    >(({ message, chat, history, onClickReplyMessage }, ref) => {

    const {
        messageReply,
        setMessageReply
    } = useChat();

    const [modalState, setModalState] = useState<boolean>(false);
    const [activeMessageId, setActiveMessageId] = useState<number|null>(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const messageRef = useRef<HTMLDivElement>(null);

    const date = new Date(parseInt(message.message_time));
    let time = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
    const now = new Date().toLocaleDateString("pt-BR");
    if(date.toLocaleDateString("pt-BR") != now) {
        time = formatDate(date.toString());
    }

    let normalizedMessage,nameAgent = "";
    if ( message.sender === 'agent' ){
        normalizedMessage   = normalizeMessage(message.message);
        nameAgent = getNameAgent(message.message);
    }

    const messageParticipantGroup = ( message.participant_name && chat.isGroup )? `${message.participant_name}\n` : ""; 

    const toggleModal = () => {
        setModalState(!modalState);
    }
    
    const toggleModalMenuOptions = (messageId: number) => {
        if (activeMessageId === messageId) {
            setActiveMessageId(null);
        } else {
            setTimeout(()=>{}, 1000);
            setActiveMessageId(messageId);
        }
    }
    
    const handleMouseEnter = (sender: string) => {
        if (messageRef.current) {
            const rect = messageRef.current.getBoundingClientRect();
            
            setMenuPosition({ top: rect.top, left: rect.right });
            toggleModalMenuOptions(message.id);
        }   
    };
    
    const handleMouseLeave = () => {
        toggleModalMenuOptions(-1);
    };

    const copyMessage = (message: IMessage) => {
        if(message.type_message == EMessage.TEXT) {
            navigator.clipboard.writeText(message.message);
            return toast.success('Mensagem copiada!');
        }

        if(message.url) {
            navigator.clipboard.writeText(message.url);
            return toast.success('Você copiou a URL desse arquivo. Para visualizar basta colar no seu navegador!', {
                duration: 5000
            });
        }
        
        return toast.error('Nenhum conteúdo foi copiado');
    };
    
    const replyMessage = (id: string, content: IMessage) => {
        if(!id) return;
        setMessageReply({id, content});
    };
    
    const scrollToMessage = (whatsappReferenceMessageId: string) => {
        if(!whatsappReferenceMessageId) return toast.error('Não foi possível encontrar a mensagem');
        
        const replyMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
        if(!replyMessage) return toast.error('Não foi possível encontrar a mensagem respondida pois ela foi enviada em um atendimento já encerrado.', {
            duration: 3000
        });

        onClickReplyMessage();
    }
    
    const getResponseTypeMessage = (whatsappReferenceMessageId: string = "") => {
        let responseMessage: IMessage|undefined;
        if (whatsappReferenceMessageId) {
            responseMessage = history.find(history => history.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId))?.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
            if(!responseMessage) responseMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
        }

        if(responseMessage) {
            return responseMessage.type_message;
        }
        return "";
    }

    const getResponseSender = (whatsappReferenceMessageId: string) => {
        let responseMessage: IMessage|undefined;
        if (whatsappReferenceMessageId) {
            responseMessage = history.find(history => history.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId))?.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
            if(!responseMessage) responseMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
        }

        if(responseMessage) {
            return responseMessage.sender;
        }
        return "";
    }

    function normalizeMessage(text:string){ 
        if( text === "" || text.split("*").length < 2 ) return text;
        let nameAgent = text.split('*')[1].split('*')[0];
        return text.replace("*"+nameAgent+"*","");   
    }

    function getNameAgent(text:string){
        if ( text === "" || text.split("*").length < 2  ) return text;
        return text.split('*')[1].split('*')[0];
    }

    function padTo2Digits(num:Number) {
        return num.toString().padStart(2, '0');
    }
    function formatDate(dateString:string) {
        let date = new Date(dateString);
        return [
          padTo2Digits(date.getDate()),
          padTo2Digits(date.getMonth() + 1) + " " + ( padTo2Digits(date.getHours()) + ':'+ padTo2Digits(date.getMinutes())),
        ].join('/');
    }

    
    function renderSwitch( type: string, sender: string, whatsappReferenceMessageId: string = "" ){
        switch( type ){
            case EMessage.TEXT:
                if( sender === "agent" ){
                    
                    if(whatsappReferenceMessageId) {

                        let responseMessage: IMessage|undefined;
                        if (message.whatsapp_reference_message_id) {
                            responseMessage = history.find(history => history.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId))?.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                            if(!responseMessage) responseMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                        }

                        if(responseMessage) {
                            
                            const date = new Date(parseInt(responseMessage.message_time));
                            let messageResponseTime = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);

                            const now = new Date().toLocaleDateString("pt-BR");
                            if(date.toLocaleDateString("pt-BR") != now) {
                                messageResponseTime = formatDate(date.toString());
                            }

                            let name = responseMessage.participant_name;
                            let message = responseMessage.message;
                            if(!name) {
                                name = getNameAgent(responseMessage.message.split("\n")[0]);
                                message = responseMessage.message.split("\n")[1];
                            }

                            return getResponseMessageComponent(responseMessage, name, message, messageResponseTime);
                            
                        }
                        return <></>;
                    }

                    let name = getNameAgent(message.message);
                    return <>
                        <C.MessageContainer> 
                            <div><strong>{name==localStorage.getItem("user") ? 'Você' : name}</strong></div>
                            {normalizeMessage(message.message)}
                        </C.MessageContainer>
                        <C.TimeMessage>{time}</C.TimeMessage>
                    </>

                }else{

                    if(whatsappReferenceMessageId) {

                        let responseMessage: IMessage|undefined;
                        if (message.whatsapp_reference_message_id) {
                            responseMessage = history.find(history => history.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId))?.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                            if(!responseMessage) responseMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                        }

                        if(responseMessage) {
                            
                            const date = new Date(parseInt(responseMessage.message_time));
                            let messageResponseTime = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);

                            const now = new Date().toLocaleDateString("pt-BR");
                            if(date.toLocaleDateString("pt-BR") != now) {
                                messageResponseTime = formatDate(date.toString());
                            }

                            let name = responseMessage.participant_name;
                            let message = responseMessage.message;
                            if(!name) {
                                name = getNameAgent(responseMessage.message.split("\n")[0]);
                                message = responseMessage.message.split("\n")[1];
                            }

                            return getResponseMessageComponent(responseMessage, name, message, messageResponseTime);
                            
                        }
                        return <></>;
                    }

                    return <>
                        <C.MessageContainer> 
                            <div><strong>{messageParticipantGroup}</strong></div>
                            {message.message}
                        </C.MessageContainer>
                        <C.TimeMessage>{time}</C.TimeMessage>
                    </>
                }
    
            case EMessage.IMAGE:
                if ( sender === "agent" ) {

                    if(whatsappReferenceMessageId) {

                        let responseMessage: IMessage|undefined;
                        if (message.whatsapp_reference_message_id) {
                            responseMessage = history.find(history => history.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId))?.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                            if(!responseMessage) responseMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                        }

                        if(responseMessage) {
                            
                            const date = new Date(parseInt(responseMessage.message_time));
                            let messageResponseTime = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);

                            const now = new Date().toLocaleDateString("pt-BR");
                            if(date.toLocaleDateString("pt-BR") != now) {
                                messageResponseTime = formatDate(date.toString());
                            }

                            let name = responseMessage.participant_name;
                            let message = responseMessage.message;
                            if(!name) {
                                name = getNameAgent(responseMessage.message.split("\n")[0]);
                                message = responseMessage.message.split("\n")[1];
                            }
                            
                            return getResponseMessageComponent(responseMessage, name, message, messageResponseTime);
                            
                        }
                        return <></>;
                    }

                    return (
                        <div
                        style={{cursor: 'zoom-in'}}
                        onClick={ () => toggleModal()}
                        >
                            <ChatBodyFileItem 
                                url={message.url}
                                time={time}
                                type={type}
                                content={
                                    <C.MessageImageContainer>
                                        <C.MessageImage src={message.url} />
                                        <C.MessageTimeImage>
                                            <C.TimeMessageImage>{time}</C.TimeMessageImage>
                                        </C.MessageTimeImage>
                                    </C.MessageImageContainer>
                                }
                            />
                        </div>
                    );
                }else {

                    if(whatsappReferenceMessageId) {

                        let responseMessage: IMessage|undefined;
                        if (message.whatsapp_reference_message_id) {
                            responseMessage = history.find(history => history.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId))?.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                            if(!responseMessage) responseMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                        }

                        if(responseMessage) {
                            
                            const date = new Date(parseInt(responseMessage.message_time));
                            let messageResponseTime = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);

                            const now = new Date().toLocaleDateString("pt-BR");
                            if(date.toLocaleDateString("pt-BR") != now) {
                                messageResponseTime = formatDate(date.toString());
                            }

                            let name = responseMessage.participant_name;
                            let message = responseMessage.message;
                            if(!name) {
                                name = getNameAgent(responseMessage.message.split("\n")[0]);
                                message = responseMessage.message.split("\n")[1];
                            }
                            
                            return getResponseMessageComponent(responseMessage, name, message, messageResponseTime);
                            
                        }
                        return <></>;
                    }

                    return (
                        <div
                            style={{cursor: 'zoom-in'}}
                            onClick={ () => toggleModal()}
                        >
                            <ChatBodyFileItem 
                                url={message.url}
                                participantName={getNameAgent(message.message)}
                                time={time}
                                type={type}
                                content={
                                    <C.MessageImageContainer>
                                        <div><strong>{messageParticipantGroup}</strong></div>
                                        <C.MessageImage src={message.url} />
                                        <C.MessageTimeImage>
                                            <C.TimeMessageImage>{time}</C.TimeMessageImage>
                                        </C.MessageTimeImage>
                                    </C.MessageImageContainer>
                                }
                            />
                        </div>
                    );
                }

            case EMessage.AUDIO:

                if(whatsappReferenceMessageId) {

                    let responseMessage: IMessage|undefined;
                    if (message.whatsapp_reference_message_id) {
                        responseMessage = history.find(history => history.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId))?.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                        if(!responseMessage) responseMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                    }

                    if(responseMessage) {
                        
                        const date = new Date(parseInt(responseMessage.message_time));
                        let messageResponseTime = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);

                        const now = new Date().toLocaleDateString("pt-BR");
                        if(date.toLocaleDateString("pt-BR") != now) {
                            messageResponseTime = formatDate(date.toString());
                        }

                        let name = responseMessage.participant_name;
                        let message = responseMessage.message;
                        if(!name) {
                            name = getNameAgent(responseMessage.message.split("\n")[0]);
                            message = responseMessage.message.split("\n")[1];
                        }
                        
                        return getResponseMessageComponent(responseMessage, name, message, messageResponseTime);
                        
                    }
                    return <></>;
                }

                return (
                    <ChatBodyFileItem 
                        url={message.url}
                        time={time}
                        type={type}
                        content={
                            <audio controls>
                                <source src={message.url} type="audio/ogg"></source>
                            </audio>
                        }
                    />
                );
                
            case EMessage.VIDEO:

                if(whatsappReferenceMessageId) {

                    let responseMessage: IMessage|undefined;
                    if (message.whatsapp_reference_message_id) {
                        responseMessage = history.find(history => history.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId))?.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                        if(!responseMessage) responseMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                    }

                    if(responseMessage) {
                        
                        const date = new Date(parseInt(responseMessage.message_time));
                        let messageResponseTime = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);

                        const now = new Date().toLocaleDateString("pt-BR");
                        if(date.toLocaleDateString("pt-BR") != now) {
                            messageResponseTime = formatDate(date.toString());
                        }

                        let name = responseMessage.participant_name;
                        let message = responseMessage.message;
                        if(!name) {
                            name = getNameAgent(responseMessage.message.split("\n")[0]);
                            message = responseMessage.message.split("\n")[1];
                        }
                        
                        return getResponseMessageComponent(responseMessage, name, message, messageResponseTime);
                        
                    }
                    return <></>;
                }

                return (
                    <ChatBodyFileItem 
                        url={message.url}
                        time={time}
                        type={type}
                        content={
                            <video width="400" controls>
                                <source src={message.url} type="video/mp4"></source>
                            </video>
                        }
                    />
                );

            case EMessage.DOCUMENT:

                if(whatsappReferenceMessageId) {

                    let responseMessage: IMessage|undefined;
                    if (message.whatsapp_reference_message_id) {
                        responseMessage = history.find(history => history.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId))?.messages.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                        if(!responseMessage) responseMessage = chat.messages_chat.find(message => message.whatsapp_message_id == whatsappReferenceMessageId);
                    }

                    if(responseMessage) {                        
                        const date = new Date(parseInt(responseMessage.message_time));
                        let messageResponseTime = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);

                        const now = new Date().toLocaleDateString("pt-BR");
                        if(date.toLocaleDateString("pt-BR") != now) {
                            messageResponseTime = formatDate(date.toString());
                        }

                        let name = responseMessage.participant_name;
                        let message = responseMessage.message;
                        if(!name) {
                            name = getNameAgent(responseMessage.message.split("\n")[0]);
                            message = responseMessage.message.split("\n")[1];
                        }
                        
                        return getResponseMessageComponent(responseMessage, name, message, messageResponseTime);
                        
                    }
                    return <></>;
                }

                return (
                    <ChatBodyFileItem 
                        url={message.url}
                        time={time}
                        type={type}
                        content={
                            <C.MessageLinkDocument href={message.url} target="_blank" >
                                <FaFileAlt /> {message.message.includes("\n") ? message.message.split(/[\r\n]+/)[1] : message.message}
                                <C.TimeMessage>{time}</C.TimeMessage>
                            </C.MessageLinkDocument>
                        }
                    />
                );
        
            default:
        }   


        function getResponseMessageComponent(responseMessage: IMessage, name: string, message: string, messageResponseTime: string) {
            switch (responseMessage.type_message) {
                case EMessage.TEXT:
                    return <>
                        <C.MessageContainer>
                            <div><strong>{name == localStorage.getItem("user") ? 'Você' : name}</strong></div>
                            {message}
                        </C.MessageContainer>
                        <C.TimeMessage>{messageResponseTime}</C.TimeMessage>
                    </>;
                case EMessage.AUDIO:
                    return <>
                        <C.MessageContainer>
                            <div><strong>{name == localStorage.getItem("user") ? 'Você' : name}</strong></div>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsMic /> Áudio</div>
                        </C.MessageContainer>
                        <C.TimeMessage>{messageResponseTime}</C.TimeMessage>
                    </>;
                case EMessage.IMAGE:
                    return <>
                        <C.MessageContainer>
                            <div><strong>{name == localStorage.getItem("user") ? 'Você' : name}</strong></div>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsCardImage /> Imagem</div>
                        </C.MessageContainer>
                        <C.TimeMessage>{messageResponseTime}</C.TimeMessage>
                    </>;
                case EMessage.VIDEO:
                    return <>
                        <C.MessageContainer>
                            <div><strong>{name == localStorage.getItem("user") ? 'Você' : name}</strong></div>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsCameraReelsFill /> Vídeo</div>
                        </C.MessageContainer>
                        <C.TimeMessage>{messageResponseTime}</C.TimeMessage>
                    </>;
                case EMessage.DOCUMENT:
                    return <>
                        <C.MessageContainer>
                            <div><strong>{name == localStorage.getItem("user") ? 'Você' : name}</strong></div>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}><BsPaperclip /> Anexo</div>
                        </C.MessageContainer>
                        <C.TimeMessage>{messageResponseTime}</C.TimeMessage>
                    </>;
                default:
                    break;
            }
        }
    }

    return (
        <>
        <C.ChatBodyItemContainer ref={ref} isAgent={ message.sender === 'agent'? true :false}>

            <C.ChatBodyItemAllMessage
                isText={ message.type_message==EMessage.TEXT } 
                isAgent={ message.sender === 'agent'? true :false }
                isReply={ !!message.whatsapp_reference_message_id }
                shouldHaveBackground={  message.type_message==EMessage.DOCUMENT || (message.type_message === EMessage.IMAGE  && message.sender !== 'agent' && message.url !== '' && message.message !== '') }
            >
                
                {message.whatsapp_reference_message_id ? 
                    <C.ChatBodyItemResponseMessage
                        isText={ getResponseTypeMessage(message.whatsapp_reference_message_id) == EMessage.TEXT } 
                        isAgent={ getResponseSender(message.whatsapp_reference_message_id) === 'agent'? true :false}
                        isReply={!!message.whatsapp_reference_message_id}
                        shouldHaveBackground={false}
                        onClick={() => scrollToMessage(message.whatsapp_reference_message_id??"")}
                    >
                        {renderSwitch( message.type_message, message.sender, message.whatsapp_reference_message_id )}
                    </C.ChatBodyItemResponseMessage> : <></>
                }

                <C.ChatBodyItemMessage
                    ref={messageRef}
                    isText={message.type_message == EMessage.TEXT} 
                    isAgent={message.sender === 'agent'}
                    isReply={false}
                    shouldHaveBackground={false}
                    onMouseEnter={() => handleMouseEnter(message.sender)}
                    onMouseLeave={handleMouseLeave}
                >
                    {renderSwitch(message.type_message, message.sender)}

                    {(message.type_message === EMessage.IMAGE  && message.sender !== 'agent' && message.url !== '' && message.message !== '') ?
                        <C.ChatBodyItemMessage 
                            isText={ true } 
                            isAgent={ false }
                            shouldHaveBackground={ true }
                            isReply={ !!message.whatsapp_reference_message_id }
                        > 
                            <C.MessageContainer> 
                                <div><strong>{messageParticipantGroup}</strong></div>
                                {message.message}
                            </C.MessageContainer>
                            <C.TimeMessage>{time}</C.TimeMessage>
                        </C.ChatBodyItemMessage>
                        : <></> 
                    }

                    {activeMessageId === message.id && (
                        <C.MenuOptions style={{ top: menuPosition.top, left: menuPosition.left, position: 'absolute' }}>
                            <C.MenuOptionsButton onClick={() => copyMessage(message)}>Copiar</C.MenuOptionsButton>
                            <C.MenuOptionsButton onClick={() => replyMessage(message.whatsapp_message_id ?? "", message)} disabled={!message.whatsapp_message_id}>Responder</C.MenuOptionsButton>
                        </C.MenuOptions>
                    )}
                </C.ChatBodyItemMessage>
            </C.ChatBodyItemAllMessage>
        </C.ChatBodyItemContainer> 

        <Modal
            open={modalState}
            onClose={toggleModal}
            
            sx={{ display:'flex', justifyContent:'center', alignItems:'center', cursor: 'zoom-out' }}
        >
            <Box>
                <img
                    src={message.url}
                    alt={message.sender !== 'agent' ? "Imagem enviada pelo Cliente" : "Imagem enviada pelo Atendente" }
                    style={{ maxWidth: '90vw', maxHeight: '90vh', objectFit: 'contain', cursor: 'default' }}
                />
            </Box>
        </Modal>
        </>    
  );
});

export default ChatBodyItem;